/* eslint-disable complexity */
import 'react-toastify/dist/ReactToastify.css';

import { memo, useEffect, useMemo, useState } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { NotificationBar } from '@ftrprf/tailwind-components';

import CodeCosmosContainer from 'pages/ContentSlideViewer/CodeCosmosContainer';
import Error404 from 'pages/Error/Error404';
import Exercise from 'pages/Exercise/Exercise';
import ExerciseOverviewContainer from 'pages/ExercisesOverview/ExerciseOverviewContainer';
import HelpVideos from 'pages/HelpVideos/HelpVideos';
import Logout from 'pages/Logout/Logout';
import NoClasses from 'pages/NoClasses/index';
import Profile from 'pages/Profile/Profile';
import RedirectStore from 'pages/RedirectStore/index';
import Status from 'pages/Status/Status';

import CurrentClassGroupProvider from 'providers/CurrentClassGroupProvider';

import useClassGroups from 'hooks/api/useClassGroups';
import useCurrentUser from 'hooks/api/useCurrentUser';
import useParams from 'hooks/useParams';

import { AUTHORIZATION_TOKEN } from 'utils/constants/localStorage';
import urls from 'utils/constants/urls';
import { initializeClients } from 'utils/graphqlQueryClients';
import { CustomNavigationClient } from 'utils/NavigationClientAD';

import { ReactComponent as Loader } from '../assets/vectors/logo-animated-lines.svg';

import FullPageLayout from './layouts/FullPageLayout';
import GeneralLayout from './layouts/GeneralLayout';
import NoLoginLayout from './layouts/NoLoginLayout';

import ClassGroupApp from './ClassGroupApp';
import Route from './Route';

const PrivateApp = () => {
  const [smallDelay, setSmallDelay] = useState(true);
  const [clientsInitialized, setClientsInitialized] = useState(false);

  const { classGroups, isLoading: isClassGroupsLoading } = useClassGroups();
  const {
    user,
    isCodeCosmosAdmin,
    isCodeCosmosTeacherOrAdmin,
    isCodeCosmosStudent,
    isCodeCosmosParent,
    isCodeCosmosChild,
    isLoading: isUserLoading,
  } = useCurrentUser();
  const hasClassGroups = classGroups?.length > 0;
  const { lessonId, viewmode } = useParams();

  // Initialize GraphQl clients so we don't have to initialize them on every request.
  const runInitializeClients = () => {
    initializeClients().then(() => {
      setClientsInitialized(true);
    });
  };

  // minimal delay is 1 second
  useEffect(() => {
    runInitializeClients();
    const smallDelayTimeOut = setTimeout(() => {
      setSmallDelay(false);
    }, 1000);

    return () => clearTimeout(smallDelayTimeOut);
  }, []);

  const isLoading = isUserLoading || isClassGroupsLoading;
  if (isLoading || smallDelay || !clientsInitialized) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Loader className="w-32" />
      </div>
    );
  }

  const codeCosmosRedirect = isCodeCosmosParent && lessonId === undefined;

  return (
    <CurrentClassGroupProvider>
      <Switch>
        <Route
          path={[
            urls.EXERCISE_URLS_START,
            urls.EXERCISE_URLS_NEW,
            urls.EXERCISE_URLS_ID,
          ]}
          component={Exercise}
          layout={FullPageLayout}
        />
        {(isCodeCosmosParent || isCodeCosmosChild) &&
          lessonId !== undefined &&
          viewmode !== undefined && <CodeCosmosContainer />}

        {codeCosmosRedirect && (
          <Route
            path={urls.REDIRECT_STORE}
            component={RedirectStore}
            layout={GeneralLayout}
          />
        )}

        {codeCosmosRedirect && <Redirect from="/" to={urls.REDIRECT_STORE} />}

        {(isCodeCosmosTeacherOrAdmin ||
          isCodeCosmosStudent ||
          isCodeCosmosAdmin) &&
          hasClassGroups && (
            <Route
              path={urls.CLASSGROUP}
              component={ClassGroupApp}
              layout={GeneralLayout}
              user={user}
            />
          )}
        {isCodeCosmosTeacherOrAdmin && (
          <Route
            path={urls.HELP}
            component={HelpVideos}
            layout={GeneralLayout}
          />
        )}
        <Route path={urls.PROFILE} component={Profile} layout={GeneralLayout} />

        {(isCodeCosmosStudent || isCodeCosmosTeacherOrAdmin) && (
          <Route
            path={urls.EXERCISE_OVERVIEW}
            component={ExerciseOverviewContainer}
            layout={GeneralLayout}
          />
        )}
        {hasClassGroups && (
          <Redirect
            exact
            from="/"
            to={`/classgroups/${classGroups?.[0]?.id}`}
          />
        )}
        {hasClassGroups && (
          <Redirect
            exact
            from="/classgroups"
            to={`/classgroups/${classGroups?.[0]?.id}`}
          />
        )}
        {!codeCosmosRedirect && !hasClassGroups && (
          <Route
            path={urls.NO_CLASSES}
            component={NoClasses}
            layout={GeneralLayout}
          />
        )}
        {!codeCosmosRedirect && !hasClassGroups && (
          <Redirect exact from="/" to={urls.NO_CLASSES} />
        )}
        <Route layout={GeneralLayout} component={Error404} />
      </Switch>
    </CurrentClassGroupProvider>
  );
};

const App = () => {
  const history = useHistory();
  const { token, redirect } = useParams();
  const { instance, inProgress } = useMsal();
  const navigationClient = new CustomNavigationClient(history);
  instance.setNavigationClient(navigationClient);

  const account = instance.getActiveAccount();
  const isPossiblySignedIn = localStorage.getItem(AUTHORIZATION_TOKEN);
  useEffect(() => {
    if (
      !isPossiblySignedIn &&
      !account &&
      inProgress === InteractionStatus.None
    ) {
      instance.loginRedirect().catch(() => instance.handleRedirectPromise());
    }
  }, [account, inProgress, instance, isPossiblySignedIn]);

  if (
    !isPossiblySignedIn &&
    window.location.pathname.startsWith(urls.CALLBACK)
  ) {
    localStorage.setItem(AUTHORIZATION_TOKEN, token);
    window.location = redirect;
  }
  if (window.location.pathname.startsWith(urls.CALLBACK)) {
    localStorage.setItem(AUTHORIZATION_TOKEN, token);
    window.location = redirect;
  }
  // Prevent rerenders of status page so it doesn't send out fetches which never complete
  const statusRoute = useMemo(
    () => (
      <Route
        path={urls.STATUS}
        layout={NoLoginLayout}
        component={() => <Status />}
      />
    ),
    [],
  );
  return (
    <div className="antialiased w-full h-full flex flex-col flex-grow items-center relative">
      <NotificationBar />
      <ToastContainer />

      <Switch>
        {statusRoute}
        <Route
          path={urls.LOGOUT}
          layout={FullPageLayout}
          component={() => <Logout />}
        />
        {account && <PrivateApp />}
        {isPossiblySignedIn && <PrivateApp />}
      </Switch>
    </div>
  );
};

export default memo(App);
