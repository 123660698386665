import faker from 'faker';

export const generateFeedback = (comment, firstName, lastName) => {
  return {
    comment: comment || faker.lorem.sentence(),
    id: faker.datatype.uuid(),
    submittedAt: faker.date.past().toISOString(),
    teacher: {
      firstName: firstName || faker.name.firstName(),
      lastName: lastName || faker.name.lastName(),
      id: faker.datatype.uuid(),
    },
  };
};
