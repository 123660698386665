import { Children, cloneElement } from 'react';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';

import useParams from 'hooks/useParams';

import c from 'utils/c';

export const Pivot = ({ active, children, itemsRender }) => {
  let current;

  const enhancedChildren = Children.map(children, (child) =>
    cloneElement(child, {
      active:
        // eslint-disable-next-line no-sequences
        child.props.name === active && ((current = child.props.children), true),
    }),
  );

  return (
    <div className="w-full flex flex-col">
      {itemsRender(<div className="flex space-x-6">{enhancedChildren}</div>)}
      <div className="w-full">{current || null}</div>
    </div>
  );
};

export const PivotElement = ({ active, label }) => {
  return (
    <button
      className={c(
        'pb-1 px-1 border-0 border-b-2 border-solid',
        active ? 'border-accent-400' : 'border-transparent',
      )}
    >
      {label}
    </button>
  );
};

export const PivotLinkElement = ({ name, ...props }) => {
  const params = useParams();
  const { path } = useRouteMatch();

  return (
    <Link to={generatePath(path, { ...params, active: name })}>
      <PivotElement name={name} {...props} />
    </Link>
  );
};

export default Pivot;
