import { useContext, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import * as Sentry from '@sentry/react';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { LanguageContext } from 'providers/LanguageProvider';

import useFormatMessage from 'hooks/useFormatMessage';

import { put } from 'api/index';

//import IDPS from 'utils/constants/idps';
import roles from 'utils/constants/roles';
import urls from 'utils/constants/urls';

export const LOGOUT_LOCATION = {
  pathname: urls.LOGOUT,
  search: '?projects=NEXT,STUDIO,EDU',
};

const URL = () => `${process.env.REACT_APP_API_URL}/users/me`;
const changeUserURL = (id) => `${process.env.REACT_APP_API_URL}/users/${id}`;

const useCurrentUser = () => {
  const t = useFormatMessage();
  const queryCache = useQueryClient();
  const history = useHistory();

  const { addNotification } = useContext(NotificationContext);
  const { setLanguage } = useContext(LanguageContext);

  const { data, isLoading } = useQuery(URL(), {
    staleTime: 1000 * 60 * 60 * 24 * 365, // https://github.com/tannerlinsley/react-query/discussions/230#discussioncomment-1234
  });
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  useEffect(() => {
    if (!data) {
      return;
    }

    setLanguage(data.language);
    Sentry.setUser(data);
  }, [data, setLanguage]);

  const handleLanguageRequest = (newLanguage) => {
    return put(changeUserURL(data.id), newLanguage);
  };

  const handleEuaAcceptedRequest = (eulaAccepted) => {
    put(changeUserURL(data.id), eulaAccepted);
  };

  const { mutate: updateLanguage } = useMutation(
    (newLanguage) => handleLanguageRequest({ language: newLanguage }),
    {
      onMutate: (newLanguage) => {
        const oldCache = queryCache.getQueryData(URL());

        queryCache.setQueryData(URL(), (oldUser) => {
          return { ...oldUser, language: newLanguage };
        });

        return () => queryCache.setQueryData(URL(), oldCache);
      },
      onError: (_, _newData, rollback) => {
        rollback();
        addNotification({
          type: 'error',
          content: t('errors.change_language'),
        });
      },
    },
  );

  const { mutate: updateEulaAccepted } = useMutation(
    (eulaAccepted) => handleEuaAcceptedRequest({ eulaAccepted }),
    {
      onSuccess: () => {
        history.push(LOGOUT_LOCATION);
      },
      onMutate: (isEulaAccepted) => {
        const oldCache = queryCache.getQueryData(URL());

        queryCache.setQueryData(URL(), (oldUser) => {
          return { ...oldUser, eulaAccepted: isEulaAccepted };
        });

        return () => queryCache.setQueryData(URL(), oldCache);
      },
      onError: (_, _newData, rollback) => {
        rollback();
        addNotification({
          type: 'error',
          content: t('errors.change_eula'),
        });
      },
    },
  );
  return useMemo(() => {
    const roleConditions = {
      isCodeCosmosTeacherOrAdmin:
        account?.idTokenClaims?.roles[0] === roles.CODECOSMOS_TEACHER ||
        account?.idTokenClaims?.roles[0] === roles.CODECOSMOS_ADMIN,
      isCodeCosmosStudent:
        account?.idTokenClaims?.roles[0] === roles.CODECOSMOS_STUDENT,
      isCodeCosmosParent:
        account?.idTokenClaims?.roles[0] === roles.CODECOSMOS_PARENT,
      isCodeCosmosChild:
        account?.idTokenClaims?.roles[0] === roles.CODECOSMOS_CHILD,
      isCodeCosmosUser: [
        roles.CODECOSMOS_TEACHER,
        roles.CODECOSMOS_ADMIN,
        roles.CODECOSMOS_STUDENT,
        roles.CODECOSMOS_PARENT,
        roles.CODECOSMOS_CHILD,
      ].includes(account?.idTokenClaims?.roles[0]),
    };
    return {
      user: {
        ...data,
        ...roleConditions,
      },
      ...roleConditions,
      isLoading,
      updateLanguage,
      updateEulaAccepted,
    };
  }, [data, isLoading, updateLanguage, updateEulaAccepted]);
};

export default useCurrentUser;
