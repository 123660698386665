import { useState } from 'react';
import { FaArrowRight, FaTimes } from 'react-icons/fa';

import { Button, ChatBubble, Input } from '@ftrprf/tailwind-components';

import Popover from 'components/Popover';

import useFeedbacks from 'hooks/api/useFeedbacks';

import c from 'utils/c';

import FeedbackItem from './FeedbackItem';

const FeedbackPopover = ({ feedbackEndpoint, canEdit, questionId, user }) => {
  const [draft, setDraft] = useState('');

  const { feedbacks, isSuccess, remove, create } = useFeedbacks({
    endpoint: feedbackEndpoint,
    questionId,
  });

  return (
    <Popover
      placement="left-start"
      trigger={({ toggle }) => {
        const canGiveFeedback = canEdit || feedbacks?.length > 0;
        return (
          isSuccess &&
          canGiveFeedback && (
            <ChatBubble
              onClick={toggle}
              className="self-end w-5 h-5"
              amount={feedbacks.length}
            />
          )
        );
      }}
    >
      {({ close }) => (
        <div className="relative bg-white max-w-sm w-screen border border-gray-300 shadow mr-2 flex flex-col">
          <div className="absolute top-0 right-0 leading-none pt-1 pr-1">
            <button className="text-gray-500 w-3 h-3" onClick={close}>
              <FaTimes className="w-full h-full" />
            </button>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col max-h-64 overflow-y-auto divide-y divide-gray-300">
              {feedbacks?.map((f) => (
                <div className="p-4" key={f.id}>
                  <FeedbackItem
                    user={user}
                    feedback={f}
                    onDelete={(id) => remove([questionId, id])}
                  />
                </div>
              ))}
            </div>

            {canEdit && (
              <div
                className={c(
                  'py-2 px-3 flex items-center border-gray-300',
                  feedbacks && feedbacks.length && 'border-t',
                )}
              >
                <Input
                  placeholder="Voeg hier je feedback toe"
                  className="mr-2"
                  value={draft}
                  onChange={(e) => setDraft(e.target.value)}
                />
                <Button
                  className="flex-shrink-0 text-gray-800"
                  onClick={() => {
                    setDraft('');
                    create({
                      comment: draft,
                      linkedId: questionId,
                      linkedType: 'ANSWERED_QUESTION',
                      teacher: user,
                    });
                  }}
                  disabled={!draft}
                >
                  <FaArrowRight className="w-4 h-4" />
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </Popover>
  );
};

export default FeedbackPopover;
