import { useCallback, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import queryString from 'query-string';

import roles from 'utils/constants/roles';

const Logout = () => {
  const { instance } = useMsal();
  const currentAccount = instance.getActiveAccount();
  const queryParams = queryString.parse(location.search);

  const isCodeCosmos = [
    roles.CODECOSMOS_ADMIN,
    roles.CODECOSMOS_TEACHER,
    roles.CODECOSMOS_STUDENT,
    roles.CODECOSMOS_PARENT,
    roles.CODECOSMOS_CHILD,
  ].includes(currentAccount?.idTokenClaims?.roles[0]);

  const logout = useCallback(() => {
    instance.logoutRedirect({
      account: currentAccount,
      postLogoutRedirectUri: queryParams.redirect
        ? queryParams.redirect
        : isCodeCosmos
        ? process.env.REACT_APP_CODECOSMOS_LOGOUT_URL
        : process.env.REACT_APP_CODECOSMOS_LOGOUT_URL,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  useEffect(() => {
    logout();
  }, [logout]);

  return <div className="flex justify-center items-center pt-10" />;
};

export default Logout;
