import { useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { NotificationContext } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { remove } from 'api/index';

export const URL = (userId) =>
  `${process.env.REACT_APP_API_URL}/exercises/users/${userId}`;

export const DELETE = (exerciseId) =>
  `${process.env.REACT_APP_API_URL}/exercises/${exerciseId}`;

const useExercises = (userId) => {
  const t = useFormatMessage();
  const queryClient = useQueryClient();

  const readUrl = useMemo(() => URL(userId), [userId]);

  const { addNotification } = useContext(NotificationContext);

  const {
    data: exercises,
    isLoading,
    isError,
  } = useQuery(readUrl, {
    enabled: !!userId,
  });

  const removeExerciseMutation = useMutation(
    (exerciseId) => remove(DELETE(exerciseId)),
    {
      onMutate: async (exerciseId) => {
        await queryClient.cancelQueries();

        const prev = queryClient.getQueryData(readUrl);

        queryClient.setQueryData(
          readUrl,
          prev.filter((e) => e.id !== exerciseId),
        );

        return { prev };
      },
      onSettled: () => {
        queryClient.invalidateQueries(URL(userId));

        addNotification({
          type: 'success',
          content: t('exercises-overview.delete_success'),
        });
      },
      onError: (_err, _changed, context) => {
        queryClient.setQueryData(readUrl, context.prev);

        addNotification({
          type: 'error',
          content: t('exercises-overview.delete_fail'),
        });
      },
    },
  );

  return {
    exercises: exercises,
    removeExerciseMutation: removeExerciseMutation,
    isLoading: isLoading,
    isError: isError,
  };
};

export default useExercises;
