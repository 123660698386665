import { Children, cloneElement, useState } from 'react';

import usePopper from 'hooks/usePopper';

const Popover = ({ trigger: triggerFn, children, placement }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const close = () => {
    setIsOpen(false);
  };

  const [trigger, container] = usePopper({
    placement,
    strategy: 'fixed',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  });

  const triggerElement = triggerFn({ isOpen, toggle, close });
  const child = Children.only(children({ close }));

  return (
    <div className="relative z-10">
      <span ref={trigger}>{triggerElement}</span>
      <div ref={container}>{isOpen && cloneElement(child)}</div>
    </div>
  );
};

Popover.defaultProps = {
  placement: 'bottom',
};

export default Popover;
