const urls = {
  ROOT: '/',
  CALLBACK: '/callback',
  CLASSGROUP: '/classgroups/:classGroupId',
  LOGOUT: '/logout',
  PROFILE: '/profile',
  EXERCISE: '/exercises',
  HELP: '/help',
  ADMIN: '/admin',
  EXERCISE_URLS_START: `/exercises/start/:startId/:language?`,
  EXERCISE_URLS_NEW: `/exercises/new`,
  EXERCISE_URLS_ID: `/exercises/:exerciseId`,
  EXERCISE_OVERVIEW: '/exercises',
  NO_CLASSES: '/no-classes',
  STATUS: '/status',
  NO_LICENCE: '/no-licence',
  REDIRECT_STORE: '/store',
};

export default urls;
