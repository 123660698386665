import { useQuery } from 'react-query';

import queryClient from 'utils/queryClient';

import { URL as USER_EXERCISE_URL } from './useExercises';

export const URL = (id) =>
  `${process.env.REACT_APP_API_URL}/exercises/classGroups/${id}`;

const useClassGroupStudents = (classGroupId) => {
  const {
    data: classGroupStudents,
    isLoading,
    error,
  } = useQuery({
    queryKey: URL(classGroupId),
    onSuccess: (students) => {
      students.forEach((student) => {
        queryClient.setQueryData(
          USER_EXERCISE_URL(student.id),
          student.exercises,
        );
      });
    },
  });

  return { classGroupStudents, isLoading, error };
};

export default useClassGroupStudents;
