import Footer from '../../components/Footer';

const NoLoginLayout = ({ children }) => (
  <div className=" flex flex-col w-screen h-screen">
    <div className="flex-1">{children}</div>
    <Footer className="flex-0" />
  </div>
);

export default NoLoginLayout;
