import useFormatMessage from 'hooks/useFormatMessage';

const RedirectStore = () => {
  window.location.href = process.env.REACT_APP_STORE_URL;

  const t = useFormatMessage();

  return (
    <div className="flex h-full items-center">
      <p>
        {t('errors.redirect_store_part_1')}{' '}
        <a
          className="underline text-blue"
          href={process.env.REACT_APP_STORE_URL}
        >
          {' '}
          {t('errors.redirect_store_part_2')}
        </a>{' '}
        {t('errors.redirect_store_part_3')}
      </p>
    </div>
  );
};

export default RedirectStore;
