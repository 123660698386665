import * as Sentry from '@sentry/react';

const initSentry = () => {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `ftrprf-next-frontend@${process.env.REACT_APP_VERSION}`,
    environment: window.location.hostname,
  });
};

export default initSentry;
