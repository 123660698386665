import { useHistory } from 'react-router-dom';

import { ConfirmationDialog } from '@ftrprf/tailwind-components';

import useCurrentContent from 'hooks/useCurrentContent';
import useFormatMessage from 'hooks/useFormatMessage';

const QuestionWarning = ({
  warningData,
  setWarningData,
  viewMode,
  submitAnswers,
}) => {
  const t = useFormatMessage();
  const history = useHistory();

  const { viewUrl } = useCurrentContent();

  const onDismiss = () => {
    setWarningData((prev) => ({ ...prev, isOpen: false }));
    history.replace(viewUrl(viewMode, warningData.nextSlide));
  };

  return (
    <ConfirmationDialog
      title={t('content-slide-viewer.forgot-to-save')}
      content={
        <span>{t('content-slide-viewer.forgot-to-save-description')}</span>
      }
      isOpen={warningData.isOpen}
      onConfirm={() => {
        submitAnswers();

        setWarningData((prev) => ({ ...prev, isOpen: false }));
        history.replace(viewUrl(viewMode, warningData.nextSlide));
      }}
      onDismiss={onDismiss}
    />
  );
};

export default QuestionWarning;
