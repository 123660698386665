import {
  Content,
  FormGroup,
  PageHeader,
  PageTitle,
} from '@ftrprf/tailwind-components';

import useCurrentUser from 'hooks/api/useCurrentUser';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import IDPS from 'utils/constants/idps';

import Language from './partials/Language';
import ProfileInfo from './partials/ProfileInfo';
import UpdatePassword from './partials/UpdatePassword';

const Profile = () => {
  const t = useFormatMessage();
  const { user, updateLanguage, updateEulaAccepted } = useCurrentUser();

  useTitle(t('profile.title'));

  return (
    <>
      <PageHeader>
        <PageTitle>{t('profile.title')}</PageTitle>
      </PageHeader>
      <Content>
        <div className="w-full max-w-screen-sm flex flex-col items-start mt-2">
          <FormGroup>
            <Language
              currentLanguageKey={user.language}
              updateLanguage={updateLanguage}
            />
          </FormGroup>

          <FormGroup>
            <ProfileInfo user={user} updateEulaAccepted={updateEulaAccepted} />
          </FormGroup>

          {user.idp === IDPS.FTRPRF && (
            <FormGroup>
              <UpdatePassword user={user} />
            </FormGroup>
          )}
        </div>
      </Content>
    </>
  );
};

export default Profile;
