import { useContext, useState } from 'react';

import { SlideViewerContext } from '../SlideViewerContextProvider';

export const SLIDEVIEWER_PREFIX = 'SlideViewer__';
export const STYLED_BUTTON_CLASS = `${SLIDEVIEWER_PREFIX}StyledButton`;

export const allLinkStyles = {
  EXERCISE: 'ExerciseLink',
  MOVIE: 'VideoLink',
  EXTERNAL: 'ExternalLink',
  OTHER: 'OtherLink',
  DOWNLOAD: 'DownloadLink',
  SLIDE: 'SlideLink',
};

export const EXERCISE_LINKS = {
  OLD: 'EXERCISE_SCRATCH',
  NEW: 'SlideViewer__ExerciseLink',
};

export const LESSON_ID = 'LESSON_ID';

const forceDownload = (blob, filename) => {
  var a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const downloadResource = (url, filename) => {
  if (!filename) {
    filename = url.split('\\').pop().split('/').pop();
  } else {
    if (filename.split('.').length < 2) {
      filename = `${filename}.${url.split('.').pop()}`;
    }
  }

  fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    });
};

const Link = ({
  className,
  href,
  children,
  versionid: versionId,
  ['data-studio-link-id']: dataStudioLinkId,
  ['data-target-slide-id']: slideId,
  ['download-url']: downloadUrl,
  ['download-file-name']: downloadFileName,
  ['target']: target,
}) => {
  const { generateScratchExercisePath, setCurrentSlideId, lesson } =
    useContext(SlideViewerContext);
  const [url, setUrl] = useState();

  const isExercise =
    className?.includes(EXERCISE_LINKS.OLD) ||
    className?.includes(EXERCISE_LINKS.NEW);

  if (isExercise) {
    if (!!dataStudioLinkId && !!versionId && generateScratchExercisePath) {
      generateScratchExercisePath({ versionId, dataStudioLinkId }).then(
        (response) => {
          setUrl(response);
        },
      );
    }

    return (
      <a
        className={`${STYLED_BUTTON_CLASS} ${EXERCISE_LINKS.NEW}`}
        // there might be a possibility where the exercises contain other hrefs
        // to external exercises for example. If this is the case, just pass href
        href={url ? url : href}
        rel="noreferrer"
        target="_blank"
      >
        {children}
      </a>
    );
  }

  if (slideId) {
    return (
      <a
        className={className}
        onClick={
          target === '_blank'
            ? () => {}
            : // eslint-disable-next-line radix
              () => setCurrentSlideId(parseInt(slideId))
        }
        href={
          target === '_blank'
            ? window.location.href.replace(/.*[0-9*]$/g, slideId)
            : null
        }
        target={target === '_blank' ? target : '_self'}
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }

  if (downloadUrl) {
    return (
      <a
        className={className}
        onClick={() => downloadResource(downloadUrl, downloadFileName)}
      >
        {children}
      </a>
    );
  }
  if (href?.includes(LESSON_ID)) {
    href = href.replace(LESSON_ID, lesson?.id);
  }

  return (
    <a className={className} href={href} rel="noreferrer" target={target}>
      {children}
    </a>
  );
};

export default Link;
