import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { classGroupUrls, curriculaUrls } from 'app/ClassGroupApp';

import {
  ChromeInfoBar,
  Content,
  Header,
  MenuButton,
} from '@ftrprf/tailwind-components';

import useClassGroups from 'hooks/api/useClassGroups';
import useCurrentUser, { LOGOUT_LOCATION } from 'hooks/api/useCurrentUser';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';
import useFormatMessage from 'hooks/useFormatMessage';

import urls from 'utils/constants/urls';

const StudentHeader = ({ logo, beforeNavigationChildren }) => {
  const t = useFormatMessage();

  const { user } = useCurrentUser();
  const { pathname } = useLocation();
  const classGroup = useCurrentClassGroup();
  const classGroupId = classGroup?.id;
  const { classGroups } = useClassGroups();

  const hasClassGroups = classGroups?.length > 0;

  const history = useHistory();

  return (
    <>
      <Header
        logo={logo}
        beforeNavigationChildren={beforeNavigationChildren}
        profileChildren={() => (
          <div>
            <MenuButton onClick={() => history.push(urls.PROFILE)}>
              {t('header.profile')}
            </MenuButton>
            <MenuButton onClick={() => history.push(LOGOUT_LOCATION)}>
              {t('header.logout')}
            </MenuButton>
          </div>
        )}
        label={t('header.hello')}
        userName={user.firstName}
      >
        <NavLink
          to={
            hasClassGroups &&
            `/classgroups/${classGroupId}/${curriculaUrls.LESSON}`
          }
          active={
            pathname.startsWith(
              `/classgroups/${classGroupId}/${curriculaUrls.LESSON}`,
            ) ||
            pathname.startsWith(
              `/classgroups/${classGroupId}/${classGroupUrls.LESSON}`,
            )
          }
        >
          <button>{t('header.navigation.lessons')}</button>
        </NavLink>

        <NavLink to="/exercises" active={pathname.startsWith('/exercises')}>
          <button>{t('header.navigation.exercises')}</button>
        </NavLink>
      </Header>
      <ChromeInfoBar container={(children) => <Content>{children}</Content>} />
    </>
  );
};

export default StudentHeader;
