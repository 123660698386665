import useExercises from 'hooks/api/useExercises';
import useUser from 'hooks/api/useUser';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import ExercisesOverview from './ExercisesOverview';

const ExercisesOverviewTeacherContainer = () => {
  const t = useFormatMessage();
  const { studentId } = useParams();

  const {
    user,
    isLoading: userLoading,
    isError: userError,
  } = useUser(studentId);
  const {
    exercises,
    isLoading: exercisesLoading,
    isError: exercisesError,
  } = useExercises(studentId);

  const title = userLoading
    ? t('exercises-overview.pretitle')
    : `${t('exercises-overview.title')} ${user?.firstName} ${user?.lastName}`;

  return (
    <ExercisesOverview
      exercises={exercises}
      userId={studentId}
      title={title}
      isLoading={userLoading || exercisesLoading}
      isError={userError || exercisesError}
      hasCreateExercise={false}
      canRemoveExercise={false}
    />
  );
};

export default ExercisesOverviewTeacherContainer;
