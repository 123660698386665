import { createContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { usePrevious } from '@ftrprf/tailwind-components';

import useClassGroups from 'hooks/api/useClassGroups';

import redirectToExistingClassgroup, {
  getClassGroupFromLocation,
} from 'utils/redirectToExistingClassgroup';

export const CurrentClassGroupContext = createContext({});

const isClassGroupsURL = (location) => {
  return location.pathname.startsWith('/classgroups/');
};

const CurrentClassGroupProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { isLoading, classGroups } = useClassGroups();

  const _currentClassGroupId = getClassGroupFromLocation(location);

  // Normally, we determine the classGroupId by looking at the URL
  // When a user navigates to the /profile page, there is no classGroupId and thus
  // we 'forgot' about the classGroupId. This line will help remember the previous
  // selected classGroupId
  const prevCurrentClassGroupId = usePrevious(_currentClassGroupId);
  const currentClassGroupId = _currentClassGroupId || prevCurrentClassGroupId;

  const currentClassGroup = classGroups.find(
    (c) => c.id === currentClassGroupId,
  );

  const hasExistingClassGroup = !!currentClassGroup;

  useEffect(() => {
    if (!isLoading && isClassGroupsURL(location) && !hasExistingClassGroup) {
      redirectToExistingClassgroup(history, location, classGroups);
    }
  }, [isLoading, hasExistingClassGroup, history, location, classGroups]);

  if (isLoading) {
    return null;
  }

  const currentClassGroupWithDefault = currentClassGroup || classGroups[0];

  return (
    <CurrentClassGroupContext.Provider value={currentClassGroupWithDefault}>
      {children}
    </CurrentClassGroupContext.Provider>
  );
};

export default CurrentClassGroupProvider;
