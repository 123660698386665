import useCurrentUser from 'hooks/api/useCurrentUser';
import useExercises from 'hooks/api/useExercises';

import ExercisesOverview from './ExercisesOverview';

const UserExercisesOverviewContainer = () => {
  const { user } = useCurrentUser();
  const { exercises, removeExerciseMutation, isLoading, isError } =
    useExercises(user.id);

  return (
    <ExercisesOverview
      exercises={exercises}
      isLoading={isLoading}
      isError={isError}
      userId={user.id}
      hasCreateExercise={true}
      removeExercise={removeExerciseMutation.mutate}
    />
  );
};

export default UserExercisesOverviewContainer;
