import { Link } from 'react-router-dom';

import { Badge, ContentCard } from '@ftrprf/tailwind-components';

import { classGroupContentHelpers } from 'providers/ContentProvider';

import useClassGroupContentList from 'hooks/api/useClassGroupContentList';
import useCurrentUser from 'hooks/api/useCurrentUser';
import useDocument from 'hooks/api/useDocument';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';
import useFormatMessage from 'hooks/useFormatMessage';

import publishStatuses from 'utils/constants/publishStatuses';

import { ReactComponent as EmptyImage } from 'assets/vectors/empty-image.svg';
import { ReactComponent as Loader } from 'assets/vectors/logo-animated-lines.svg';

const ClassGroupContentStatus = ({ classGroupContent }) => {
  const t = useFormatMessage();

  return (
    <Badge
      className="px-2 text-sm"
      success={[publishStatuses.OPEN_FOR_CLASSGROUP].includes(
        classGroupContent.publishStatus,
      )}
      warning={publishStatuses.CLOSED === classGroupContent.publishStatus}
      succesAccent={
        publishStatuses.OPEN_FOR_HOME === classGroupContent.publishStatus
      }
    >
      {t(`publish-statuses.${classGroupContent.publishStatus}`)}
    </Badge>
  );
};

const ContentOverview = ({ curricula, contentType, defaultViewMode }) => {
  const t = useFormatMessage();
  const download = useDocument();

  const { isCodeCosmosStudent, isCodeCosmosTeacherOrAdmin } = useCurrentUser();
  const { id: classGroupId } = useCurrentClassGroup();

  const { classGroupContentList, isLoading } = useClassGroupContentList(
    curricula.id,
    contentType,
  );

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader className="w-32" />
      </div>
    );
  }

  if (classGroupContentList.length === 0) {
    return (
      <div>
        {t(
          `curricula.${contentType}.content-overview.${
            isCodeCosmosStudent ? 'STUDENT' : 'TEACHER'
          }.empty`,
        )}
      </div>
    );
  }

  return (
    <div className="w-full grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
      {classGroupContentList.map((classGroupContent) => {
        const { viewUrl, resultsUrl, overviewUrl } = classGroupContentHelpers({
          classGroupContent,
          classGroupId,
          contentType,
          contentId: classGroupContent.content.id,
          defaultViewMode,
        });

        // show the results when the user is a teacher, or when the results are published
        const showResults =
          isCodeCosmosTeacherOrAdmin || classGroupContent.resultsPublished;

        const showView =
          isCodeCosmosTeacherOrAdmin ||
          classGroupContent.publishStatus !== publishStatuses.CLOSED;

        return (
          <div key={classGroupContent.id} className="w-full">
            <ContentCard
              defaultImage={EmptyImage}
              image={classGroupContent.content.imageUrl}
              title={classGroupContent.content.title}
              statusLabel={
                <ClassGroupContentStatus
                  classGroupContent={classGroupContent}
                />
              }
              onDocument={
                classGroupContent.content.document
                  ? () => download(classGroupContent.content.document)
                  : undefined
              }
            >
              {showView && (
                <ContentCard.Action>
                  <Link to={viewUrl()}>
                    <div>{t('global.view')}</div>
                  </Link>
                </ContentCard.Action>
              )}
              {showResults && (
                <ContentCard.Action>
                  <Link to={resultsUrl}>{t('content.results')}</Link>
                </ContentCard.Action>
              )}

              {isCodeCosmosTeacherOrAdmin && (
                <ContentCard.Action>
                  <Link to={overviewUrl}>{t('content.overview')}</Link>
                </ContentCard.Action>
              )}
            </ContentCard>
          </div>
        );
      })}
    </div>
  );
};

export default ContentOverview;
