import { FaTrash } from 'react-icons/fa';

import { Button, Loader } from '@ftrprf/tailwind-components';

import Avatar from 'components/Avatar';

import useFormatDate from 'hooks/useFormatDate';

import c from 'utils/c';

const FeedbackItem = ({
  feedback: { comment, teacher, id, submittedAt, isInvalidated },
  onDelete,
  user,
}) => {
  const formatDate = useFormatDate();

  const canDelete = user?.id === teacher?.id;

  const displayName = `${teacher.firstName} ${teacher.lastName}`;

  return (
    <div className="flex justify-between">
      <div
        className={c('flex items-start group', isInvalidated && 'opacity-25')}
      >
        <Avatar
          className="w-6 h-6 text-sm"
          firstName={teacher?.firstName || ''}
          lastName={teacher?.lastName || ''}
        />
        <div className="ml-2">
          <div className="flex items-end leading-none">
            <div className="font-semibold mr-1 mt-px">{displayName}</div>
            <div className="text-xs text-gray-600">
              {formatDate(submittedAt)}
            </div>
          </div>
          <div>
            <div className="text-sm">
              {comment}
              {canDelete && (
                <Button
                  onClick={() => onDelete(id)}
                  className="hidden group-hover:inline ml-1"
                >
                  <FaTrash className=" w-3 h-3 text-gray-800" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {isInvalidated && (
        <div className="h-5 w-5">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default FeedbackItem;
