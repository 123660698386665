import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { ListContextMenu } from '@ftrprf/context-menu';
import { MenuButton } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

const ContentCardContextMenu = ({
  children,
  editURL,
  onDelete,
  hasRemoveExercise,
}) => {
  const t = useFormatMessage();

  return (
    <ListContextMenu trigger={children}>
      {editURL && (
        <Link to={editURL}>
          <MenuButton iconBefore={FaPencilAlt}>{t('global.edit')}</MenuButton>
        </Link>
      )}

      {hasRemoveExercise && (
        <MenuButton onClick={onDelete} iconBefore={FaTrash}>
          {t('global.delete')}
        </MenuButton>
      )}
    </ListContextMenu>
  );
};

export default ContentCardContextMenu;
