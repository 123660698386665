import { useCallback, useState } from 'react';

import {
  generateSubmittedQuestionAnswersState,
  generateTemporaryQuestionAnswersState,
} from '@ftrprf/tailwind-components';

import useCurrentUser from 'hooks/api/useCurrentUser';
import useCurrentContent from 'hooks/useCurrentContent';

import ContentSlideViewer from './ContentSlideViewer';

const FEEDBACK_URL = ({ slideId, lesson, user, contentType, programId }) => {
  if (!slideId || !user || !lesson) {
    return null;
  }

  return `https://docs.google.com/forms/d/e/1FAIpQLSciYGPPHtFCTcnKXhSNvyU-Hz_Qy9l-9i9Key-Ewp2ZyDXrJw/viewform?usp=pp_url&entry.2145939336=${encodeURIComponent(
    `${user.firstName} ${user.lastName}`,
  )}&entry.410557796=${slideId}&entry.619418695=${
    lesson.id
  }&entry.1623489133=${programId}&entry.1966597930=${encodeURIComponent(
    lesson?.title,
  )}&entry.1378317072=${contentType}`.replace(/ /g, '+');
};

const TeacherContainer = () => {
  const { user } = useCurrentUser();
  const { contentType, programId } = useCurrentContent();

  const [canEdit, setCanEdit] = useState(true);

  const generateFeedbackURL = useCallback(
    ({ slideId, lesson }) =>
      FEEDBACK_URL({ slideId, lesson, user, contentType, programId }),
    [user, contentType, programId],
  );

  const [temporaryQuestionAnswers, setTemporaryQuestionAnswers] = useState({});
  const [submittedQuestionAnswers, setSubmittedQuestionAnswers] = useState([]);

  const onTemporaryQuestionAnswers = useCallback(
    (question, value) => {
      setTemporaryQuestionAnswers(
        generateTemporaryQuestionAnswersState(question, value),
      );
    },
    [setTemporaryQuestionAnswers],
  );

  // This generates the output that normally the API will generate
  const onSubmitQuestionAnswers = useCallback((question, value) => {
    return new Promise((resolve) => {
      const submittedQuestionAnswer = generateSubmittedQuestionAnswersState(
        question,
        value,
      );

      setSubmittedQuestionAnswers((prev) => {
        const filteredSubmittedAnswers = prev.filter(
          ({ questionId }) => questionId !== question.id,
        );
        return [...filteredSubmittedAnswers, submittedQuestionAnswer];
      });

      // clear the temporary value for the current question
      setTemporaryQuestionAnswers(
        ({ [question.id]: _, ...otherQuestions }) => ({
          ...otherQuestions,
        }),
      );

      resolve({
        question,
        submittedQuestionAnswer,
      });
    });
  }, []);

  const onHardSubmit = useCallback(() => {
    return new Promise((resolve) => {
      setCanEdit(false);
      resolve();
    });
  }, []);

  return (
    <ContentSlideViewer
      canEdit={canEdit}
      isLoading={false}
      contentType={contentType}
      generateFeedbackURL={generateFeedbackURL}
      submittedQuestionAnswers={submittedQuestionAnswers}
      temporaryQuestionAnswers={temporaryQuestionAnswers}
      onTemporaryQuestionAnswers={onTemporaryQuestionAnswers}
      onSubmitQuestionAnswers={onSubmitQuestionAnswers}
      onHardSubmit={onHardSubmit}
    />
  );
};

export default TeacherContainer;
