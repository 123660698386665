import { gql } from 'graphql-request';

export const CREATE_SCRATCH_EXERCISE = gql`
  mutation CreateScratchExercise(
    $exerciseVersionId: Int!
    $exerciseId: Int!
    $file: Upload
  ) {
    createScratchExercise(
      exerciseVersionId: $exerciseVersionId
      exerciseId: $exerciseId
      file: $file
    ) {
      exerciseId
    }
  }
`;

export const CREATE_SCRATCH_JUDGE = gql`
  mutation CreateScratchJudge(
    $exerciseVersionId: Int!
    $testPlanId: Int!
    $file: Upload
    $language: String
  ) {
    createScratchJudge(
      input: {
        exerciseVersionId: $exerciseVersionId
        testPlanId: $testPlanId
        language: $language
      }
      file: $file
    ) {
      id
      message
    }
  }
`;

export const FIND_EXERCISE_TEST_RESULT = gql`
  query FindScratchJudgeResults($sessionId: String!) {
    findScratchJudgeResults(sessionId: $sessionId) {
      id
      result
    }
  }
`;
