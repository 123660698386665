import { useCallback, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router';

import { ConfirmationDialog as RemoveUserDialog } from '@ftrprf/tailwind-components';

import ResetPasswordDialog from 'components/ResetPasswordDialog';

import useClassGroups from 'hooks/api/useClassGroups';
import useSuperUser from 'hooks/api/useSuperUser';
import useUser from 'hooks/api/useUser';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import urls from '../../utils/constants/urls';

import { adminUrls } from '../../app/AdminApp';
import { classGroupUrls } from '../../app/ClassGroupApp';

import StudentProfile from './StudentProfile';

const StudentProfileContainer = ({ canDelete, canEdit }) => {
  const t = useFormatMessage();
  const history = useHistory();
  const { studentId, classGroupId } = useParams();
  const { data: _user, isLoading, isError } = useUser(studentId);
  const { id: currentClassGroupId } = useCurrentClassGroup();
  const { classGroups: _classGroups } = useClassGroups();

  const {
    isSaving,
    resetModalIsOpened,
    removeModalIsOpened,
    resetPassword,
    removeUser,
    editUser,
    openRemoveModal,
    openResetModal,
    closeRemoveModal,
    closeResetModal,
  } = useSuperUser({ roleId: 0 });

  const user = useMemo(
    () => ({
      ...(!isLoading && {
        ..._user,
        classGroups: _user?.classGroups.map((c) => c.name),
      }),
    }),
    [_user, isLoading],
  );

  const classGroups = useMemo(
    () =>
      Object.fromEntries(
        _classGroups.map((classGroup) => [
          classGroup.name,
          {
            key: classGroup.id,
            value: classGroup.name,
            label: classGroup.name,
          },
        ]),
      ),
    [_classGroups],
  );

  const onSubmit = useCallback(
    (data) => {
      const {
        classGroups: classGroupNames,
        firstName,
        lastName,
        username,
      } = data;

      editUser({
        user: {
          classGroupIds: classGroupNames.map((cName) => classGroups[cName].key),
          firstName,
          lastName,
          username,
        },
        userId: user.id,
      });
    },
    [classGroups, editUser, user.id],
  );

  if (!_user) {
    return null;
  }

  return (
    <>
      <ResetPasswordDialog
        isResetting={isLoading}
        isOpen={!!resetModalIsOpened}
        onDismiss={closeResetModal}
        resetPassword={(confirmationPassword) => {
          resetPassword({ userId: studentId, confirmationPassword });
        }}
      />

      {canDelete && (
        <RemoveUserDialog
          content={t('student-overview.are-you-sure')}
          isOpen={!!removeModalIsOpened}
          onConfirm={() => {
            removeUser(studentId);
            if (classGroupId) {
              history.replace(
                generatePath(
                  `${urls.CLASSGROUP}/${classGroupUrls.STUDENT_OVERVIEW}`,
                  {
                    classGroupId: currentClassGroupId,
                  },
                ),
              );
            } else {
              history.replace(
                generatePath(`/admin/${adminUrls.STUDENT_OVERVIEW}`),
              );
            }
          }}
          onDismiss={closeRemoveModal}
        />
      )}

      <StudentProfile
        user={user}
        availableClassGroups={classGroups}
        classGroupId={classGroupId}
        isLoading={isLoading}
        isError={isError}
        isSaving={isSaving}
        canDelete={canDelete}
        canEdit={canEdit}
        resetPassword={resetPassword}
        removeUser={removeUser}
        onSubmit={onSubmit}
        openRemoveModal={openRemoveModal}
        openResetModal={openResetModal}
      />
    </>
  );
};

StudentProfileContainer.defaultProps = {
  canDelete: false,
};

export default StudentProfileContainer;
