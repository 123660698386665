export default {
  'styled-slide-viewer.open_the_sidebar': 'Open the sidebar',

  'styled-slide-viewer.sidebar.questions_not_saved':
    'Your submitted answers will not be saved',

  'styled-slide-viewer.previous': 'Previous',
  'styled-slide-viewer.next': 'Next',

  'styled-slide-viewer.teacher_manual': 'Teacher manual',
  'styled-slide-viewer.teacher_manual_empty':
    'No additional explanation is needed with this slide',

  'styled-slide-viewer.slides_overview': 'Slides overview',

  'styled-slide-viewer.settings': 'Settings',
  'styled-slide-viewer.settings.feedback': 'Feedback',
  'styled-slide-viewer.settings.feedback_explanation':
    'Give feedback on this slide',

  'styled-slide-viewer.settings.zoom': 'Zoom',
  'styled-slide-viewer.settings.zoom_explanation':
    'Use + and - to zoom in and out the slides.',

  'styled-slide-viewer.view-modes.projection': 'Projection',
  'styled-slide-viewer.view-modes.selfstudy': 'Self study',
  'styled-slide-viewer.view-modes.classical': 'Classical',

  'styled-slide-viewer.submit': 'Submit',
  'styled-slide-viewer.submitted': 'Submitted',
  'styled-slide-viewer.save': 'Save',
  'styled-slide-viewer.saved': 'Saved',
  'styled-slide-viewer.close': 'Close',
  'styled-slide-viewer.no_slides': 'There are no slides in this lesson',
  'styled-slide-viewer.empty_title': 'Section slide',
  'styled-slide-viewer.submit_answers': 'Submit answers',
  'styled-slide-viewer.answer_placeholder': 'Enter your answer',

  'styled-slide-viewer.hints': 'Hints',
  'styled-slide-viewer.hint': 'Hint',
  'styled-slide-viewer.hint-dialog.title': 'Hint',

  'styled-slide-viewer.questions.not_supported_type': 'The question could not be displayed: the type of the question is not supported.'
};
