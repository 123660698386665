import c from 'utils/c';

const Avatar = ({ className, firstName, lastName }) => {
  return (
    <div
      className={c(
        className,
        'bg-gray-400 text-sm text-white rounded-full leading-none flex flex-shrink-0 items-center justify-center select-none',
      )}
    >
      <span className="uppercase">
        {firstName.charAt(0)}
        {lastName.charAt(0)}
      </span>
    </div>
  );
};

Avatar.defaultProps = {
  firstName: '',
  lastName: '',
};

export default Avatar;
