import { useMemo, useState } from 'react';

import useHackRoom from 'hooks/api/useHackRoom';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';

import HackRoom from './HackRoom';

const HackRoomContainer = () => {
  const [filter, setFilter] = useState('');
  const isFiltering = filter !== '';

  const { id: classGroupId } = useCurrentClassGroup();
  const { hackRoomLessons, isLoading: isLoadingHackRoomLessons } = useHackRoom({
    classGroupId,
  });

  const filteredLessons = useMemo(
    () =>
      isFiltering
        ? hackRoomLessons.filter((lesson) =>
            lesson?.title.toLowerCase().includes(filter.toLowerCase()),
          )
        : hackRoomLessons,
    [filter, hackRoomLessons, isFiltering],
  );

  return (
    <HackRoom
      hackRoomLessons={filteredLessons}
      featuredLessons={hackRoomLessons?.slice(0, 6)}
      isLoadingHackRoomLessons={isLoadingHackRoomLessons}
      classGroupId={classGroupId}
      setFilter={setFilter}
    />
  );
};

export default HackRoomContainer;
