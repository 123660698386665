import faker from 'faker';

import { generateFeedback } from './feedback';

export const lessonDetails = {
  id: 'd49515ed-972c-11e9-b935-0a0029000023',
  lessonContentId: 955,
  title: '(a)Sociale media',
  defaultPublishStatus: 'CLOSED',
  sequence: 0,
  document: null,
  maxScore: 10,
};

const generateLessonQuestions = () => {
  faker.seed(69);

  return {
    archived: faker.datatype.boolean(),
    centurySkills: [],
    id: 1000,
    published: faker.datatype.boolean(),
    slides: Array.from(Array(10), (_, i) => ({
      title: faker.lorem.sentence(),
      content: faker.lorem.paragraph(),
      id: i,
      lessonId: 1000,
      module: 'NORMAL',
      questions: [
        {
          id: 100 + i,
          type: i % 7 ? 'MULTIPLE_CHOICE' : 'OPEN',
          questionAnswers:
            i % 7
              ? Array.from(Array(4), (_, i) => ({
                  id: `${200 + i}`,
                  track: true,
                  value: faker.lorem.sentence(),
                  correct: faker.datatype.boolean(),
                  explanation: faker.lorem.sentence(),
                }))
              : null,
        },
      ],
    })),
  };
};

const generateLessonAnswers = () => {
  faker.seed(69);

  return Array.from(Array(10), (_, i) => ({
    id: faker.datatype.uuid(),
    lessonContentId: faker.datatype.uuid(),
    type: 'MULTIPLE_CHOICE',
    questionId: 100 + i,
    userId: faker.datatype.uuid(),
    latestAnswerSubmittedAt: faker.date.past().toISOString(),
    answers:
      i % 7
        ? Array.from(Array(2), (_, j) => ({
            id: `${300 + j}`,
            multipleChoiceAnswerId: `${
              200 + (Math.ceil(Math.random() * 10) % 4)
            }`,
          }))
        : [{ id: '300', value: faker.lorem.sentences() }],
    feedback:
      i !== 0 ? Array.from(Array(10), () => ({ ...generateFeedback() })) : [],
  }));
};

const generateVersions = () => {
  faker.seed(69);

  return Array.from(Array(10), (_, i) => ({
    id: faker.datatype.uuid(),
    createdOn: faker.date.recent(),
    maxScore: 5,
    numberOfQuestions: 3,
    version: i,
  }));
};

const generateStudentLesson = () => {
  faker.seed(71);

  return {
    allQuestionsHaveScores: faker.datatype.boolean(),
    score: faker.datatype.number(9),
    maxScore: 10,
  };
};

export const lessonAnswers = generateLessonAnswers();
export const lessonQuestions = generateLessonQuestions();
export const lessonVersions = generateVersions();
export const studentLesson = generateStudentLesson();
