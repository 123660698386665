import {
  CheckBox,
  Input,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

const ProfileInfo = ({ user, updateEulaAccepted }) => {
  const t = useFormatMessage();

  return (
    <>
      <InputGroup>
        <Label>{t('profile.first_name')}</Label>
        <Input value={user.firstName} disabled />
      </InputGroup>
      <InputGroup>
        <Label>{t('profile.last_name')}</Label>
        <Input value={user.lastName} disabled />
      </InputGroup>
      <InputGroup>
        <Label>{t('profile.username')}</Label>
        <Input value={user.username} disabled />
      </InputGroup>
      <div>
        {process.env.NODE_ENV === 'development' && (
          <CheckBox
            label={t('profile.eula_accepted')}
            checked={user.eulaAccepted}
            onChange={updateEulaAccepted}
          />
        )}
      </div>
    </>
  );
};

export default ProfileInfo;
