import { useMemo } from 'react';

import useCRUDQuery from 'hooks/useCRUDQuery';

import { post, remove } from 'api/index';

import { normalize } from 'utils/normalizer';

export const STUDENTURL = ({ contentId, contentType }) =>
  `${process.env.REACT_APP_API_URL}/content/${contentType}/${contentId}`;

export const TEACHERURL = ({
  classGroupId,
  contentId,
  contentType,
  studentId,
}) =>
  `${process.env.REACT_APP_API_URL}/classgroups/${classGroupId}/content/${contentType}/${contentId}/students/${studentId}`;

const path = 'id.feedback:id';

const useFeedbacks = ({ endpoint, questionId }) => {
  const { data, create, ...response } = useCRUDQuery(
    `${endpoint}/answers`,
    path,
    {
      remove: (feedbackId) => remove(`${endpoint}/feedback/${feedbackId}`),
      create: (newFeedback) => post(`${endpoint}/feedback`, newFeedback),
      queryOptions: {
        staleTime: 1000,
      },
    },
  );

  const feedbacks = useMemo(
    () =>
      data &&
      Object.values(normalize(data, path)[questionId] || {}).sort(
        (f1, f2) => new Date(f1.submittedAt) - new Date(f2.submittedAt),
      ),
    [data, questionId],
  );

  return {
    feedbacks,
    create: (newObject) => create({ keys: [questionId], newObject }),
    ...response,
  };
};

export default useFeedbacks;
