import { useEffect, useMemo, useState } from 'react';
import {
  FaArrowLeft,
  FaCertificate,
  FaFileExcel,
  FaFileExport,
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {
  Content,
  DropdownButton,
  DropdownMenu,
  FilledButton,
  MenuButton,
  Table,
  VersionNumberSelector,
  VIEW_MODES,
  ViewModeButton,
} from '@ftrprf/tailwind-components';

import Indicator from 'components/Indicator';

import useCurrentUser from '../../hooks/api/useCurrentUser';
import useClassGroup from 'hooks/api/useClassGroup';
import useClassGroupContent from 'hooks/api/useClassGroupContent';
import useClassGroupContentStudents from 'hooks/api/useClassGroupContentStudents';
import useContent from 'hooks/api/useContent';
import useContentVersions from 'hooks/api/useContentVersions';
import useScoreExport from 'hooks/api/useScoreExport';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';
import useCurrentContent from 'hooks/useCurrentContent';
import useFormatMessage from 'hooks/useFormatMessage';
import useFormatNumber from 'hooks/useFormatNumber';
import useParams from 'hooks/useParams';
import useTitle from 'hooks/useTitle';

import calculateAverage from './utils/average';
import contentTypes from 'utils/constants/contentTypes';

import PublishResultsWarningDialog from './partials/PublishResultsWarningDialog';
import {
  StudentResultsOverviewContentSkeleton,
  StudentResultsOverviewTopSkeleton,
} from './partials/StudentResultsOverviewSkeleton';
import createColumns from './columns';

const validViewModes = {
  CLASSICAL: VIEW_MODES.CLASSICAL,
  SELFSTUDY: VIEW_MODES.SELFSTUDY,
};

const switchViewMode = (history, viewMode) => {
  history.replace({
    pathname: history.location.pathname,
    search: `?viewMode=${viewMode}`,
  });
};

const StudentResultsOverview = ({ contentType }) => {
  const t = useFormatMessage();
  const formatNumber = useFormatNumber();
  const history = useHistory();
  const { programUrl } = useCurrentContent();

  const { id: classGroupId, name: classGroupName } = useCurrentClassGroup();
  const { contentId: contentId, viewMode: _viewMode } = useParams();
  const [selectedVersion, setSelectedVersion] = useState();
  const [showPublishWarningDialog, setShowPublishWarningDialog] =
    useState(false);

  const viewMode = validViewModes[_viewMode]
    ? _viewMode
    : validViewModes.CLASSICAL;

  const { classGroup, isLoading: classGroupLoading } =
    useClassGroup(classGroupId);

  const { contentDetails } = useContent({
    contentId: contentId,
    contentType: contentType,
    viewMode,
  });

  const { user } = useCurrentUser();

  const {
    versions,
    versionMap,
    latestVersion,
    isLoading: versionsLoading,
  } = useContentVersions({
    contentId: contentId,
    contentType: contentType,
    viewMode,
  });

  useEffect(() => {
    setSelectedVersion(latestVersion);
  }, [latestVersion]);

  const {
    classGroupContent,
    isLoading: classGroupContentLoading,
    setResultsPublished,
  } = useClassGroupContent({ classGroupId, contentId, contentType });

  const {
    classGroupContentStudents,
    isLoading: classGroupContentStudentsLoading,
  } = useClassGroupContentStudents({
    classGroupId,
    contentId,
    contentType,
    version: selectedVersion?.id,
  });

  /**
   * 1. All questions need to be filled in by all students:
   *  Otherwise empty questions will be counted,
   *  and the average will be deceptively low
   * 2. All questions need to be graded:
   *  An open question is not automatically graded
   *  and again, the average will be deceptively low
   *
   * ==>  but 2 is a stronger requirement than 1,
   * so only checking on this the second requirement is okay.
   */
  const filteredStudents = classGroupContentStudents?.filter(
    ({ allQuestionsHaveScores: isFullyGraded }) => isFullyGraded,
  );

  const isAverageCalculatable = filteredStudents?.length > 0;
  const isCompleteAverage =
    filteredStudents?.length === classGroupContentStudents?.length;

  const average = calculateAverage(filteredStudents);

  const isSmartSchoolUser = user?.idp === 'SMARTSCHOOL';

  // can be exported when results can be viewed for at least 1 student
  const canBeExportedToExcel = classGroupContentStudents?.some(
    ({ latestAnswerSubmittedAt: latestAnswerSubmittedAt }) =>
      latestAnswerSubmittedAt,
  );

  const { generateExcelFile, sendToSmartschool, downloadStarted } =
    useScoreExport({
      classGroupId,
      classGroupName,
      contentId,
      contentType,
      versionId: selectedVersion?.id,
      lessonTitle: contentDetails?.title,
    });

  useTitle(
    t('results-overview.title.results'),
    contentDetails?.title,
    classGroup?.name,
  );

  const columns = useMemo(
    () =>
      createColumns({
        classGroupId,
        contentId,
        t,
        version: selectedVersion,
        viewMode,
        contentType,
        versions,
        setSelectedVersion,
      }),
    [
      classGroupId,
      contentId,
      t,
      selectedVersion,
      viewMode,
      contentType,
      versions,
    ],
  );

  const amountOfInCompleteStudents = classGroupContentStudents?.filter(
    ({ allQuestionsAnswered }) => !allQuestionsAnswered,
  ).length;
  const amountOfnotFullyGradedStudents = classGroupContentStudents?.filter(
    ({ allQuestionsHaveScores }) => !allQuestionsHaveScores,
  ).length;

  const publishResults = () => {
    if (
      (amountOfInCompleteStudents > 0 || amountOfnotFullyGradedStudents > 0) &&
      !showPublishWarningDialog
    ) {
      setShowPublishWarningDialog(true);
    } else {
      setResultsPublished(true);
    }
  };
  const unpublishResults = () => setResultsPublished(false);

  return (
    <>
      <PublishResultsWarningDialog
        isOpen={showPublishWarningDialog}
        onDismiss={() => setShowPublishWarningDialog(false)}
        onConfirm={() => {
          publishResults();
          setShowPublishWarningDialog(false);
        }}
        amountOfInCompleteStudents={amountOfInCompleteStudents}
        amountOfnotFullyGradedStudents={amountOfnotFullyGradedStudents}
      />

      <StudentResultsOverviewTopSkeleton
        isLoading={
          classGroupLoading ||
          classGroupContentLoading ||
          classGroupContentStudentsLoading ||
          versionsLoading
        }
      >
        <Content hasPaddingBottom={false}>
          <div className="w-full flex justify-between">
            <div className="flex">
              <Link to={programUrl}>
                <FilledButton secondary iconBefore={FaArrowLeft}>
                  {t('global.go_back')}
                </FilledButton>
              </Link>
              <div className="flex items-center ml-4">
                <p>{t('results-overview.version')}:</p>
                <div className="pt-2 ml-2">
                  <VersionNumberSelector
                    values={Object.keys(versionMap)}
                    selected={selectedVersion?.version}
                    onChange={(version) =>
                      setSelectedVersion(versionMap[version])
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex">
              {isAverageCalculatable && (
                <div className="lg:self-end flex gap-x-2 items-baseline flex-grow-0">
                  <span className="uppercase text-md text-gray-600">
                    {t('results-overview.average')}
                  </span>
                  <div
                    className="flex"
                    title={t('results-overview.no_complete_average')}
                  >
                    <span className="text-lg">
                      {formatNumber(average)}
                      {' / '}
                      {selectedVersion?.maxScore}
                    </span>
                    {!isCompleteAverage && (
                      <div className="h-1 w-1 ml-1">
                        <Indicator />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {(canBeExportedToExcel || isSmartSchoolUser) && (
                <div className="flex justify-between items-end ml-7">
                  <DropdownMenu
                    trigger={({ isOpen }) => (
                      <DropdownButton
                        iconBefore={FaFileExport}
                        className=""
                        isOpen={isOpen}
                      >
                        {t('global.export')}
                      </DropdownButton>
                    )}
                  >
                    {isSmartSchoolUser && (
                      <MenuButton
                        iconBefore={FaCertificate}
                        onClick={() => sendToSmartschool()}
                      >
                        <div className="flex flex-col">
                          <span>
                            {t('results-overview.export_smartschool')}
                          </span>
                          <small className="text-gray-700 block">
                            Smartschool
                          </small>
                        </div>
                      </MenuButton>
                    )}
                    {canBeExportedToExcel && (
                      <MenuButton
                        iconBefore={FaFileExcel}
                        disabled={downloadStarted}
                        loaderClassName="w-3 h-3"
                        loading={downloadStarted}
                        onClick={() => generateExcelFile()}
                      >
                        <div>
                          {t(
                            downloadStarted
                              ? 'global.loading'
                              : 'results-overview.export_excel',
                          )}
                        </div>
                      </MenuButton>
                    )}
                  </DropdownMenu>
                </div>
              )}
              <div className="flex justify-between items-end ml-7">
                <FilledButton
                  accent
                  className="whitespace-nowrap"
                  onClick={() =>
                    classGroupContent?.resultsPublished
                      ? unpublishResults()
                      : publishResults()
                  }
                >
                  {classGroupContent?.resultsPublished
                    ? t('results-overview.unpublish_results')
                    : t('results-overview.publish_results')}
                </FilledButton>
              </div>
              {contentType === contentTypes.LESSON && (
                <div className="flex space-x-1 ml-7">
                  <div className="w-8 h-8">
                    <ViewModeButton
                      viewMode={validViewModes.CLASSICAL}
                      onClick={() =>
                        switchViewMode(history, validViewModes.CLASSICAL)
                      }
                      isActive={viewMode === validViewModes.CLASSICAL}
                    />
                  </div>

                  <div className="w-8 h-8">
                    <ViewModeButton
                      viewMode={validViewModes.SELFSTUDY}
                      onClick={() =>
                        switchViewMode(history, validViewModes.SELFSTUDY)
                      }
                      isActive={viewMode === validViewModes.SELFSTUDY}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Content>
      </StudentResultsOverviewTopSkeleton>
      <StudentResultsOverviewContentSkeleton
        classGroupContentStudentsLoading={classGroupContentStudentsLoading}
      >
        <Content>
          <Table columns={columns} data={classGroupContentStudents} />
        </Content>
      </StudentResultsOverviewContentSkeleton>
    </>
  );
};

export default StudentResultsOverview;
