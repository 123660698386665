import { Link } from 'react-router-dom';
import slugify from 'slugify';

import { Content, PageHeader, SubjectCard } from '@ftrprf/tailwind-components';
import { Breadcrumbs } from '@ftrprf/tailwind-components';

import PageTitle from 'components/PageTitle';

import useDocument from 'hooks/api/useDocument';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import { removeTrailingSlash } from '../../utils/removeLeadingAndTrailingSlash';
import contentTypes from 'utils/constants/contentTypes';

import ContentOverview from './partials/ContentOverview';

const Curricula = ({ curricula, url, path, contentType, defaultViewMode }) => {
  const t = useFormatMessage();
  const download = useDocument();

  const title = curricula.title;

  const items = (curricula.items || []).sort((a, z) => {
    const x = (a.sequence || 0) - (z.sequence || 0);

    // sort alphabetical when sequences are undefined
    // or when the sequences are equal
    return x === 0 ? a.title.localeCompare(z.title) : x;
  });

  const pageTitle = title || t(`curricula.${contentType}.title`);

  useTitle(pageTitle);

  const homeLink = removeTrailingSlash(url.replace(path, ''));
  const otherLinks = curricula.pathChain.map(({ href, name }) => {
    return { href: `${homeLink}/${href}`, name: name };
  });
  const breadcrumbsLinks = [
    { href: homeLink, name: t(`curricula.${contentType}.title`) },
    ...otherLinks,
  ];

  return (
    <>
      <PageHeader>
        <PageTitle label={title && t(`curricula.${contentType}.title`)}>
          {pageTitle}
        </PageTitle>
      </PageHeader>
      <Content>
        <div className="flex flex-col w-full">
          {path && <Breadcrumbs links={breadcrumbsLinks} />}
          {items.length > 0 && (
            <div className="mt-4 flex flex-wrap gap-6">
              {items.map((curriculum, index) => {
                return (
                  <div className="w-88" key={curriculum.id}>
                    <Link to={`${url}/${slugify(curriculum.title)}`}>
                      <SubjectCard
                        hasContent={
                          (contentType === contentTypes.LESSON &&
                            curriculum.hasLessons) ||
                          (contentType === contentTypes.EXAM &&
                            curriculum.hasExams) ||
                          false
                        }
                        index={index}
                        title={curriculum.title}
                        onDocument={
                          curriculum.document
                            ? () => download(curriculum.document)
                            : undefined
                        }
                      />
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
          {items.length === 0 && (
            <div className="mt-4 flex-grow">
              <ContentOverview
                contentType={contentType}
                curricula={curricula}
                defaultViewMode={defaultViewMode}
              />
            </div>
          )}
        </div>
      </Content>
    </>
  );
};

Curricula.defaultProps = {
  defaultViewMode: undefined,
};

export default Curricula;
