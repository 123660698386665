import { Content } from '@ftrprf/tailwind-components';

export const StudentResultsOverviewTopSkeleton = ({ isLoading, children }) =>
  isLoading ? (
    <Content hasPaddingBottom={false}>
      <div
        data-testid="loading"
        className="w-full flex justify-between items-center"
      >
        <div className="bg-gray-200 h-8 w-28 rounded animate-pulse" />
        <div className="flex items-center">
          <div className="bg-gray-200 h-8 w-44 rounded animate-pulse" />
          <div className="bg-gray-200 h-8 w-8 rounded-full animate-pulse ml-7" />
          <div className="bg-gray-200 h-8 w-8 rounded-full animate-pulse ml-1" />
        </div>
      </div>
    </Content>
  ) : (
    children
  );

export const StudentResultsOverviewContentSkeleton = ({
  classGroupContentStudentsLoading,
  children,
}) =>
  classGroupContentStudentsLoading ? (
    <Content>
      <div data-testid="loading" className="w-full flex-flex-col">
        <div className="h-8 bg-gray-200 animate-pulse w-full" />
        <div className="border-l border-r border-gray-200">
          {Array(3)
            .fill({})
            .map((_, i) => (
              <div className="flex p-2 border-b border-gray-200" key={i}>
                <div className="bg-gray-200 w-full animate-pulse h-10 rounded items-center" />
              </div>
            ))}
        </div>
      </div>
    </Content>
  ) : (
    children
  );
