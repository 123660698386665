import { Content, PageHeader, PageTitle } from '@ftrprf/tailwind-components';

import useCurrentUser from 'hooks/api/useCurrentUser';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import { teacherVideos } from './videos';

const transformVimeoUrl = (url) => {
  return `https://player.vimeo.com/video/${url.substring(
    url.lastIndexOf('/') + 1,
    url.length,
  )}`;
};

const HelpVideos = () => {
  const t = useFormatMessage();
  const { isCodeCosmosTeacherOrAdmin } = useCurrentUser();

  useTitle(t('help_videos.title'));

  const videos = isCodeCosmosTeacherOrAdmin ? teacherVideos : [];

  return (
    <>
      <PageHeader>
        <PageTitle>{t('help_videos.title')}</PageTitle>
      </PageHeader>
      <Content>
        <div className="w-full grid auto-rows-max grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {videos.map(({ title, src }) => (
            <div
              className="rounded overflow-hidden border border-gray-300 hover:bg-gray-100"
              key={src}
            >
              <div className="w-full h-16 flex items-center border-b border-gray-300 p-4">
                <p className="truncate font-medium">{title}</p>
              </div>

              <div className="w-full aspect-w-16 aspect-h-9 bg-gray-100">
                <iframe
                  title={title}
                  src={transformVimeoUrl(src)}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          ))}
        </div>
      </Content>
    </>
  );
};

export default HelpVideos;
