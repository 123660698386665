import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Dropdown } from '@ftrprf/tailwind-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  Input,
  Label,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import useClassGroups from 'hooks/api/useClassGroups';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';
import useFormatMessage from 'hooks/useFormatMessage';

const AddUserDialog = ({
  isOpen,
  onConfirm: addUser,
  onDismiss: dismiss,
  isLoading,
}) => {
  const t = useFormatMessage();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm();

  const { classGroups: _classGroups } = useClassGroups();
  const { name: currentClassgroupName } = useCurrentClassGroup();
  const watchName = watch(['firstName', 'lastName']);

  const defaultUsername = useMemo(() => {
    setValue('username', watchName.join('').replace(/\s+/g, ''));

    return watchName.join('').replace(/\s+/g, '');
  }, [setValue, watchName]);

  const classGroups = useMemo(
    () =>
      Object.fromEntries(
        _classGroups.map((classGroup) => [
          classGroup.name,
          {
            key: classGroup.id,
            value: classGroup.name,
            label: classGroup.name,
          },
        ]),
      ),
    [_classGroups],
  );

  const onDismiss = useCallback(() => {
    dismiss();
    reset();
  }, [dismiss, reset]);

  const onSubmit = useCallback(
    (data) => {
      // This is needed because react hook form expects an array
      //, not an object
      const { classGroups: selectedClassGroupNames, ...newUser } = data;

      const selectedClassgroupIds = selectedClassGroupNames.map(
        (name) => classGroups[name].key,
      );

      addUser({ ...newUser, classGroupIds: selectedClassgroupIds });

      reset();
      dismiss();
    },
    [addUser, classGroups, dismiss, reset],
  );

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>
          <div className="flex flex-col">
            <span>{t('add-student-dialog.header')}</span>
          </div>
        </DialogHeader>
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col">
              <Label>{t('profile.first_name')}</Label>
              <Input
                type="text"
                {...register('firstName', {
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                })}
              />
              {errors.firstName && (
                <span className="text-red-500 text-xs">
                  {errors.firstName.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <Label>{t('profile.last_name')}</Label>
              <Input
                type="text"
                {...register('lastName', {
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                })}
              />
              {errors.lastName && (
                <span className="text-red-500 text-xs">
                  {errors.lastName.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <Label>{t('profile.username')}</Label>
              <Input
                type="text"
                defaultValue={defaultUsername}
                {...register('username', {
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                })}
              />
              {errors.username && (
                <span className="text-red-500 text-xs">
                  {errors.username.message}
                </span>
              )}
            </div>

            <div className="flex flex-col">
              <Label>{t('add-student-dialog.linked_classgroups')}</Label>
              <Controller
                control={control}
                name="classGroups"
                type="select"
                rules={{
                  required: {
                    value: true,
                    message: t('global.required-field'),
                  },
                }}
                defaultValue={[classGroups[currentClassgroupName].label]}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Dropdown
                    onBlur={onBlur}
                    onChange={onChange}
                    isMulti={true}
                    options={Object.keys(classGroups)}
                    value={value}
                  />
                )}
              />
              {errors.classGroups && (
                <span className="text-red-500 text-xs">
                  {errors.classGroups.message}
                </span>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <OutlineButton disabled={isLoading} onClick={onDismiss}>
            {t('global.cancel')}
          </OutlineButton>
          <FilledButton
            type="submit"
            disabled={Object.keys(errors).length > 0 || isLoading}
            loading={isLoading}
          >
            {t('student-overview.add-student')}
          </FilledButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddUserDialog;
