import '@ftrprf/tailwind-components/slideviewer-styles.css';

import { useCallback, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import {
  SlideViewerCloseButton,
  SlideViewerContextProvider,
  StyledSlideViewer,
  VIEW_MODES,
  ViewModeButton,
} from '@ftrprf/tailwind-components';

import useContentSlides from 'hooks/api/useContentSlides';
import useCurrentContent from 'hooks/useCurrentContent';
import useDebounce from 'hooks/useDebounce';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import contentTypes from 'utils/constants/contentTypes';
import programTypes from 'utils/constants/programTypes';
import urls from 'utils/constants/urls';

import { ReactComponent as Loader } from '../../assets/vectors/logo-animated-lines.svg';

import { createHardSubmitSlide } from './partials/HardSubmitSlide';

const generateScratchExercisePath = async ({ versionId, language }) => {
  if (!versionId) {
    return '';
  }

  // eslint-disable-next-line no-return-await
  return await generatePath(urls.EXERCISE_URLS_START, {
    startId: versionId,
    language: language,
  });
};

const EmptyContent = ({ currentViewMode, slideId, onClose, canClose }) => {
  const t = useFormatMessage();

  const history = useHistory();
  const { viewUrl } = useCurrentContent();

  const otherViewModes = Object.values(VIEW_MODES).filter(
    (viewMode) => viewMode !== currentViewMode,
  );

  return (
    <div className="w-full h-full flex flex-col items-center gap-y-2 justify-center relative">
      {canClose && (
        <div className="absolute top-5 right-5">
          <SlideViewerCloseButton onClose={onClose} />
        </div>
      )}

      <span className="font-bold text-2xl">
        {`${t('content-slide-viewer.no-slides')}!`}
      </span>
      <span className="text-gray-700">
        {t('content-slide-viewer.no-slides.description')}
      </span>

      <div className="flex gap-x-4 mt-4">
        {otherViewModes.map((viewMode) => (
          <div className="w-10 h-10" key={viewMode}>
            <ViewModeButton
              onClick={() => history.push(viewUrl(viewMode, slideId))}
              viewMode={viewMode}
              title={t(`view-modes.${viewMode.toLowerCase()}`)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const ContentSlideViewer = ({
  canEdit,
  contentType,
  submittedQuestionAnswers,
  temporaryQuestionAnswers,
  showViewModes,
  showTeacherInfoTab,
  showSettingsTab,
  showSlidesOverviewTab,
  showSteamsTab,
  areAnswersSaved,
  generateFeedbackURL,
  onSubmitQuestionAnswers,
  onTemporaryQuestionAnswers,
  onWarning,
  onHardSubmit,
  EmptyContent,
}) => {
  const t = useFormatMessage();
  const history = useHistory();

  const { contentId, viewMode, slideId } = useParams();
  const { viewUrl, programUrl, classGroupId, language } = useCurrentContent();

  const { contentSlides, isLoading: slidesAreLoading } = useContentSlides({
    contentType,
    contentId,
    viewMode,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSlideChange = useCallback(
    useDebounce((newSlide) => {
      if (`${newSlide.id}` !== `${slideId}`) {
        history.push(viewUrl(viewMode, newSlide.id));
      }
    }, 70),
    [viewUrl, history, slideId, viewMode],
  );

  const onViewModeChange = useCallback(
    (newViewMode, { currentSlide }) => {
      if (newViewMode !== viewMode) {
        history.push(viewUrl(newViewMode, currentSlide.id));
      }
    },
    [viewUrl, history, viewMode],
  );

  const slides = useMemo(() => {
    if (!contentSlides?.slides) {
      return [];
    }

    return contentType === contentTypes.EXAM
      ? [...contentSlides.slides, createHardSubmitSlide(t, onHardSubmit)]
      : contentSlides.slides;
  }, [contentType, contentSlides?.slides, t, onHardSubmit]);

  const onClose = useCallback(() => {
    if (contentSlides?.programType === programTypes.HACKROOM) {
      history.push(`/classgroups/${classGroupId}/hackroom`);
    } else {
      history.push(programUrl);
    }
  }, [history, programUrl, contentSlides, classGroupId]);

  if (slidesAreLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader className="w-32" />
      </div>
    );
  }

  if (!slidesAreLoading && contentSlides.slides.length === 0) {
    return (
      <EmptyContent
        contentType={contentType}
        currentViewMode={viewMode}
        contentId={contentId}
        slideId={slideId}
        onClose={onClose}
      />
    );
  }

  if (!slideId) {
    history.replace(viewUrl(viewMode, contentSlides.slides[0].id));
  }

  const defaultSlide = slides.find((slide) => `${slide.id}` === `${slideId}`);
  const defaultSlideId = defaultSlide?.id || slides[0].id;

  return (
    <SlideViewerContextProvider
      showConfetti={true}
      canEdit={canEdit}
      canOverrideAnswers={contentType === contentTypes.EXAM}
      lesson={contentSlides}
      slides={slides}
      defaultSlideId={defaultSlideId}
      onSlideChange={onSlideChange}
      onViewModeChange={onViewModeChange}
      temporaryQuestionAnswers={temporaryQuestionAnswers}
      submittedQuestionAnswers={submittedQuestionAnswers}
      onSubmitQuestionAnswers={onSubmitQuestionAnswers}
      onTemporaryQuestionAnswers={onTemporaryQuestionAnswers}
      onWarning={onWarning}
      generateScratchExercisePath={(rest) =>
        generateScratchExercisePath({ ...rest, language })
      }
      canClose={true}
    >
      <StyledSlideViewer
        canClose={true}
        onClose={onClose}
        showViewModes={showViewModes}
        showTeacherInfoTab={showTeacherInfoTab}
        showSettingsTab={showSettingsTab}
        showSteamsTab={showSteamsTab}
        showSlidesOverviewTab={showSlidesOverviewTab}
        areAnswersSaved={areAnswersSaved}
        generateFeedbackURL={generateFeedbackURL}
      />
    </SlideViewerContextProvider>
  );
};

ContentSlideViewer.defaultProps = {
  submittedQuestionAnswers: [],
  temporaryAnswers: {},
  showViewModes: true,
  showSettingsTab: true,
  showSlidesOverviewTab: true,
  areAnswersSaved: false,
  canClose: true,
  showTeacherInfoTab: true,
  EmptyContent: EmptyContent,
  generateFeedbackURL: () => {},
  onTemporaryQuestionAnswers: () => {},
  onSubmitQuestionAnswers: () => {},
  generateScratchExercisePath: () => {},
};

export default ContentSlideViewer;
