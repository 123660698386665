import { useContext, useMemo } from 'react';

import { useLocalStorage } from '@ftrprf/hooks';

import en from './translations/en';
import nl from './translations/nl';
import { SlideViewerContext } from '../slideviewer/SlideViewer';

import EmptySlides from './partials/EmptySlides';
import SideBar from './partials/SideBar';
import SlideViewerCenter from './partials/SlideViewerCenter';
import SlideViewerFooter from './partials/SlideViewerFooter';
import SlideViewerLeft from './partials/SlideViewerLeft';
import SlideViewerRight from './partials/SlideViewerRight';

export const StyledSlideViewer = ({
  onClose,
  canClose,
  areAnswersSaved,
  showViewModes,
  showTeacherInfoTab,
  showSettingsTab,
  showSlidesOverviewTab,
  showSteamsTab,
  generateFeedbackURL,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage(
    'SLIDE_VIEWER_SIDEBAR_OPEN',
    true,
  );

  const context = useContext(SlideViewerContext);

  const { lesson, currentSlide, viewMode, slides } = context;

  const feedbackURL = useMemo(
    () => generateFeedbackURL({ slideId: currentSlide?.id, lesson }),
    [currentSlide?.id, generateFeedbackURL, lesson],
  );

  if (!slides || !slides.length) {
    return <EmptySlides onClose={onClose} />;
  }

  if (!currentSlide) {
    return null;
  }

  return (
    <div
      className="w-full relative manual-height flex flex-grow overflow-y-auto"
      id="SlideViewerFullscreen"
    >
      {isSidebarOpen && (
        <SideBar
          currentSlide={currentSlide}
          feedbackUrl={feedbackURL}
          lesson={lesson}
          showTeacherInfoTab={showTeacherInfoTab}
          showSettingsTab={showSettingsTab}
          showSteamsTab={showSteamsTab}
          showSlidesOverviewTab={showSlidesOverviewTab}
          areAnswersSaved={areAnswersSaved}
        />
      )}
      <div className="bg-white flex flex-grow flex-col items-center justify-between w-full h-full relative">
        <SlideViewerLeft
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <SlideViewerCenter currentSlide={currentSlide} />
        <SlideViewerRight
          onClose={onClose}
          showViewModes={showViewModes}
          hints={currentSlide.hints}
          canClose={canClose}
        />
        <SlideViewerFooter viewMode={viewMode} />
      </div>
    </div>
  );
};

StyledSlideViewer.defaultProps = {
  generateFeedbackURL: () => {},
};

export const StyledSlideViewerTranslations = {
  en,
  nl,
};
