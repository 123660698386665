import { useCallback, useState } from 'react';

import {
  generateSubmittedQuestionAnswersState,
  generateTemporaryQuestionAnswersState,
} from '@ftrprf/tailwind-components';

import useCurrentContent from 'hooks/useCurrentContent';

import CodeCosmosSlideViewer from './CodeCosmosSlideviewer';

const CodeCosmosContainer = () => {
  const { contentType } = useCurrentContent();

  const [canEdit, setCanEdit] = useState(true);

  const [temporaryQuestionAnswers, setTemporaryQuestionAnswers] = useState({});
  const [submittedQuestionAnswers, setSubmittedQuestionAnswers] = useState([]);

  const onTemporaryQuestionAnswers = useCallback(
    (question, value) => {
      setTemporaryQuestionAnswers(
        generateTemporaryQuestionAnswersState(question, value),
      );
    },
    [setTemporaryQuestionAnswers],
  );

  // This generates the output that normally the API will generate
  const onSubmitQuestionAnswers = useCallback((question, value) => {
    return new Promise((resolve) => {
      const submittedQuestionAnswer = generateSubmittedQuestionAnswersState(
        question,
        value,
      );

      setSubmittedQuestionAnswers((prev) => {
        const filteredSubmittedAnswers = prev.filter(
          ({ questionId }) => questionId !== question.id,
        );
        return [...filteredSubmittedAnswers, submittedQuestionAnswer];
      });

      // clear the temporary value for the current question
      setTemporaryQuestionAnswers(
        ({ [question.id]: _, ...otherQuestions }) => ({
          ...otherQuestions,
        }),
      );

      resolve({
        question,
        submittedQuestionAnswer,
      });
    });
  }, []);

  const onHardSubmit = useCallback(() => {
    return new Promise((resolve) => {
      setCanEdit(false);
      resolve();
    });
  }, []);

  return (
    <CodeCosmosSlideViewer
      canEdit={canEdit}
      isLoading={false}
      contentType={contentType}
      submittedQuestionAnswers={submittedQuestionAnswers}
      temporaryQuestionAnswers={temporaryQuestionAnswers}
      showViewModes={false}
      showTeacherInfoTab={false}
      onTemporaryQuestionAnswers={onTemporaryQuestionAnswers}
      onSubmitQuestionAnswers={onSubmitQuestionAnswers}
      onHardSubmit={onHardSubmit}
    />
  );
};

export default CodeCosmosContainer;
