const Dropdown = ({ selected, values, onChange }) => {
  return (
    <select
      data-testid="dropdown"
      style={{ textAlignLast: 'right' }}
      className="flex-grow h-8 bg-gray-100 text-right pr-2 border-none outline-none"
      value={selected}
      onChange={({ target }) => onChange(target.value)}
    >
      {values?.map((item) => (
        <option
          data-testid="dropdown-option"
          key={item.key}
          value={item.key}
          className="bg-white border-white outline-white"
        >
          {item.value}
        </option>
      ))}
    </select>
  );
};

export default Dropdown;
