import { generatePath, useHistory } from 'react-router';

import { ConfirmationDialog as RemoveUserDialog } from '@ftrprf/tailwind-components';

import ResetPasswordDialog from 'components/ResetPasswordDialog';

import useClassGroupStudents from 'hooks/api/useClassGroupStudents';
import useSuperUser from 'hooks/api/useSuperUser';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';
import useFormatMessage from 'hooks/useFormatMessage';

import urls from '../../utils/constants/urls';

import { classGroupUrls } from '../../app/ClassGroupApp';

import AddUserDialog from './partials/AddUserDialog';
import StudentOverview from './StudentOverview';

const StudentOverviewContainer = ({ canDelete, canCreate }) => {
  const t = useFormatMessage();
  const history = useHistory();
  const { id: currentClassGroupId } = useCurrentClassGroup();

  const { classGroupStudents, isLoading: isLoadingStudents } =
    useClassGroupStudents(currentClassGroupId);

  const {
    isResetting,
    isAdding,
    resetModalIsOpened,
    removeModalIsOpened,
    addModalIsOpened,
    resetPassword,
    removeUser,
    addUser,
    closeResetModal,
    openResetModal,
    openRemoveModal,
    closeRemoveModal,
    openAddModal,
    closeAddModal,
  } = useSuperUser({ roleId: 0 });

  return (
    <>
      <ResetPasswordDialog
        isResetting={isResetting}
        isOpen={!!resetModalIsOpened}
        onDismiss={closeResetModal}
        resetPassword={(confirmationPassword) => {
          resetPassword({ confirmationPassword });
        }}
      />

      {canDelete && (
        <RemoveUserDialog
          content={t('student-overview.are-you-sure')}
          isOpen={!!removeModalIsOpened}
          onConfirm={() => {
            removeUser();
            history.replace(
              generatePath(
                `${urls.CLASSGROUP}/${classGroupUrls.STUDENT_OVERVIEW}`,
                {
                  classGroupId: currentClassGroupId,
                },
              ),
            );
            closeRemoveModal();
          }}
          onDismiss={closeRemoveModal}
        />
      )}

      {canCreate && (
        <AddUserDialog
          isOpen={!!addModalIsOpened}
          isLoading={isAdding}
          onConfirm={(data) => {
            addUser(data);
          }}
          onDismiss={closeAddModal}
        />
      )}

      <StudentOverview
        classGroupStudents={classGroupStudents || []}
        isLoadingStudents={isLoadingStudents}
        canDelete={canDelete}
        canCreate={canCreate}
        openAddModal={openAddModal}
        openRemoveModal={openRemoveModal}
        openResetModal={openResetModal}
      />
    </>
  );
};

export default StudentOverviewContainer;
