import { FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { ListContextMenu } from '@ftrprf/context-menu';
import { MenuButton } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { ReactComponent as ScratchIcon } from 'assets/vectors/scratch.svg';

const RowContextMenu = ({ children, canDelete, userId, openRemoveModal }) => {
  const t = useFormatMessage();

  return (
    <ListContextMenu isWide={true} trigger={children}>
      <Link to={userId && `./students/${userId}/exercises`}>
        <MenuButton iconBefore={() => <ScratchIcon className="w-5 h-5" />}>
          {t('exercises-overview.view_exercises')}
        </MenuButton>
      </Link>

      {!!canDelete && (
        <MenuButton
          iconBefore={FaTrash}
          onClick={() => openRemoveModal(userId)}
        >
          {t('global.delete')}
        </MenuButton>
      )}
    </ListContextMenu>
  );
};

export default RowContextMenu;
