import { useMutation, useQuery, useQueryClient } from 'react-query';
import dotProp from 'dot-prop';

import { generateLocalId } from '@ftrprf/tailwind-components';

import { denormalize, normalize } from 'utils/normalizer';

const useCRUDQuery = (
  url,
  path,
  { remove: apiRemove, create: apiCreate, queryOptions },
) => {
  const { data, ...response } = useQuery(url, queryOptions);
  const queryCache = useQueryClient();

  const { mutate: remove } = useMutation(
    (keys) => apiRemove(keys[keys.length - 1]),
    {
      onMutate: (keys) => {
        queryCache.cancelQueries(url);

        const oldData = queryCache.getQueryData(url);

        queryCache.setQueryData(url, (oldQueryCache) => {
          const normalizedQueryCache = normalize(oldQueryCache, path);
          dotProp.delete(normalizedQueryCache, keys.join('.'));
          return denormalize(normalizedQueryCache, oldQueryCache, path);
        });

        return () => queryCache.setQueryData(url, oldData);
      },
      onSettled: () => {
        queryCache.invalidateQueries(url);
      },
    },
  );

  const { mutate: create } = useMutation(
    ({ newObject }) => apiCreate(newObject),
    {
      onMutate: ({ keys, newObject }) => {
        queryCache.cancelQueries(url);
        const oldData = queryCache.getQueryData(url);

        queryCache.setQueryData(url, (oldQueryCache) => {
          const normalizedQueryCache = normalize(oldQueryCache, path);
          dotProp.set(normalizedQueryCache, `${keys.join('.')}.new`, {
            ...newObject,
            isInvalidated: true,
            id: generateLocalId(url),
          });
          return denormalize(normalizedQueryCache, oldQueryCache, path);
        });

        return () => queryCache.setQueryData(url, oldData);
      },
      onError: (_, _newData, rollback) => rollback(),
      onSettled: () => {
        queryCache.invalidateQueries(url);
      },
    },
  );

  return {
    ...response,
    data,
    create,
    remove,
  };
};

export default useCRUDQuery;
