import { useContext, useState } from 'react';
import retry from 'async-retry';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { get, post } from '../../api/index';

import useFormatMessage from '../useFormatMessage';

const useScoreExport = ({
  classGroupId,
  classGroupName,
  contentId,
  contentType,
  versionId,
  lessonTitle,
}) => {
  const [downloadStarted, setDownloadStarted] = useState(false);

  const { addNotification } = useContext(NotificationContext);

  const t = useFormatMessage();

  const generateExcelFile = async () => {
    const url = `${process.env.REACT_APP_API_URL}/classgroups/${classGroupId}/content/${contentType}/${contentId}/version/${versionId}/excel`;
    post(url)
      .then(async () => {
        setDownloadStarted(true);
        const fileUrl = `${process.env.REACT_APP_API_URL}/classgroups/${classGroupId}/content/${contentType}/${contentId}/excel/download`;
        await retry(
          async (_bail) => {
            await get(fileUrl).then((data) => {
              const file = data.base64EncodedBlob;
              const filename = `${classGroupName}_${lessonTitle.replace(
                / /g,
                '-',
              )}_export.xlsx`;
              fetch(file)
                .then((res) => res.blob())
                .then((blob) => {
                  if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                  } else {
                    let elem = window.document.createElement('a');
                    const urlObject = window.URL.createObjectURL(blob);
                    elem.role = 'excel-download';
                    elem.href = urlObject;
                    elem.download = filename;
                    document.body.appendChild(elem);
                    elem.click();
                    document.body.removeChild(elem);
                    URL.revokeObjectURL(urlObject);
                  }
                });
              setDownloadStarted(false);
              addNotification({
                type: 'success',
                content: t('results-overview.export_successful'),
              });
            });
          },
          { retries: 3, randomize: false, minTimeout: 5000, factor: 1 },
        ).catch(() => {
          setDownloadStarted(false);
          addNotification({
            type: 'error',
            content: t('results-overview.export_failed'),
          });
        });
      })
      .catch(() => {
        setDownloadStarted(false);
        addNotification({
          type: 'error',
          content: t('results-overview.export_failed'),
        });
      });
  };

  const sendToSmartschool = () => {
    const url = `${process.env.REACT_APP_API_URL}/classgroups/${classGroupId}/content/${contentType}/${contentId}/version/${versionId}/smartschool`;
    post(url)
      .then(() => {
        setDownloadStarted(false);
        addNotification({
          type: 'success',
          content: t('results-overview.export_successful'),
        });
      })
      .catch(() => {
        setDownloadStarted(false);
        addNotification({
          type: 'error',
          content: t('results-overview.export_failed'),
        });
      });
  };

  return { generateExcelFile, sendToSmartschool, downloadStarted };
};

export default useScoreExport;
