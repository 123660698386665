import { useContext, useReducer } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import copy from 'copy-to-clipboard';

import { NotificationContext } from '@ftrprf/tailwind-components';

import useCurrentClassGroup from 'hooks/useCurrentClassGroup';
import useFormatMessage from 'hooks/useFormatMessage';

import { post, put, remove } from 'api/index';

import throwIfDevelopment from 'utils/throwIfDevelopment';

import { URL } from './useClassGroupStudents';
import useCurrentUser from './useCurrentUser';
import { URL as RoleURL } from './useSchoolStudents';
import { URL as UserURL } from './useUser';

const reducer = (_, action) => {
  switch (action.type) {
    case 'OPEN_ADD_MODAL':
      return {
        addModalIsOpened: true,
      };
    case 'CLOSE_ADD_MODAL':
      return {
        addModalIsOpened: false,
      };
    case 'OPEN_DELETE_CONFIRMATION_MODAL':
      return {
        removeModalIsOpened: true,
        ...(action.userId && { selectedUserId: action.userId }),
      };
    case 'CLOSE_DELETE_CONFIRMATION_MODAL':
      return {
        removeModalIsOpened: false,
      };
    case 'OPEN_RESET_PASSWORD_MODAL':
      return {
        resetModalIsOpened: true,
        ...(action.userId && { selectedUserId: action.userId }),
      };
    case 'CLOSE_RESET_PASSWORD_MODAL':
      return {
        resetModalIsOpened: false,
      };

    default:
      throwIfDevelopment('No correct action passed');
      return null;
  }
};

// this hook is available for teachers that want to change config
// of a student
const useSuperUser = ({ roleId }) => {
  const t = useFormatMessage();
  const queryCache = useQueryClient();

  const { user } = useCurrentUser();
  const { id: classGroupId } = useCurrentClassGroup();

  const [modalState, dispatch] = useReducer(reducer);

  const { addNotification } = useContext(NotificationContext);

  const { mutate: resetPassword, isLoading: isResetting } = useMutation(
    ({ userId = modalState.selectedUserId, confirmationPassword }) =>
      post(`${process.env.REACT_APP_API_URL}/users/${userId}/resetPassword`, {
        authPassword: confirmationPassword,
      }),
    {
      onSuccess: ({ newPassword }) => {
        copy(newPassword);
        addNotification({
          type: 'success',
          content: t('student-overview.column.reset-password.confirmation'),
        });
        dispatch({ type: 'CLOSE_RESET_PASSWORD_MODAL' });
      },
      onError: () => {
        addNotification({
          type: 'error',
          content: t('student-overview.column.reset-password.error'),
        });
      },
    },
  );

  const {
    mutate: removeUser,
    isLoading: isRemoving,
    isError: isErrorRemoving,
  } = useMutation(
    (userId = modalState.selectedUserId) => {
      remove(`${process.env.REACT_APP_API_URL}/users/${userId}`);
    },
    {
      onSuccess: () => {
        if (classGroupId) {
          queryCache.refetchQueries(URL(classGroupId));
        } else {
          queryCache.refetchQueries(RoleURL(roleId));
        }

        addNotification({
          type: 'success',
          content: t('student-overview.column.delete-student.success'),
        });
      },
      onError: (_, _newData, rollback) => {
        rollback();
        addNotification({
          type: 'error',
          content: t('student-overview.column.delete-student.error'),
        });
      },
    },
  );

  const {
    mutate: editUser,
    isLoading: isSaving,
    isError: isErrrorSaving,
  } = useMutation(
    ({ userId, user }) =>
      put(`${process.env.REACT_APP_API_URL}/users/${userId}`, user),
    {
      onSuccess: ({ id }) => {
        queryCache.refetchQueries(UserURL({ userId: id }));
        addNotification({
          type: 'success',
          content: t('student-overview.column.edit-student.success'),
        });
      },
      onError: (_, _newData, rollback) => {
        rollback();

        addNotification({
          type: 'error',
          content: t('student-overview.column.edit-student.error'),
        });
      },
    },
  );

  const {
    mutate: addUser,
    isLoading: isAdding,
    isError: errorAdding,
  } = useMutation(
    (newUser) =>
      post(`${process.env.REACT_APP_API_URL}/users`, {
        eulaAccepted: false,
        classGroupProgramIds: [],
        role: 'SCHOOLSTUDENT',
        schoolId: user.school.id,
        ...newUser,
      }),
    {
      onSuccess: (newUser) => {
        if (classGroupId) {
          queryCache.refetchQueries(URL(classGroupId));
        } else {
          queryCache.refetchQueries(RoleURL(roleId));
        }

        copy(newUser.password);
        addNotification({
          type: 'success',
          content: t('student-overview.column.create-student.success'),
        });
      },
      onError: () => {
        addNotification({
          type: 'error',
          content: t('student-overview.column.create-student.error'),
        });
      },
    },
  );

  return {
    resetPassword,
    removeUser,
    addUser,
    editUser,
    isAdding,
    errorAdding,
    isRemoving,
    isErrorRemoving,
    isSaving,
    isErrrorSaving,
    isResetting,
    openAddModal: () => dispatch({ type: 'OPEN_ADD_MODAL' }),
    closeAddModal: () => dispatch({ type: 'CLOSE_ADD_MODAL' }),
    openRemoveModal: (userId) =>
      dispatch({ type: 'OPEN_DELETE_CONFIRMATION_MODAL', userId }),
    closeRemoveModal: () =>
      dispatch({ type: 'CLOSE_DELETE_CONFIRMATION_MODAL' }),
    openResetModal: (userId) =>
      dispatch({ type: 'OPEN_RESET_PASSWORD_MODAL', userId }),
    closeResetModal: () => dispatch({ type: 'CLOSE_RESET_PASSWORD_MODAL' }),

    ...modalState,
  };
};

export default useSuperUser;
