import { useQuery } from 'react-query';

export const URL = (roleId) =>
  `${process.env.REACT_APP_API_URL}/users?roleId=${roleId}`;

const useSchoolStudents = ({ roleId }) => {
  const {
    data: schoolStudents,
    isLoading,
    error,
  } = useQuery({
    queryKey: URL(roleId),
  });

  return { schoolStudents, isLoading, error };
};

export default useSchoolStudents;
