import useCurrentUser from './useCurrentUser';

export const URL = `${process.env.REACT_APP_API_URL}/users/me`;

const useClassGroups = () => {
  const { user, isLoading } = useCurrentUser();

  const classGroups =
    // Generate the list of available classgroups from classGroupPrograms.
    user?.classGroupPrograms?.reduce((uniqueClassGroups, { classGroup }) => {
      if (!uniqueClassGroups[classGroup.id]) {
        return { ...uniqueClassGroups, [classGroup.id]: classGroup };
      }

      return uniqueClassGroups;
    }, {}) || {};

  return { classGroups: Object.values(classGroups), isLoading };
};

export default useClassGroups;
