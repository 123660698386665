export const PageTitleSkeleton = () => (
  <div data-testid="loading">
    <div className="w-36 h-6 mb-2 bg-gray-300 animate-pulse rounded" />
    <div className="bg-gray-300 h-10 w-88 rounded animate-pulse -mb-1" />
  </div>
);

const PageTitle = ({ label, children }) => {
  return (
    <div>
      {label && (
        <div className="mb-1 text-2xl text-gray-600 font-normal leading-none">
          {label}
        </div>
      )}
      <h1 className="max-w-lg font-semibold text-3xl leading-tight">
        {children}
      </h1>
    </div>
  );
};

export default PageTitle;
