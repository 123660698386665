import { QueryClient } from 'react-query';

import { get } from 'api/index';

const defaultQueryFn = ({ queryKey }) => {
  return get(queryKey[0]);
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      retry: 3,
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient;
