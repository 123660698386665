/* eslint eqeqeq: 0 */
import { lessonAnswers } from 'mocks/mockData/lesson';

/**
 * Function that is called recursively to normalize a nested object.
 *
 * @param data {Object|Object[]} The data to be normalized
 * @param indices {String[]} The key-value pairs of the nested path
 * @param index {Number} index of the current key-value pair to be normalized
 */
const normalizeHelper = (data, indices, index) => {
  // Stop  condition of the recursive function
  if (index < indices.length) {
    // First split the key-value pairs
    const [key, identifier] = indices[index].split(':');

    // data[key] might be null or undefined. return an empty object
    // since it needs to be present in the normalized object
    if (!data[key]) {
      return {};
    }

    if (Array.isArray(data[key])) {
      // Same logic as before, only now include a pair, and not only the key
      return Object.fromEntries(
        data[key].map((el) => {
          return [el[identifier], normalizeHelper(el, indices, index + 1)];
        }),
      );
    } else {
      /**
       * Map the new identifier with the whole object, but add
       * any nested key (if there is any) with its normalized version. Original
       * data is thus kept right now, but there does not seem a reason why it should
       * be deleted right now.
       *
       * eg. {foo: {bar: {id: 2}, id: 1}} with path 'foo:id,bar:id'
       * steps:
       * {1: {bar: {id : 2, name: 'Cedric'}}}, but bar needs to be normalized
       * {1: {bar: {id : 2, name: 'Cedric'}}, 2: {id: 2, name: 'Cedric'}}
       *
       */
      return {
        [data[key][identifier]]: {
          ...data[key],
          ...normalizeHelper(data[key], indices, index + 1),
        },
      };
    }
  }

  return data;
};

/**
 * Normalizes an object, makes use of the normalizeHelper function.
 *
 * @param data {Object|Object[]} The data to be normalized
 * @param path {string} A path that maps nested key:value pairs with a dot eg. foo:id.bar:id
 */
export const normalize = (data, path) => {
  // To find all the indices, first split the path by a dot to get an array
  const indices = path.split('.');

  if (Array.isArray(data)) {
    /**
     * If the data is an array of objects, there is no key present. Only a value.
     * eg: [{id: "1", foo: "bar"}, {id: "2", foo: "bar"}] will need to be mapped to
     * {1: {foo: "bar"}, 2: {foo: "bar"}, 3: {foo: "bar"}}
     *
     * To do this, the fromEntries function will be used to create an object based on
     * the first key in indices and to to be normalized object as the value for each object
     * in the original array
     */
    return Object.fromEntries(
      data.map((el) => [el[indices[0]], normalizeHelper(el, indices, 1)]),
    );
  } else {
    /**
     * When the originaldata is an object, the first step does not differ
     * from the other recursion steps
     */

    return normalizeHelper(data, indices, 0);
  }
};

/**
 * Is very similar to the denormalize function, but with an index to
 * be called recursively
 *
 * @param normalizedData {Object|Object[]} The data that was normalized
 * @param originalData {Object|Object[]} The origin data of the normalizedData
 * @param indices {String[]} An array of keys
 * @param index {Number} indicates which part of the nested object needs to be denormalized
 */
const denormalizeHelper = (normalizedData, originalData, indices, index) => {
  if (Array.isArray(originalData)) {
    if (index + 1 < indices.length) {
      return Object.entries(normalizedData).map(([key, value]) => ({
        ...lessonAnswers.find((el) => el[indices[index]] == key),
        [indices[index]]: key,
        [indices[index + 1]]: denormalizeHelper(
          value,
          lessonAnswers.find((el) => el == key),
          indices,
          index + 1,
        ),
      }));
    }
  } else {
    if (index < indices.length) {
      return originalData
        ? {
            ...originalData,
            [indices[index]]: denormalizeHelper(
              normalizedData[indices[index]],
              originalData[indices[index]],
              indices,
              index + 1,
            ),
          }
        : Object.values(normalizedData)[0];
    }
  }

  if (Array.isArray(originalData)) {
    return Object.values(normalizedData);
  }
  return normalizedData;
};

/**
 * Denormalizes an object, makes use of the denormalizeHelper function.
 *
 * @param normalizedData {Object|Object[]} The data that was normalized
 * @param originalData {Object|Object[]} The origin data of the normalizedData
 * @param path {string} A path that maps nested key:value pairs with a dot eg. foo:id.bar:id
 */
export const denormalize = (normalizedData, originalData, path) => {
  // We don't need key-value pairs right now, only the keys
  const indices = path.split('.').map((el) => el.split(':')[0]);

  if (Array.isArray(originalData)) {
    /**
     * Normalized data is always an object. The entries of the normalized object are
     * mapped to an array containing both the original data  overridden with the possibly
     * new data of the normalized object. This object also needs to be denormalized, and this
     * happens recursively.
     */
    return Object.entries(normalizedData).map(([key, value]) => ({
      ...originalData.find((el) => el[indices[0]] == key),
      [indices[0]]: key,
      ...(indices.length > 1 && {
        [indices[1]]:
          Object.keys(value).length > 0
            ? denormalizeHelper(
                value,
                originalData.find((el) => el[indices[0]] == key)[indices[1]],
                indices,
                1,
              )
            : [],
      }),
    }));
  } else {
    /**
     * The new key created by the normalize function needs to be undone. This can
     * be done by getting the originalData and overriding it with the possibly
     * changed data of the values of the normalized object.
     *
     * eg. original: {foo: {id: '1', name: 'before'}}
     *     normalized: {1: {foo: 'bar', name: 'changed}}
     *
     *     == {foo: {id: '1', name: 'before', name: 'changed'}}
     *     Since 'changed' was added after 'before', 'before' is replaced.
     */
    return {
      ...originalData,
      [indices[0]]: denormalizeHelper(
        Object.values(normalizedData)[0],
        originalData[indices[0]],
        indices,
        1,
      ),
    };
  }
};
