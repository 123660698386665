import Header from 'components/Header/Header';

const GeneralLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
    </>
  );
};

export default GeneralLayout;
