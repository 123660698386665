import viewModes from 'utils/constants/viewModes';

import CurriculaContainer from './CurriculaContainer';

const ExamContainer = (props) => {
  return (
    <CurriculaContainer defaultViewMode={viewModes.CLASSICAL} {...props} />
  );
};

export default ExamContainer;
