import { useCallback, useMemo } from 'react';
import { FaPlus } from 'react-icons/fa';
import { generatePath, useHistory } from 'react-router-dom';
import { classGroupUrls } from 'app/ClassGroupApp';

import {
  Content,
  FilledButton,
  PageHeader,
  Table,
} from '@ftrprf/tailwind-components';

import PageTitle from 'components/PageTitle';

import useFormatDate from 'hooks/useFormatDate';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import urls from 'utils/constants/urls';

import StudentOverviewTableSkeleton from './partials/StudentOverviewTableSkeleton';
import createColumns from './columns';

const StudentOverview = ({
  classGroupStudents,
  isLoadingStudents,
  canDelete,
  canCreate,
  openAddModal,
  openRemoveModal,
  openResetModal,
}) => {
  const t = useFormatMessage();
  const history = useHistory();
  const formatDate = useFormatDate();
  const { classGroupId } = useParams();

  const routeToDetail = useCallback(
    ({ id: studentId }) =>
      history.push(
        generatePath(`${urls.CLASSGROUP}/${classGroupUrls.STUDENT_PROFILE}`, {
          classGroupId,
          studentId,
        }),
      ),
    [classGroupId, history],
  );

  const columns = useMemo(
    () =>
      createColumns({
        t,
        formatDate,
        canDelete,
        routeToDetail,
        openRemoveModal,
        openResetModal,
      }),
    [t, formatDate, canDelete, routeToDetail, openRemoveModal, openResetModal],
  );

  return (
    <>
      <PageHeader>
        <div className="w-full flex justify-between">
          <PageTitle>{t('global.STUDENT.plural')}</PageTitle>
          {canCreate && (
            <FilledButton iconBefore={FaPlus} onClick={openAddModal}>
              {t('student-overview.new-student')}
            </FilledButton>
          )}
        </div>
      </PageHeader>
      <Content>
        <StudentOverviewTableSkeleton loading={isLoadingStudents}>
          {classGroupStudents?.length > 0 && (
            <Table
              columns={columns}
              data={classGroupStudents}
              rowClassName="cursor-pointer h-8"
              onRowClick={routeToDetail}
            />
          )}
          {classGroupStudents?.length === 0 && (
            <div>{t('student-overview.no-students')}</div>
          )}
        </StudentOverviewTableSkeleton>
      </Content>
    </>
  );
};

StudentOverview.defaultProps = {
  canDelete: false,
  canCreate: false,
  openAddModal: () => {},
  openRemoveModal: () => {},
};

export default StudentOverview;
