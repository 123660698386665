import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import {
  generateTemporaryQuestionAnswersState,
  VIEW_MODES,
} from '@ftrprf/tailwind-components';

import useContentSubmitMutation from 'hooks/api/mutations/useContentSubmitMutation';
import useContent from 'hooks/api/useContent';
import useContentAnswers, { STUDENTURL } from 'hooks/api/useContentAnswers';
import useContentSlides from 'hooks/api/useContentSlides';
import useSteams from 'hooks/api/useSteams';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';
import useFormatMessage from 'hooks/useFormatMessage';
import useLocalStorage from 'hooks/useLocalStorage';
import useParams from 'hooks/useParams';

import QuestionWarning from './partials/QuestionWarning';
import ContentSlideViewer from './ContentSlideViewer';

export const EmptyContent = () => {
  const t = useFormatMessage();
  return (
    <div className="w-full h-full flex flex-col items-center gap-y-2 justify-center">
      <span className="font-bold text-2xl">{`${t(
        'content-slide-viewer.no-slides',
      )}!`}</span>
      <span className="text-gray-700">
        {t('content-slide-viewer.no-slides.description-student')}
      </span>
    </div>
  );
};

const StudentContentSlideViewer = ({ contentType, user }) => {
  const queryCache = useQueryClient();
  const { id: classGroupId } = useCurrentClassGroup();
  const { contentId, viewMode: initialViewMode, slideId } = useParams();
  const { initVm, destroyVm, sendPageNumber, groupId, isSteams } =
    useSteams(user);

  const viewMode = initialViewMode || VIEW_MODES.CLASSICAL;

  const [warningData, setWarningData] = useState({
    nextSlide: null,
    isOpen: false,
  });

  const { mutateAsync } = useContentSubmitMutation({
    classGroupId,
    contentId,
    contentType,
  });

  const { contentSlides, endpoint: contentSlidesEndpoint } = useContentSlides({
    contentId,
    contentType,
    viewMode,
  });

  const { isLoading, contentDetails, refetch } = useContent({
    contentId,
    contentType,
    viewMode,
  });

  const currentSlide = contentSlides?.slides.find(
    (slide) => `${slide.id}` === `${slideId}`,
  );

  // STEAMS stuff
  // This is only a POC, more details ask Fien or Jannick
  useEffect(() => {
    initVm();

    return () => {
      destroyVm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentSlide?.sequence) sendPageNumber(currentSlide.sequence);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  const SteamsTab = useCallback(() => {
    return (
      <div className="overflow-hidden flex flex-col flex-grow">
        <div className="mt-4 px-4 mb-1 text-2xl font-bold">Steams</div>
        <div className="overflow-y-auto flex-grow relative">
          <iframe
            src={`https://steams.cs.kuleuven.be/dashboard/widgets/${groupId}/${user.id}`}
            allow="camera;microphone"
            frameBorder="0"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </div>
    );
  }, [groupId, user.id]);
  // END Steams Stuff

  const {
    data: submittedQuestionAnswers,
    submitAnswers,
    endpoint: contentAnswersEndpoint,
  } = useContentAnswers({
    contentId,
    contentType,
    endpoint: STUDENTURL,
    viewMode,
    slideId: Number(slideId),
  });

  const [temporaryQuestionAnswers, setTemporaryQuestionAnswers] =
    useLocalStorage(`TEMPORARY_QUESTIONS`, {});

  const onTemporaryQuestionAnswers = useCallback(
    (question, value) =>
      setTemporaryQuestionAnswers(
        generateTemporaryQuestionAnswersState(question, value),
      ),
    [setTemporaryQuestionAnswers],
  );

  const onSubmitQuestionAnswers = (question) => {
    return submitAnswers({
      question: question,
      temporaryQuestionAnswers: temporaryQuestionAnswers,
      setTemporaryQuestionAnswers,
    }).then(() => {
      const currentSlide = queryCache
        .getQueryData(contentSlidesEndpoint)
        .slides.find((slide) => `${slide.id}` === `${slideId}`);

      const updatedQuestion = currentSlide.questions.find(
        ({ id }) => question.id === id,
      );

      const submittedQuestionAnswer = queryCache
        .getQueryData(contentAnswersEndpoint)
        .find((q) => Number(q.questionId) === Number(question.id));

      return {
        question: updatedQuestion,
        submittedQuestionAnswer,
      };
    });
  };

  const onHardSubmit = useCallback(() => {
    return mutateAsync().then(() => refetch());
  }, [mutateAsync, refetch]);

  return (
    <>
      <QuestionWarning
        warningData={warningData}
        setWarningData={setWarningData}
        viewMode={viewMode}
        submitAnswers={
          () => onSubmitQuestionAnswers(currentSlide.questions[0]) // only one question allowed per slide
        }
      />
      <ContentSlideViewer
        canEdit={!contentDetails?.submittedAt}
        isLoading={isLoading}
        contentType={contentType}
        submittedQuestionAnswers={submittedQuestionAnswers}
        temporaryQuestionAnswers={temporaryQuestionAnswers}
        showViewModes={false}
        showTeacherInfoTab={false}
        showSteamsTab={isSteams ? SteamsTab : undefined}
        EmptyContent={EmptyContent}
        onWarning={(nextSlide) =>
          setWarningData(() => ({ isOpen: true, nextSlide }))
        }
        onTemporaryQuestionAnswers={onTemporaryQuestionAnswers}
        onSubmitQuestionAnswers={onSubmitQuestionAnswers}
        onHardSubmit={onHardSubmit}
      />
    </>
  );
};

export default StudentContentSlideViewer;
