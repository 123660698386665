import { get } from 'api/index';

const urls = {
  FIND_STARTED: (startId, language) =>
    `${process.env.REACT_APP_API_URL}/exercises/start/${startId}?new=false&language=${language}`,
  CREATE: (startId, language) =>
    `${process.env.REACT_APP_API_URL}/exercises/start/${startId}?new=true&language=${language}`,
};

const findStartedExercise = (startId, language) =>
  get(urls.FIND_STARTED(startId, language));

const createFromStartId = (startId, language) =>
  get(urls.CREATE(startId, language));

export default async (startId, language) => {
  try {
    return await findStartedExercise(startId, language);
  } catch {
    return createFromStartId(startId, language);
  }
};
