const HackRoomSkeleton = ({ loading, children }) => {
  return loading ? (
    <>
      <div className="hidden md:block">
        <div className="h-8 w-32 my-4 bg-gray-200 rounded animate-pulse" />
        <div className="h-32 w-full bg-gray-200 rounded animate-pulse" />
      </div>
      <div className="h-8 w-32 mb-2 mt-4 bg-gray-200 rounded animate-pulse" />
      <div className="h-8 w-full mt-4 bg-gray-200 rounded animate-pulse" />
      <div className="w-full grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
        {Array.from(Array(6), (_, i) => (
          <div
            className="h-32 w-full bg-gray-200 rounded animate-pulse"
            key={i}
          />
        ))}
      </div>
    </>
  ) : (
    children
  );
};
export default HackRoomSkeleton;
