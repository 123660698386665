const ContentCardList = ({ children }) => {
  return (
    <div className="w-full grid gap-6 mt-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
      {children}
    </div>
  );
};

const ContentCardSkeleton = () => {
  return (
    <div className="relative w-full flex justify-start items-end rounded-md border border-gray-300 overflow-hidden">
      <div className="h-32 w-32 flex-shrink-0 bg-gray-200 animate-pulse" />
      <div className="ml-4 h-full w-full flex flex-col">
        <div className="h-6 w-5/6 mt-4 bg-gray-200 animate-pulse" />
        <div className="h-4 w-1/2 mt-4 bg-gray-200 animate-pulse" />
      </div>
    </div>
  );
};

export const ContentCardListSkeleton = ({ isLoading, children }) => {
  const items = Array.from({ length: 12 });

  return (
    <div className="w-full">
      <ContentCardList>
        {isLoading
          ? items.map((_, i) => (
              <ContentCardSkeleton key={`cardskeleton${i}`} />
            ))
          : children}
      </ContentCardList>
    </div>
  );
};

export default ContentCardList;
