import { PageHeader } from '@ftrprf/tailwind-components';

const ContentSkeleton = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <PageHeader marginBottom={false}>
        <div className="flex justify-between">
          <div className="bg-gray-300 h-8 w-88 animate-pulse rounded" />
          <div className="bg-gray-300 h-8 w-36 animate-pulse rounded" />
        </div>
        <div className="mb-2">
          <div className="flex mt-4">
            <div className="bg-gray-300 h-7 w-28 animate-pulse rounded" />
            <div className="bg-gray-300 h-7 w-28 animate-pulse rounded ml-2" />
          </div>
        </div>
      </PageHeader>
    );
  }

  return children;
};

export default ContentSkeleton;
