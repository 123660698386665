import { ReactComponent as Logo } from '../assets/vectors/logo_new.svg';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="pt-10 text-gray-700 bg-gray-100">
      <div className="flex items-center flex-col">
        <Logo className="h-32" />
        <p className="py-5 text-gray-600  dark:text-white">FFTRPRF ©{year}</p>
      </div>
    </footer>
  );
};

export default Footer;
