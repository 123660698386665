import { loginRequest } from 'authConfig';

import { AUTHORIZATION_TOKEN } from '../utils/constants/localStorage';

import msalInstance from '../msalInstance';
const statusCodes = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NO_CONTENT: 204,
};

export const getToken = () => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    return new Promise((resolve) => resolve(null));
  }
  return new Promise((resolve, reject) =>
    msalInstance
      .acquireTokenSilent({ account, ...loginRequest })
      .then(({ idToken }) => {
        resolve(idToken);
      })
      .catch(() =>
        msalInstance
          .acquireTokenRedirect({ account, ...loginRequest })
          .then(({ idToken }) => {
            resolve(idToken);
          })
          .catch(reject),
      ),
  );
};

const authorizedRequest = (endpoint, options = {}) => {
  const token = window.localStorage[AUTHORIZATION_TOKEN];
  const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : {};

  const hasContentTypeHeader = options.headers?.['Content-Type'] !== null;
  const hasAcceptHeader = options.headers?.accept !== null;

  const {
    'Content-Type': contentType,
    accept,
    ...otherHeaders
  } = options.headers || {};
  const contentTypeHeader = hasContentTypeHeader
    ? {
        'Content-Type': contentType || 'application/json;charset=UTF-8',
      }
    : {};
  const acceptHeader = hasAcceptHeader
    ? {
        accept: accept || 'application/json, text/plain, */*',
      }
    : {};

  if (token === undefined) {
    return getToken().then((token) => {
      const authorizationHeader = token
        ? { Authorization: `Bearer ${token}` }
        : {};
      return fetch(endpoint, {
        ...options,
        headers: {
          ...otherHeaders,
          ...contentTypeHeader,
          ...acceptHeader,
          ...authorizationHeader,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error(res);
        }
        const contentType = res.headers.get('content-type');
        if (
          res.status === statusCodes.NO_CONTENT ||
          res.headers.get('content-length') === '0'
        ) {
          return null;
        }
        if (contentType.startsWith('text/plain')) {
          return res;
        }
        return res.json();
      });
    });
  } else {
    return fetch(endpoint, {
      ...options,
      headers: {
        ...otherHeaders,
        ...contentTypeHeader,
        ...acceptHeader,
        ...authorizationHeader,
      },
    }).then((res) => {
      if (res.status === statusCodes.UNAUTHORIZED) {
        window.location.assign(`${process.env.REACT_APP_EDU_URL}`);
      }
      if (
        res.status === statusCodes.FORBIDDEN &&
        endpoint.endsWith('api/users/me')
      ) {
        window.location.assign(`${process.env.REACT_APP_EDU_URL}`);
      }
      if (!res.ok) {
        throw new Error(res);
      }
      const responseToken = res.headers.get('authorization');
      const contentType = res.headers.get('content-type');
      if (responseToken) {
        localStorage.setItem(AUTHORIZATION_TOKEN, responseToken);
      }
      if (
        res.status === statusCodes.NO_CONTENT ||
        res.headers.get('content-length') === '0'
      ) {
        return null;
      }
      if (contentType.startsWith('text/plain')) {
        return res;
      }
      return res.json();
    });
  }
};

export const get = (endpoint, options) => {
  return authorizedRequest(endpoint, {
    method: 'GET',
    ...options,
  });
};
export const post = (endpoint, body) => {
  return authorizedRequest(endpoint, {
    method: 'POST',
    headers: body instanceof FormData ? { 'Content-Type': null } : {},
    body: body instanceof FormData ? body : JSON.stringify(body),
  });
};
export const put = (endpoint, body) => {
  return authorizedRequest(endpoint, {
    method: 'PUT',
    headers: body instanceof FormData ? { 'Content-Type': null } : {},
    body: body instanceof FormData ? body : JSON.stringify(body),
  });
};
export const remove = (endpoint) => {
  return authorizedRequest(endpoint, {
    method: 'DELETE',
  });
};
