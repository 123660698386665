import { ConfirmationDialog } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../hooks/useFormatMessage';

const PublishResultsWarningDialog = ({
  amountOfInCompleteStudents,
  amountOfnotFullyGradedStudents,
  ...props
}) => {
  const t = useFormatMessage();

  return (
    <ConfirmationDialog
      title={t('results-overview.publish_results_warnings')}
      content={
        <div>
          {amountOfInCompleteStudents > 0 && (
            <p>
              <b>{amountOfInCompleteStudents}</b>{' '}
              {amountOfInCompleteStudents === 1
                ? t('results-overview.student_has')
                : t('results-overview.students_have')}{' '}
              {t('results-overview.not_all_questions_answered_warning')}
            </p>
          )}
          {amountOfnotFullyGradedStudents > 0 && (
            <p>
              <b>{amountOfnotFullyGradedStudents}</b>{' '}
              {amountOfnotFullyGradedStudents === 1
                ? t('results-overview.student_has')
                : t('results-overview.students_have')}{' '}
              {t('results-overview.not_all_questions_have_scores_warning')}
            </p>
          )}
        </div>
      }
      {...props}
    />
  );
};

export default PublishResultsWarningDialog;
