import { NavLink } from 'react-router-dom';
import { curriculaUrls } from 'app/ClassGroupApp';

import useClassGroups from 'hooks/api/useClassGroups';
import useCurrentUser from 'hooks/api/useCurrentUser';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';

import { ReactComponent as Logo } from '../../assets/vectors/logo.svg';

import ClassGroupDropDown from './ClassGroupDropDown';
import StudentHeader from './StudentHeader';
import TeacherHeader from './TeacherHeader';

const Header = () => {
  const { isCodeCosmosTeacherOrAdmin, isCodeCosmosStudent, isSchoolAdmin } =
    useCurrentUser();
  const { classGroups } = useClassGroups();
  const classGroup = useCurrentClassGroup();
  const classGroupId = classGroup?.id;

  const RoleHeader =
    isCodeCosmosTeacherOrAdmin || isSchoolAdmin ? TeacherHeader : StudentHeader;

  const hasClassGroups = classGroups?.length > 0;

  return (
    <RoleHeader
      logo={
        <NavLink to={`/classgroups/${classGroupId}/${curriculaUrls.LESSON}`}>
          <Logo className="h-full cursor-pointer" />
        </NavLink>
      }
      beforeNavigationChildren={() =>
        hasClassGroups && classGroupId ? (
          <ClassGroupDropDown
            classGroups={classGroups}
            selectedClassGroupId={classGroupId}
            hasSettings={!isCodeCosmosStudent}
          />
        ) : null
      }
    />
  );
};

export default Header;
