/* eslint-disable react/display-name */
import { classGroupUrls } from 'app/ClassGroupApp';

import {
  Badge,
  Button,
  ProgressBar,
  VersionLabel,
} from '@ftrprf/tailwind-components';

import Avatar from 'components/Avatar';
import Indicator from 'components/Indicator';
import Link from 'components/Link';

import useFormatDate from 'hooks/useFormatDate';

import progressStates from './utils/progressStates';
import c from 'utils/c';

const columns = ({
  classGroupId,
  contentId,
  t,
  version,
  viewMode,
  contentType,
  versions,
  setSelectedVersion,
}) => [
  {
    Header: t('results-overview.column.name'),
    Cell: ({ row }) => {
      const { firstName, lastName, username } = row.original;
      return (
        <div className="flex gap-x-4 items-center" title={username}>
          <Avatar
            className="w-10 h-10 flex-shrink-0 text-white"
            firstName={firstName}
            lastName={lastName}
          />
          {`${firstName} ${lastName}`}
        </div>
      );
    },
  },
  {
    Header: t('results-overview.column.submitted_at'),
    Cell: ({ row }) => {
      const {
        latestAnswerSubmittedAt,
        totalNumberOfAnsweredQuestions,
        allQuestionsHaveScores,
        submittedVersionId,
      } = row.original;

      const formatDate = useFormatDate();

      const { isComplete, totalNumberOfUnansweredQuestions } = progressStates({
        latestAnswerSubmittedAt,
        allQuestionsHaveScores,
        numberOfQuestions: version?.numberOfQuestions,
        totalNumberOfAnsweredQuestions,
      });

      const madeInVersion =
        submittedVersionId &&
        version?.id !== submittedVersionId &&
        versions?.find(({ id }) => submittedVersionId === id);

      return (
        <div className={c('flex items-start')}>
          {latestAnswerSubmittedAt && (
            <div>
              {!isComplete && (
                <p className="text-xs text-yellow-600">
                  {totalNumberOfUnansweredQuestions}{' '}
                  {totalNumberOfUnansweredQuestions === 1
                    ? t('results-overview.unanswered_question')
                    : t('results-overview.unanswered_questions')}
                </p>
              )}
              <Badge className="text-sm px-2" warning={!isComplete}>
                {formatDate(latestAnswerSubmittedAt)}
              </Badge>
            </div>
          )}
          {madeInVersion && (
            <div className="flex items-center mt-1">
              <Button
                className="mt-2 ml-5 p-1"
                title={`${t('results-overview.made_in_version')}: ${
                  madeInVersion.version
                }`}
                onClick={() => setSelectedVersion(madeInVersion)}
              >
                <VersionLabel version={madeInVersion.version} />
              </Button>
            </div>
          )}
        </div>
      );
    },
  },
  {
    Header: t('results-overview.column.progress'),
    Cell: ({ row }) => {
      const {
        totalNumberOfAnsweredQuestions,
        latestAnswerSubmittedAt,
        allQuestionsHaveScores,
      } = row.original;

      const { isComplete } = progressStates({
        latestAnswerSubmittedAt,
        allQuestionsHaveScores,
        numberOfQuestions: version?.numberOfQuestions,
        totalNumberOfAnsweredQuestions,
      });

      return (
        <div className="flex items-center">
          {latestAnswerSubmittedAt && (
            <div className="w-40">
              <ProgressBar
                maximumProgress={version?.numberOfQuestions}
                progress={totalNumberOfAnsweredQuestions}
                warning={!isComplete}
                success={isComplete}
              />
            </div>
          )}
        </div>
      );
    },
  },
  {
    Header: t('results-overview.column.results'),
    Cell: ({ row }) => {
      const { id, latestAnswerSubmittedAt } = row.original;
      return (
        <div className="flex items-center">
          <Link
            href={{
              pathname: `/classgroups/${classGroupId}/${classGroupUrls[contentType]}/${contentId}/results/${id}`,
              query: version?.id
                ? { viewMode, version: version.id }
                : { viewMode },
            }}
            disabled={!latestAnswerSubmittedAt}
          >
            {t('results-overview.view')}
          </Link>
        </div>
      );
    },
  },
  {
    Header: t('results-overview.column.score'),
    Cell: ({ row }) => {
      const {
        score,
        latestAnswerSubmittedAt,
        allQuestionsHaveScores,
        totalNumberOfAnsweredQuestions,
      } = row.original;

      const { hasNoSubmittedQuestions, isComplete, isFullyGraded } =
        progressStates({
          latestAnswerSubmittedAt,
          allQuestionsHaveScores,
          numberOfQuestions: version?.numberOfQuestions,
          totalNumberOfAnsweredQuestions,
        });

      const shouldShowIndicator = !isFullyGraded || !isComplete;

      return (
        <div
          className="flex items-center flex-grow-0"
          title={
            shouldShowIndicator
              ? t(
                  !isComplete
                    ? 'results-overview.not_fully_submitted'
                    : 'results-overview.not_fully_graded',
                )
              : undefined
          }
        >
          <span
            className={c('w-4 text-right', hasNoSubmittedQuestions && 'ml-5')}
          >
            {hasNoSubmittedQuestions ? '-' : score}
          </span>
          {hasNoSubmittedQuestions || (
            <>
              <span className="px-1">/</span>
              <span className="w-4 pr-3">{version?.maxScore}</span>
            </>
          )}
          {shouldShowIndicator && (
            <div className="w-1 h-1 self-start ml-1">
              <Indicator />
            </div>
          )}
        </div>
      );
    },
  },
];

export default columns;
