import { STUDENTURL as CONTENT_ANSWERS_URL } from 'hooks/api/useContentAnswers';
import { STUDENTURL as FEEDBACK_URL } from 'hooks/api/useFeedbacks';
import useParams from 'hooks/useParams';

import StudentAnswers from './StudentAnswers';

const StudentContainer = ({ contentType, user }) => {
  const { classGroupId, contentId, viewMode, version } = useParams();

  const data = {
    feedbackEndpoint: FEEDBACK_URL({ contentType, contentId }),
    contentAnswersEndpoint: CONTENT_ANSWERS_URL,
  };

  return (
    <StudentAnswers
      data={data}
      user={user}
      contentType={contentType}
      classGroupId={classGroupId}
      contentId={contentId}
      studentId={user.id}
      viewMode={viewMode}
      version={version}
      canEdit={false}
    />
  );
};

export default StudentContainer;
