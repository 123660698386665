import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

export const SlideViewerContext = createContext({});

const SlideViewerContextProvider = ({
  lesson,
  slides: rawSlides,
  temporaryQuestionAnswers,
  submittedQuestionAnswers,
  defaultSlideId,
  defaultViewMode,
  canOverrideAnswers,
  canEdit,
  showConfetti,
  onSlideChange,
  onViewModeChange,
  onTemporaryQuestionAnswers,
  onSubmitQuestionAnswers,
  generateScratchExercisePath,
  onWarning,
  children,
}) => {
  const [viewMode, setViewMode] = useState(defaultViewMode);
  const [currentSlideId, setCurrentSlideId] = useState(defaultSlideId);
  const [fontSizeIndex, setFontSizeIndex] = useState(0);

  const history = useHistory();

  const slides = useMemo(
    () =>
      rawSlides
        .sort((a, b) => a.sequence - b.sequence)
        .map((slide) => {
          // transform questions to question
          // we agreed to support multiple questions on one slide in the future,
          // but for now we'll only allow one question.
          if (!slide.questions) {
            return slide;
          }

          return {
            ...slide,
            question: slide.questions?.[0],
          };
        }),
    [rawSlides],
  );

  const currentSlideIndex = slides?.findIndex(
    (slide) => slide.id === currentSlideId,
  );
  const currentSlide = slides?.[currentSlideIndex];

  const setSlide = useCallback(
    (index) => {
      if (!slides) {
        return;
      }

      const hasTemporaryAnswers =
        !!temporaryQuestionAnswers?.[currentSlide?.question?.id];

      // only show the warning if there is an onWarning and onSubmitQuestionAnswers callback,
      // and if there is a temporary value for the question
      if (onWarning && hasTemporaryAnswers && onSubmitQuestionAnswers) {
        onWarning(slides[index].id, currentSlide?.question);
      } else {
        setCurrentSlideId(slides[index].id);
      }
    },
    [
      slides,
      temporaryQuestionAnswers,
      currentSlide?.question,
      onSubmitQuestionAnswers,
      onWarning,
    ],
  );

  useEffect(() => {
    setCurrentSlideId(defaultSlideId);
  }, [defaultSlideId]);

  useEffect(() => {
    const slide = slides.find(
      (slide) => slide.id?.toString() === currentSlideId?.toString(),
    );
    onSlideChange(slide, viewMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlideId, history, slides, viewMode]);

  const [context, setContext] = useState({
    lesson,
    slides,
    temporaryQuestionAnswers,
    submittedQuestionAnswers,
    viewMode,
    currentSlide,
    currentSlideIndex,
    fontSizeIndex,
    setFontSizeIndex: (fontSize) => setFontSizeIndex(fontSize),
    setSlide,
    setCurrentSlideId,
    setViewMode: (newViewMode) => {
      onViewModeChange(newViewMode, { currentSlideIndex, currentSlide });
      setViewMode(newViewMode, currentSlide);
    },
    onTemporaryQuestionAnswers,
    onSubmitQuestionAnswers,
    generateScratchExercisePath,
  });

  useEffect(() => {
    const dataObject = {
      lesson,
      slides,
      temporaryQuestionAnswers,
      submittedQuestionAnswers,
      viewMode,
      currentSlide,
      currentSlideIndex,
      fontSizeIndex,
      canOverrideAnswers,
      canEdit,
      showConfetti,
    };
    setContext({
      ...dataObject,
      setFontSizeIndex: (fontSize) => setFontSizeIndex(fontSize),
      setSlide,
      setCurrentSlideId,
      setViewMode: (newViewMode) => {
        onViewModeChange(newViewMode, { currentSlideIndex, currentSlide });
        setViewMode(newViewMode, currentSlide);
      },
      onTemporaryQuestionAnswers,
      onSubmitQuestionAnswers,
      generateScratchExercisePath,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lesson,
    slides,
    temporaryQuestionAnswers,
    submittedQuestionAnswers,
    viewMode,
    currentSlide,
    currentSlideIndex,
    fontSizeIndex,
    canOverrideAnswers,
    canEdit,
    showConfetti,
    setFontSizeIndex,
    setViewMode,
    onSlideChange,
    onViewModeChange,
    onTemporaryQuestionAnswers,
    setSlide,
    setCurrentSlideId,
  ]);

  useEffect(() => {
    const handle = (e) => {
      if (
        (e.key === 'ArrowLeft' || e.key === 'PageDown') &&
        currentSlideIndex > 0
      ) {
        setSlide(currentSlideIndex - 1);
      }

      if (
        (e.key === 'ArrowRight' || e.key == 'PageUp') &&
        currentSlideIndex < slides.length - 1
      ) {
        setSlide(currentSlideIndex + 1);
      }
    };
    window.addEventListener('keyup', handle);
    return () => window.removeEventListener('keyup', handle);
  }, [currentSlideIndex, fontSizeIndex, setSlide, slides]);

  useEffect(() => {
    setFontSizeIndex(0);
  }, [viewMode]);

  return (
    <SlideViewerContext.Provider value={context}>
      {children}
    </SlideViewerContext.Provider>
  );
};

SlideViewerContextProvider.defaultProps = {
  slides: [],
  onSlideChange: () => {},
  onViewModeChange: () => {},
  generateScratchExercisePath: () => {},
  temporaryQuestionAnswers: {},
  submittedQuestionAnswers: [],
  canOverrideAnswers: false,
  canEdit: true,
};

export default SlideViewerContextProvider;
