const StudentOverviewTableSkeleton = ({ loading, children }) =>
  loading ? (
    <div className="flex flex-col w-full space-y-2" data-testid="loading">
      <div className="h-8 w-full bg-gray-200 rounded animate-pulse" />
      {Array.from(Array(5), (_, i) => (
        <div
          key={i}
          className="h-16 w-full bg-gray-200 rounded animate-pulse"
        />
      ))}
    </div>
  ) : (
    children
  );

export default StudentOverviewTableSkeleton;
