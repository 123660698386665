import { Children, cloneElement } from 'react';

const FTRPRFLink = ({ to, children }) => {
  const child = Children.only(children);

  return cloneElement(child, {
    onClick: () =>
      window.location.assign(`${process.env.REACT_APP_EDU_URL}${to}`),
  });
};

export default FTRPRFLink;
