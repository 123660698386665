import { FaTrash } from 'react-icons/fa';

import { ListContextMenu } from '@ftrprf/context-menu';
import { MenuButton } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

const RowContextMenu = ({ children, canDelete, userId, openRemoveModal }) => {
  const t = useFormatMessage();

  return (
    <ListContextMenu isWide={true} trigger={children}>
      {!!canDelete && (
        <MenuButton
          iconBefore={FaTrash}
          onClick={() => openRemoveModal(userId)}
        >
          {t('global.delete')}
        </MenuButton>
      )}
    </ListContextMenu>
  );
};

export default RowContextMenu;
