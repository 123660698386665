import useFormatMessage from 'hooks/useFormatMessage';

const NoClasses = () => {
  const t = useFormatMessage();
  return (
    <div className="flex h-full items-center">{t('errors.no_classgroups')}</div>
  );
};

export default NoClasses;
