/* eslint-disable react/display-name */
import { BsThreeDotsVertical } from 'react-icons/bs';

import { Button } from '@ftrprf/tailwind-components';

import Avatar from 'components/Avatar';

import RowContextMenu from './partials/RowContextMenu';

const columns = ({
  canDelete,
  t,
  formatDate,
  routeToDetail,
  openRemoveModal,
  openResetModal,
}) => [
  {
    Header: t('student-overview.column.name'),
    Cell: ({ row }) => {
      const { firstName, lastName, username } = row.original;

      return (
        <div className="flex gap-x-4 items-center" title={username}>
          <Avatar
            className="w-10 h-10 flex-shrink-0 text-white"
            firstName={firstName}
            lastName={lastName}
          />
          {`${firstName} ${lastName}`}
        </div>
      );
    },
  },
  {
    Header: t('student-overview.column.username'),
    Cell: ({ row }) => {
      const { username } = row.original;

      return <div className="flex gap-x-4 items-center">{username}</div>;
    },
  },
  {
    Header: t('student-overview.column.last-login'),
    Cell: ({ row }) => {
      const { lastLogin } = row.original;

      return (
        <div className="flex gap-x-4 items-center">{formatDate(lastLogin)}</div>
      );
    },
  },
  {
    Header: '   ',
    Cell: ({ row }) => (
      <Button
        className="text-accent-500 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          routeToDetail({ id: row.original.id });
        }}
      >
        {t('global.edit')}
      </Button>
    ),
  },
  {
    Header: ' ',
    Cell: ({ row }) => {
      return (
        <RowContextMenu
          canDelete={canDelete}
          userId={row.original.id}
          openRemoveModal={openRemoveModal}
          openResetModal={openResetModal}
        >
          {({ toggle }) => (
            <Button
              className="hover:bg-gray-400 rounded-lg p-2"
              onClick={(e) => {
                toggle(e);
              }}
            >
              <BsThreeDotsVertical />
            </Button>
          )}
        </RowContextMenu>
      );
    },
  },
];

export default columns;
