import { useQuery } from 'react-query';

import versionTypes from 'utils/constants/versionTypes';

export const URL = ({ contentType, contentId, viewMode, version }) =>
  `${process.env.REACT_APP_API_URL}/content/${contentType}/${contentId}/versions/${version}/questions?viewMode=${viewMode}`;

const useContentVersionQuestions = ({
  contentType,
  contentId,
  viewMode,
  version = versionTypes.LATEST,
}) => {
  const response = useQuery(URL({ contentType, contentId, viewMode, version }));

  return {
    ...response,
    data: response.data?.slides,
  };
};

export default useContentVersionQuestions;
