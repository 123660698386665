import { useCallback, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  FilledButton,
  Input,
  Label,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

const ResetPasswordDialog = ({
  isOpen,
  onDismiss: dismiss,
  resetPassword,
  isResetting,
}) => {
  const t = useFormatMessage();

  const [confirmationPassword, setConfirmationPassword] = useState('');

  const onResetPassword = useCallback(() => {
    setConfirmationPassword('');
    resetPassword(confirmationPassword);
  }, [confirmationPassword, resetPassword]);

  const onDismiss = useCallback(() => {
    dismiss();
    setConfirmationPassword('');
  }, [dismiss]);

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onResetPassword();
        }}
      >
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col">
              <Label>
                {t(
                  'student-overview.column.reset-password.your-password-label',
                )}
              </Label>
              <Input
                autoComplete="new-password"
                type="password"
                value={confirmationPassword}
                onChange={(e) => setConfirmationPassword(e.target.value)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <OutlineButton disabled={isResetting} onClick={onDismiss}>
            {t('global.cancel')}
          </OutlineButton>
          <FilledButton
            type="submit"
            disabled={!confirmationPassword || isResetting}
            loading={isResetting}
          >
            {t('student-overview.column.reset-password.confirm')}
          </FilledButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ResetPasswordDialog;
