import { createContext, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';

import roles from 'utils/constants/roles';

export const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const { instance } = useMsal();

  const account = instance?.getActiveAccount();

  const context = useMemo(() => {
    const user = account?.idTokenClaims;
    const roleConditions = user && {
      isStudent:
        user.roles?.includes(roles.FTRPRF_SCHOOLSTUDENT) ||
        user.roles?.includes(roles.CCSMS_SCHOOLSTUDENT) ||
        user.roles?.includes(roles.LPF_SCHOOLSTUDENT) ||
        user.roles?.includes(roles.CODECOSMOS_STUDENT),
      isTeacher:
        user.roles?.includes(roles.FTRPRF_SCHOOLTEACHER) ||
        user.roles?.includes(roles.CCSMS_SCHOOLTEACHER) ||
        user.roles?.includes(roles.LPF_SCHOOLTEACHER) ||
        user.roles?.includes(roles.FTRPRF_SCHOOLADMIN) ||
        user.roles?.includes(roles.CCSMS_SCHOOLADMIN) ||
        user.roles?.includes(roles.LPF_SCHOOLADMIN) ||
        user.roles?.includes(roles.CODECOSMOS_TEACHER),
      isAdmin:
        user.roles?.includes(roles.FTRPRF_ADMIN) ||
        user.roles?.includes(roles.CCSMS_ADMIN) ||
        user.roles?.includes(roles.LPF_ADMIN),
      isSchoolAdmin:
        user.roles?.includes(roles.FTRPRF_SCHOOLADMIN) ||
        user.roles?.includes(roles.CCSMS_SCHOOLADMIN) ||
        user.roles?.includes(roles.LPF_SCHOOLADMIN),
      isSmartschoolUser: user.idp_platform?.includes('smartschool'),
    };

    return {
      ...user,
      ...roleConditions,
    };
  }, [account]);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
