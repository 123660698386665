import { FaUsersCog } from 'react-icons/fa';
import { generatePath, useHistory } from 'react-router-dom';
import { classGroupUrls } from 'app/ClassGroupApp';

import {
  Button,
  DropdownButton,
  DropdownMenu,
  MenuButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import c from 'utils/c';
import urls from 'utils/constants/urls';

const ClassGroupDropDown = ({
  classGroups,
  selectedClassGroupId,
  hasSettings,
}) => {
  const t = useFormatMessage();
  const history = useHistory();

  const redirectToSettings = (newClassgroup) => {
    history.push(
      generatePath(`${urls.CLASSGROUP}/${classGroupUrls.STUDENT_OVERVIEW}`, {
        classGroupId: newClassgroup,
      }),
    );
  };

  const redirect = (newClassgroup) => {
    if (history.location.pathname.endsWith(classGroupUrls.STUDENT_OVERVIEW)) {
      redirectToSettings(newClassgroup);

      return;
    }

    history.push(
      generatePath(urls.CLASSGROUP, {
        classGroupId: newClassgroup,
      }),
    );
  };

  const classGroup = classGroups.find((c) => c.id === selectedClassGroupId);

  return (
    <div className="h-full w-full border-gray-300 border-t lg:border-t-0 lg:border-l lg:border-r lg:mx-2 lg:w-auto">
      <DropdownMenu
        className="h-full"
        leftAligned
        trigger={({ isOpen }) => (
          <DropdownButton isOpen={isOpen} className="h-full">
            <div className="px-1 flex gap-x-2 items-center">
              <div className="flex-col justify-start">
                <span className="text-xxs text-gray-500">
                  {t('global.class')}
                </span>
                <p className="-mt-1.5 text-left ">{classGroup?.name}</p>
              </div>
            </div>
          </DropdownButton>
        )}
      >
        {classGroups?.map((classGroup) => {
          return (
            <div key={classGroup.id} className="flex justify-between w-28">
              <MenuButton
                onClick={() => redirect(classGroup.id)}
                className={c(
                  classGroup.id === selectedClassGroupId && 'font-semibold',
                )}
              >
                <span className="">
                  <span>{classGroup.name}</span>
                </span>
              </MenuButton>
              {hasSettings && (
                <Button
                  className="text-gray-500 rounded-lg hover:bg-gray-200 mr-2 p-1"
                  onClick={() => redirectToSettings(classGroup.id)}
                >
                  <FaUsersCog className="w-5 h-5" />
                </Button>
              )}
            </div>
          );
        })}
      </DropdownMenu>
    </div>
  );
};

ClassGroupDropDown.defaultProps = {
  hasSettings: false,
};

export default ClassGroupDropDown;
