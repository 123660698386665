const calculateAverage = (students) => {
  if (!students || students.length === 0) {
    return null;
  }

  const summedScore = students.reduce((sum, { score }) => sum + score, 0);

  return summedScore / students.length;
};

export default calculateAverage;
