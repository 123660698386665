import useFormatMessage from 'hooks/useFormatMessage';

const Indicator = () => {
  const t = useFormatMessage();

  return (
    <div
      aria-label={t('global.indicator')}
      className="rounded-full bg-accent-500 h-full w-full ring-white"
    />
  );
};

export default Indicator;
