import { Link as RouterLink } from 'react-router-dom';

import c from 'utils/c';

const Link = ({ href, disabled, children }) => {
  const to = href.pathname
    ? `${href.pathname}?${Object.keys(href.query)
        .map((param) => `${param}=${href.query[param]}`)
        .join('&')}`
    : href;

  const element = (
    <span
      className={c(
        disabled
          ? 'cursor-none text-accent-200 cursor-not-allowed'
          : 'cursor-pointer text-accent-700 ',
      )}
    >
      {children}
    </span>
  );

  return disabled ? element : <RouterLink to={to}>{element}</RouterLink>;
};

export default Link;
