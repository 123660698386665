import { useCallback } from 'react';
import { saveAs } from 'file-saver';

import { get } from 'api/index';

export const URL = (id) =>
  `${process.env.REACT_APP_API_URL}/documents/download/${id}`;

const useDocument = () => {
  return useCallback(({ id, name, type }) => {
    get(URL(id)).then((response) => {
      saveAs(response.file, `${name}.${type.toLowerCase()}`);
    });
  }, []);
};

export default useDocument;
