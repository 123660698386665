import { lazy, Suspense } from 'react';

import useExercise from 'hooks/api/exercise/useExercise';
import useCurrentUser from 'hooks/api/useCurrentUser';
import useUser from 'hooks/api/useUser';
import useParams from 'hooks/useParams';

import { ReactComponent as Loader } from 'assets/vectors/logo-animated-lines.svg';

import ScratchError from './partials/ScratchError';

const Scratch = lazy(() => import('./partials/Scratch'));

const Exercise = () => {
  const { exerciseId, startId } = useParams();

  const { user } = useCurrentUser();

  const {
    exercise,
    isLoading,
    isLoadingTests,
    save,
    isSaving,
    isError,
    isRestarting,
    restart,
    test,
    onExerciseLoaded,
  } = useExercise({
    id: exerciseId,
    startId,
  });

  const { user: owner, isLoading: isLoadingOwner } = useUser(exercise.userId);

  if (isLoadingOwner) {
    return null;
  }

  if (isError) {
    return <ScratchError />;
  }

  const isOwnedExercise = exercise.userId === user.id;

  return (
    <Suspense
      fallback={
        <div className="flex flex-col items-center justify-center w-full h-full">
          <Loader className="w-32" />
        </div>
      }
    >
      <Scratch
        test={test}
        language={user.language}
        exercise={exercise}
        isSaving={isSaving}
        isRestarting={isRestarting}
        isLoading={isLoading || isLoadingOwner}
        isLoadingTests={isLoadingTests}
        isOwnedExercise={isOwnedExercise}
        ownerName={`${owner?.firstName} ${owner?.lastName}`}
        save={save}
        restart={restart}
        onExerciseLoaded={onExerciseLoaded}
      />
    </Suspense>
  );
};

export default Exercise;
