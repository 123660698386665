import { useQuery } from 'react-query';

export const URL = ({ contentType, contentId, viewMode }) =>
  `${process.env.REACT_APP_API_URL}/content/${contentType}/${contentId}/slides?viewMode=${viewMode}`;

const useContentSlides = ({ contentType, contentId, viewMode }) => {
  const endpoint = URL({ contentType, contentId, viewMode });
  const response = useQuery(endpoint);

  return { contentSlides: response.data, ...response, endpoint };
};

export default useContentSlides;
