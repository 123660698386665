import { useState } from 'react';

import isFunction from 'utils/isFunction';

const useLocalStorage = (identifier, initialData) => {
  const [data, setData] = useState(
    window.localStorage.getItem(identifier)
      ? JSON.parse(window.localStorage.getItem(identifier))
      : initialData,
  );

  const setLocalStorageData = (fn) =>
    setData((prevData) => {
      const newData = isFunction(fn) ? fn(prevData) : fn;

      window.localStorage.setItem(identifier, JSON.stringify(newData));
      setData(newData);
    });

  return [data, setLocalStorageData];
};

export default useLocalStorage;
