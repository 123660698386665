import { Link } from 'react-router-dom';

import {
  Content,
  PageHeader,
  PageTitle,
  SearchBar,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import c from 'utils/c';

import HackRoomLessonCard from './partials/HackRoomLessonCard';
import HackRoomSkeleton from './partials/HackRoomSkeleton';

const HackRoom = ({
  hackRoomLessons,
  featuredLessons,
  isLoadingHackRoomLessons,
  classGroupId,
  setFilter,
}) => {
  const t = useFormatMessage();

  return (
    <>
      <PageHeader>
        <div className="w-full flex justify-between">
          <PageTitle>{t('global.hack_room')}</PageTitle>
        </div>
      </PageHeader>
      <Content>
        <div className="w-full flex flex-col space-y-4">
          <HackRoomSkeleton loading={isLoadingHackRoomLessons}>
            <h2 className="text-2xl font-bold leading-none pt-2 hidden sm:block">
              {t('hack-room.featured')}
            </h2>
            <div className="hidden sm:grid gap-6 grid-cols-2 md:grid-cols-4 xl:grid-cols-6 overflow-y-hidden">
              {featuredLessons?.map((hackRoomLesson, index) => (
                <HackRoomLessonCard
                  className={c(
                    'w-full',
                    index > 1 && index < 4 && 'hidden md:block',
                    index > 3 && index < 6 && 'hidden xl:block',
                  )}
                  key={hackRoomLesson.id}
                  hackRoomLesson={hackRoomLesson}
                  classGroupId={classGroupId}
                  isFeatured
                />
              ))}
            </div>
            <div className="w-full my-4 justify-betwee pt-6 hidden sm:flex">
              <h2 className="text-2xl font-bold leading-none ">
                {t('global.LESSON.plural')}
              </h2>
            </div>
            <SearchBar
              onChange={(e) => setFilter(e.target.value)}
              placeholder={t('hack-room.what_are_you_looking_for')}
              className="mb-2"
            >
              {({ item: { value, id, url } }) => (
                <Link to={url} key={id}>
                  <div className="w-full hover:bg-gray-200">{value}</div>
                </Link>
              )}
            </SearchBar>
            {hackRoomLessons?.length === 0 && (
              <div className="flex flex-grow w-full items-center justify-center">
                <span>{t('global.empty')}</span>
              </div>
            )}
            <div className="w-full grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
              {hackRoomLessons?.map((hackRoomLesson) => (
                <HackRoomLessonCard
                  className="w-full h-full"
                  key={hackRoomLesson.id}
                  hackRoomLesson={hackRoomLesson}
                  classGroupId={classGroupId}
                />
              ))}
            </div>
          </HackRoomSkeleton>
        </div>
      </Content>
    </>
  );
};

export default HackRoom;
