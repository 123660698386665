import { useContext, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { put } from '../../api/index';

import useFormatMessage from '../useFormatMessage';

import { TEACHERURL as createAnswerURL } from './useContentAnswers';

const URL = ({ classGroupId, contentId, contentType, studentId, questionId }) =>
  `${process.env.REACT_APP_API_URL}/classGroups/${classGroupId}/content/${contentType}/${contentId}/students/${studentId}/questions/${questionId}/score`;

const useScore = ({
  contentId,
  contentType,
  classGroupId,
  studentId,
  questionId,
}) => {
  const t = useFormatMessage();
  const queryCache = useQueryClient();
  const { addNotification } = useContext(NotificationContext);

  const url = URL({
    classGroupId,
    contentId,
    contentType,
    studentId,
    questionId,
  });

  const answerURL = useMemo(
    () => createAnswerURL({ contentId, contentType, classGroupId, studentId }),
    [contentId, contentType, classGroupId, studentId],
  );

  const handleRequest = ({ score }) => put(url, { score });

  const { mutate: publishScore, isLoading } = useMutation(
    (score) => handleRequest({ score }),
    {
      onMutate: (score) => {
        const oldCache = queryCache.getQueryData(answerURL);

        queryCache.setQueryData(answerURL, (oldAnswers) => {
          const newAnswers = [...oldAnswers];

          const answerIndex = oldAnswers.findIndex(
            (question) => question.questionId === questionId,
          );

          newAnswers[answerIndex] = { ...oldAnswers[answerIndex], score };
          return newAnswers;
        });

        return () => queryCache.setQueryData(answerURL, oldCache);
      },
      onError: (_, _newData, rollback) => {
        rollback();
        addNotification({
          type: 'error',
          content: t('errors.update_score'),
        });
      },
    },
  );

  return { publishScore, isLoading };
};

export default useScore;
