import '@ftrprf/tailwind-components/slideviewer-styles.css';

import { useMemo } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { generatePath, Link } from 'react-router-dom';
import { classGroupUrls } from 'app/ClassGroupApp';

import {
  Content,
  FilledButton,
  InfoBar,
  PageHeader,
} from '@ftrprf/tailwind-components';

import PageTitle, { PageTitleSkeleton } from 'components/PageTitle';

import useClassGroupContent from 'hooks/api/useClassGroupContent';
import useContent from 'hooks/api/useContent';
import useContentAnswers from 'hooks/api/useContentAnswers';
import useContentVersionQuestions from 'hooks/api/useContentVersionQuestions';
import useContentVersions from 'hooks/api/useContentVersions';
import useCurrentContent from 'hooks/useCurrentContent';
import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import c from 'utils/c';
import urls from 'utils/constants/urls';
import versionTypes from 'utils/constants/versionTypes';
import viewModes from 'utils/constants/viewModes';

import {
  StudentAnswersContentSkeleton,
  StudentAnswersHeaderSkeleton,
} from './partials/StudentAnswersSkeleton';
import StudentAnswersQuestion from './partials/StudentsAnswerQuestion';
import StudentSwitcher from './partials/StudentSwitcher';

/**
 * This component should be refactored because classGroupContentStudents does not exists for one individual student.
 * Instead of passing classGroupContentStudents, pass the individual result for a particular student.
 * If you want to have a list of all students (e.g. for the StudentSwitcher), pass an extra prop to this component: renderXXX
 */
const StudentAnswers = ({
  data: { classGroupContentStudents, feedbackEndpoint, contentAnswersEndpoint },
  user,
  contentType,
  classGroupId,
  contentId,
  studentId,
  viewMode,
  version,
  canEdit,
}) => {
  const t = useFormatMessage();

  const contentDetails = useContent({
    contentId,
    contentType,
    viewMode,
  });

  const contentAnswers = useContentAnswers({
    contentId,
    contentType,
    endpoint: contentAnswersEndpoint,
    studentId,
    classGroupId,
  });

  const classGroupContent = useClassGroupContent({
    classGroupId,
    contentType,
    contentId,
  });

  const contentQuestions = useContentVersionQuestions({
    contentType,
    contentId,
    viewMode,
    version,
  });

  const { versions, latestVersion } = useContentVersions({
    contentId: contentId,
    contentType: contentType,
    viewMode,
  });

  const currentVersion = useMemo(
    () =>
      version && version !== versionTypes.LATEST
        ? versions?.find(({ id }) => id === version)
        : latestVersion,
    [versions, version, latestVersion],
  );

  const totalScore = useMemo(
    () =>
      contentAnswers?.data?.reduce((prev, answer) => prev + answer.score, 0),
    [contentAnswers],
  );

  const { programUrl } = useCurrentContent();

  const questionSlides = useMemo(() => {
    if (contentAnswers.data && contentQuestions.data) {
      const index = Object.fromEntries(
        contentAnswers.data.map((a) => [a.questionId, a]),
      );

      return contentQuestions.data
        .map((slide) => {
          return slide.questions.map((question) => ({
            slide,
            question,
            answer: index[String(question.id)],
          }));
        })
        .flat();
    }

    return [];
  }, [contentQuestions.data, contentAnswers.data]);

  useTitle(c(contentDetails.data?.title));

  const classGroupContentStudent = classGroupContentStudents
    ? classGroupContentStudents?.data?.find(({ id }) => id === studentId)
    : contentDetails.data;

  return (
    <>
      <StudentAnswersHeaderSkeleton
        contentLoading={contentDetails.isLoading}
        classGroupContentStudentsLoading={
          classGroupContentStudents?.isLoading || false
        }
      >
        <PageHeader>
          <div className="flex justify-between items-end">
            {contentDetails.data ? (
              <PageTitle label={t('student-answers.title.results')}>
                {contentDetails.data?.title}
              </PageTitle>
            ) : (
              <PageTitleSkeleton />
            )}
            <div>
              <div className="flex items-baseline justify-end">
                <p className="text-gray-500 text-sm mr-2">
                  {t('student-answers.title.total').toUpperCase()}
                </p>
                <p className="text-lg">
                  {totalScore}/{currentVersion?.maxScore}
                </p>
              </div>
              {canEdit && (
                <StudentSwitcher
                  contentType={contentType}
                  classGroupContentStudents={classGroupContentStudents.data}
                  student={studentId}
                />
              )}
            </div>
          </div>
        </PageHeader>
        {canEdit && !classGroupContentStudent?.allQuestionsAnswered && (
          <InfoBar
            container={(children) => (
              <Content hasPaddingBottom={false}>{children}</Content>
            )}
            text={t('results-overview.not_fully_submitted')}
          />
        )}
        <div className="w-full max-w-5/6 mt-4">
          <Link
            to={
              canEdit
                ? generatePath(
                    `${urls.CLASSGROUP}/:contentType/:contentId/results`,
                    {
                      classGroupId,
                      contentType: classGroupUrls[contentType],
                      contentId,
                    },
                  )
                : programUrl
            }
          >
            <FilledButton secondary iconBefore={FaArrowLeft}>
              {t('global.go_back')}
            </FilledButton>
          </Link>
        </div>
      </StudentAnswersHeaderSkeleton>
      <StudentAnswersContentSkeleton
        loading={
          contentQuestions.isLoading ||
          contentAnswers.isLoading ||
          classGroupContent.isLoading
        }
      >
        {questionSlides.length === 0 ? (
          <div className="flex-grow flex items-center justify-center">
            {t('student-answers.no_questions')}
          </div>
        ) : !classGroupContent?.data?.resultsPublished && !canEdit ? (
          <div className="flex-grow flex items-center justify-center">
            {t('student-answers.no_results_published')}
          </div>
        ) : (
          <div className="w-full flex flex-col items-center divide-y divide-gray-300">
            {questionSlides.map(({ slide, question, answer }, i) => (
              <StudentAnswersQuestion
                key={question.id}
                slide={slide}
                answer={answer}
                questionNumber={i + 1}
                question={question}
                canEdit={canEdit}
                user={user}
                contentType={contentType}
                feedbackEndpoint={feedbackEndpoint}
              />
            ))}
          </div>
        )}
      </StudentAnswersContentSkeleton>
    </>
  );
};

StudentAnswers.defaultProps = {
  viewMode: viewModes.CLASSICAL,
  version: versionTypes.LATEST,
};

export default StudentAnswers;
