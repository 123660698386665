import { Link } from 'react-router-dom';

import { FilledButton, PageHeader } from '@ftrprf/tailwind-components';

import ContentDetails from 'pages/ContentDetails/ContentDetails';
import Error404 from 'pages/Error/Error404';
import StudentResultsOverview from 'pages/StudentResultsOverview';

import PageTitle from 'components/PageTitle';
import Pivot, { PivotLinkElement } from 'components/Pivot';

import useClassGroupContent from 'hooks/api/useClassGroupContent';
import useContent from 'hooks/api/useContent';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';
import useCurrentContent from 'hooks/useCurrentContent';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';
import useTitle from 'hooks/useTitle';

import { removeLeadingAndTrailingSlash } from 'utils/removeLeadingAndTrailingSlash';

import ContentSkeleton from './partials/ContentSkeleton';

const Content = ({ contentType, defaultViewMode }) => {
  const t = useFormatMessage();

  const { contentId, active } = useParams();
  const { id: classGroupId, name } = useCurrentClassGroup();

  const {
    contentDetails,
    isLoading: contentDetailsLoading,
    isError: contentDetailsError,
  } = useContent({
    contentId,
    contentType,
  });

  const { isLoading: classGroupContentLoading } = useClassGroupContent({
    classGroupId,
    contentId,
    contentType,
  });

  const { viewUrl } = useCurrentContent(defaultViewMode);

  const activePivotElement = removeLeadingAndTrailingSlash(active);

  useTitle(contentDetails?.title, name);

  if (contentDetailsError) {
    return <Error404 />;
  }

  return (
    <ContentSkeleton
      isLoading={contentDetailsLoading || classGroupContentLoading}
    >
      <PageHeader borderBottom={false}>
        <div className="w-full flex justify-between">
          <PageTitle>{contentDetails?.title}</PageTitle>
          <Link to={viewUrl()}>
            <FilledButton>
              <div className="p-1 w-full">
                {t(`global.${contentType}.view`)}
              </div>
            </FilledButton>
          </Link>
        </div>
      </PageHeader>
      <div className="w-full">
        <Pivot
          active={activePivotElement || 'overview'}
          itemsRender={(children) => (
            <PageHeader marginBottom={false} marginTop={false}>
              <div className="-mb-px">{children}</div>
            </PageHeader>
          )}
        >
          <PivotLinkElement name="overview" label={t('content.overview')}>
            <ContentDetails contentType={contentType} />
          </PivotLinkElement>
          <PivotLinkElement name="results" label={t('content.results')}>
            <StudentResultsOverview contentType={contentType} />
          </PivotLinkElement>
        </Pivot>
      </div>
    </ContentSkeleton>
  );
};

Content.defaultProps = {
  defaultViewMode: undefined,
};

export default Content;
