const progressStates = ({
  latestAnswerSubmittedAt,
  allQuestionsHaveScores,
  numberOfQuestions,
  totalNumberOfAnsweredQuestions,
}) => {
  const totalNumberOfUnansweredQuestions =
    numberOfQuestions - totalNumberOfAnsweredQuestions;

  const allQuestionsAnswered = totalNumberOfUnansweredQuestions === 0;

  return {
    totalNumberOfUnansweredQuestions,
    hasNoSubmittedQuestions: !latestAnswerSubmittedAt,
    isComplete: allQuestionsAnswered && !!latestAnswerSubmittedAt,
    isFullyGraded:
      allQuestionsHaveScores &&
      allQuestionsAnswered &&
      !!latestAnswerSubmittedAt,
  };
};

export default progressStates;
