import { useEffect, useState } from 'react';

const myHeaders = new Headers();
myHeaders.append('Authorization', 'test');
myHeaders.append('Content-Type', 'application/json');

const iframeId = '_vm';
const iframeOrigin = 'https://tools.vrtinnovatie.be';
const body = document.getElementsByTagName('body')[0];
const iframeSource = iframeOrigin + '/steams/virtual-moderator/vm-frontend/';
let initFrameOnGroup = false;

const STEAMS_SCHOOLS = [
  'ee3aaddc-3056-4270-994c-ccb6e0ba0856',
  '00233226-228d-481b-bbd5-483208a41a73',
];

// 1. VIRTUAL MODERATOR
// 2. GET GROUP ID
// 3. PASS PAGE NUMBER TO STEAMS
const useSteams = (user) => {
  const [groupId, setGroupId] = useState();
  const isSteams = STEAMS_SCHOOLS.indexOf(user.school.id) > -1;

  // 1. VIRTUAL MODERATOR
  const vm_eventhandler = (event) => {
    if (event.origin !== iframeOrigin) return;
    const { width, height } = JSON.parse(event.data);
    const iframe = document.getElementById(iframeId);
    iframe.width = window.innerWidth < width ? window.innerWidth : width;
    iframe.height = window.innerHeight < height ? window.innerHeight : height;
  };

  const initVm = (forcedGroupId) => {
    if (isSteams) {
      if (groupId || forcedGroupId) {
        const iframeParams = '?groupId=' + (groupId ? groupId : forcedGroupId);
        const frame = document.createElement('iframe');
        frame.src = iframeSource + iframeParams;
        frame.id = iframeId;
        frame.width = 600;
        frame.height = 120;
        frame.style =
          'border: none; position:fixed; bottom: 0px; right: 0px; z-index: 9999';
        body.appendChild(frame);
        window.addEventListener('message', vm_eventhandler, false);
      } else {
        initFrameOnGroup = true;
      }
    }
  };

  const destroyVm = () => {
    if (isSteams) {
      const iframe = document.getElementById(iframeId);
      if (iframe) {
        body.removeChild(iframe);
      }

      window.removeEventListener('message', vm_eventhandler, false);
    }
  };
  // END VIRTUAL MODERATOR

  // 2. GET GROUP ID
  // currently a fake version, just get all groups and take the first one.
  // eslint-disable-next-line
  const getGroupId = () => {
    if (isSteams) {
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(
        'https://steams.cs.kuleuven.be/api/groups/?studentId=' + user.id,
        requestOptions,
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            setGroupId(data[0]._id);

            if (initFrameOnGroup) {
              initVm(data[0]._id);
            }
          }
        })
        .catch((error) => console.log('error', error));
    }
  };
  // Get group id on hook init.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getGroupId, []);

  // END GROUP

  // 3. PASS PAGE NUMBER TO STEAMS
  const sendPageNumber = (sequence) => {
    if (isSteams) {
      if (groupId) {
        const raw = JSON.stringify({
          group: groupId,
          type: 'lesson',
          lesson: {
            type: 'progress',
            progress: {
              slide: {
                sequence,
              },
            },
          },
        });

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };

        fetch('https://steams.cs.kuleuven.be/api/logs', requestOptions)
          .then((response) => response.text())
          // eslint-disable-next-line
          .then((result) => {
            // Response from steams is just groupId
            //console.log(result);
          })
          .catch((error) => console.log('error', error));
      } else {
        setTimeout(() => sendPageNumber(sequence), 1000);
      }
    }
  };
  // END PASS PAGE NUMBER TO STEAMS

  return {
    initVm,
    destroyVm,
    sendPageNumber,
    groupId,
    isSteams,
  };
};

export default useSteams;
