import { useHistory } from 'react-router-dom';
import { classGroupUrls } from 'app/ClassGroupApp';

import Dropdown from 'components/Dropdown';
import Switcher from 'components/Switcher';

import useParams from 'hooks/useParams';

import versionTypes from 'utils/constants/versionTypes';

const StudentSwitcher = ({ contentType, classGroupContentStudents }) => {
  const history = useHistory();
  const {
    classGroupId,
    contentId,
    studentId: currentStudentId,
    viewMode,
    version,
  } = useParams();

  const navigateToStudent = (studentId) => {
    history.replace({
      pathname: `/classgroups/${classGroupId}/${classGroupUrls[contentType]}/${contentId}/results/${studentId}`,
      search: `?${new URLSearchParams({
        viewMode,
        version: version || versionTypes.LATEST,
      }).toString()}`,
    });
  };

  const submittedStudents = classGroupContentStudents?.filter(
    (s) => !!s.latestAnswerSubmittedAt,
  );

  const studentIndex = submittedStudents?.findIndex(
    (student) => student.id === currentStudentId,
  );

  const studentsList = submittedStudents?.map((student) => ({
    key: student.id,
    value: `${student.firstName} ${student.lastName}`,
  }));

  if (!submittedStudents?.length) {
    return null;
  }

  return (
    <div className="flex gap-x-2 items-center text-center">
      <Dropdown
        values={studentsList}
        selected={currentStudentId}
        onChange={navigateToStudent}
      />

      <Switcher
        onNext={() =>
          navigateToStudent(submittedStudents[studentIndex + 1]?.id)
        }
        onPrevious={() =>
          navigateToStudent(submittedStudents[studentIndex - 1]?.id)
        }
        itemsSize={submittedStudents?.length}
        currentIndex={studentIndex}
      />
    </div>
  );
};

StudentSwitcher.defaultProps = {
  onChange: () => {},
};

export default StudentSwitcher;
