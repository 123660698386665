import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { NotificationContext } from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { put } from 'api/index';

export const URL = ({ classGroupId, contentId, contentType }) =>
  `${process.env.REACT_APP_API_URL}/classGroups/${classGroupId}/content/${contentType}/${contentId}`;

const useClassGroupContent = ({ classGroupId, contentId, contentType }) => {
  const t = useFormatMessage();
  const queryCache = useQueryClient();

  const { addNotification } = useContext(NotificationContext);

  const url = URL({ classGroupId, contentId, contentType });

  const response = useQuery(url);

  const { mutate: setResultsPublished } = useMutation(
    (value) =>
      put(url, {
        resultsPublished: value,
      }),
    {
      onMutate: (value) => {
        queryCache.cancelQueries(url);

        const oldData = queryCache.getQueryData(url);
        queryCache.setQueryData(url, (oldQueryCache) => ({
          ...oldQueryCache,
          resultsPublished: value,
        }));

        return () => queryCache.setQueryData(url, oldData);
      },
      onSettled: () => {
        queryCache.invalidateQueries(url);
      },
      onError: () => {
        addNotification({
          type: 'error',
          content: t('errors.set_results_published'),
        });
      },
    },
  );

  const { mutate: setPublishStatus } = useMutation(
    (value) => put(url, { publishStatus: value }),
    {
      onMutate: (value) => {
        queryCache.cancelQueries(url);

        const oldData = queryCache.getQueryData(url);
        queryCache.setQueryData(url, (oldQueryCache) => ({
          ...oldQueryCache,
          publishStatus: value,
        }));

        return () => queryCache.setQueryData(url, oldData);
      },
      onSettled: () => {
        queryCache.invalidateQueries(url);
      },
      onError: () => {
        addNotification({
          type: 'error',
          content: t('errors.set_publish_status'),
        });
      },
    },
  );

  const classGroupContent = response.data;

  return {
    classGroupContent,
    setResultsPublished,
    setPublishStatus,
    ...response,
  };
};

export default useClassGroupContent;
