import { QUESTION_TYPES } from '@ftrprf/tailwind-components';

import StudentAnswersMultipleChoiceResult from './StudentAnswersMultipleChoiceResult';
import StudentAnswersOpenResult from './StudentAnswersOpenResult';

const StudentAnswersQuestionResults = {
  [QUESTION_TYPES.MULTIPLE_CHOICE]: StudentAnswersMultipleChoiceResult,
  [QUESTION_TYPES.OPEN]: StudentAnswersOpenResult,
};

const StudentAnswersQuestionResult = ({
  className,
  question,
  answer,
  canEdit,
  contentType,
}) => {
  const Component = StudentAnswersQuestionResults[question.type];

  return (
    <Component
      contentType={contentType}
      className={className}
      question={question}
      answer={answer}
      canEdit={canEdit}
    />
  );
};

export default StudentAnswersQuestionResult;
