import { useQuery } from 'react-query';
import slugify from 'slugify';

import useCurrentClassGroup from 'hooks/useCurrentClassGroup';

import { removeLeadingSlash } from 'utils/removeLeadingAndTrailingSlash';

export const URL = `${process.env.REACT_APP_API_URL}/users/me`;

const createReversedIndex = (index) => {
  const reversedIndex = {};

  Object.entries(index).forEach(([_, { items }]) => {
    items.forEach((item) => {
      if (!item.programId) {
        return;
      }

      reversedIndex[item.programId] = {
        path: item.path,
      };
    });
  });

  return reversedIndex;
};

const createClassGroupIndex = (classGroupPrograms) => {
  const index = { '': { title: '', items: [], pathChain: [] } };

  classGroupPrograms.forEach((classGroupProgram) => {
    const path = [
      ...classGroupProgram.program.curriculumPath,
      {
        id: classGroupProgram.id,
        programId: classGroupProgram.program.id,
        title: classGroupProgram.program.name,
        sequence: classGroupProgram.program.sequence,
        document: classGroupProgram.program.document,
        hasLessons: classGroupProgram.hasLessons,
        hasExams: classGroupProgram.hasExams,
      },
    ];

    const currentItem = path[path.length - 1];

    path.reduce((acc, p) => {
      const prevPath = removeLeadingSlash(acc);
      const relativePath = removeLeadingSlash(`${acc}/${slugify(p.title)}`);

      if (!index[relativePath]) {
        index[relativePath] = {
          id: p.id,
          programId: p.programId,
          title: p.title,
          sequence: p.sequence,
          path: relativePath,
          pathChain: [
            ...index[prevPath].pathChain,
            { name: p.title, href: relativePath },
          ],
          items: [],
          hasLessons: currentItem.hasLessons,
          hasExams: currentItem.hasExams,
          document: p.document,
        };

        index[acc].items.push(index[relativePath]);
      }

      index[relativePath].hasLessons =
        index[relativePath].hasLessons || currentItem.hasLessons;
      index[relativePath].hasExams =
        index[relativePath].hasExams || currentItem.hasExams;

      return relativePath;
    }, '');
  });

  return index;
};

const useClassGroupPrograms = () => {
  const { id: classGroupId } = useCurrentClassGroup();

  const response = useQuery(URL, {
    staleTime: 1000 * 60 * 60 * 24 * 365, // https://github.com/tannerlinsley/react-query/discussions/230#discussioncomment-1234
  });

  const programs = (response?.data?.classGroupPrograms || []).filter(
    (program) => program.classGroup.id === classGroupId,
  );

  const index = createClassGroupIndex(programs);
  const reversedIndex = createReversedIndex(index);

  return {
    curriculaIndex: index,
    reversedProgramIndex: reversedIndex,
    isLoading: response.isLoading,
  };
};

export default useClassGroupPrograms;
