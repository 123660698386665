import { createContext, useMemo } from 'react';
import { classGroupUrls, curriculaUrls } from 'app/ClassGroupApp';

import useClassGroupContent from 'hooks/api/useClassGroupContent';
import useClassGroupPrograms from 'hooks/api/useClassGroupPrograms';
import useParams from 'hooks/useParams';

import publishStatuses from 'utils/constants/publishStatuses';
import viewModes from 'utils/constants/viewModes';

export const ContentContext = createContext({});

/*
 *
 */
export const classGroupContentHelpers = ({
  classGroupContent,
  classGroupId,
  contentType,
  contentId,
  reversedProgramIndex,
  defaultViewMode,
}) => {
  const viewMode =
    defaultViewMode ||
    classGroupContent?.publishStatus === publishStatuses.OPEN_FOR_CLASSGROUP
      ? viewModes.CLASSICAL
      : viewModes.SELFSTUDY;

  const url = `/classgroups/${classGroupId}/${classGroupUrls[contentType]}/${contentId}`;

  const viewUrl = (newViewMode, slideId) =>
    `${url}/view/${newViewMode || viewMode}${slideId ? `/${slideId}` : ''}`;

  const programSlug = reversedProgramIndex?.[classGroupContent?.programId];

  const programUrl = `/classgroups/${classGroupId}/${
    curriculaUrls[contentType]
  }${programSlug?.path ? `/${programSlug?.path}` : ''}`;

  return {
    classGroupId,
    contentId,
    contentType,
    viewMode,
    url,
    viewUrl,
    programUrl,
    resultsUrl: `${url}/results`,
    overviewUrl: `${url}/overview`,
    programId: classGroupContent?.programId,
    language: classGroupContent?.language,
  };
};

const ContentProvider = ({ contentType, children }) => {
  const { classGroupId, contentId, viewMode: queryViewMode } = useParams();

  const { reversedProgramIndex } = useClassGroupPrograms();

  const { classGroupContent } = useClassGroupContent({
    classGroupId,
    contentId,
    contentType,
  });

  const context = useMemo(() => {
    return classGroupContentHelpers({
      classGroupContent,
      classGroupId,
      contentType,
      contentId,
      reversedProgramIndex,
      queryViewMode,
    });
  }, [
    classGroupId,
    contentId,
    queryViewMode,
    contentType,
    classGroupContent,
    reversedProgramIndex,
  ]);

  return (
    <ContentContext.Provider value={context}>
      {children}
    </ContentContext.Provider>
  );
};

export default ContentProvider;
