import { GraphQLClient } from 'graphql-request';

import { getToken } from 'api';

import { AUTHORIZATION_TOKEN } from './constants/localStorage';

// Export the clients for synchronous use troughout the app.
export let portfolioServiceQueryClient;

// Get token on application load, so you don't have to get the token
// on every request.
export const initializeClients = async () => {
  const localStorageToken = window.localStorage[AUTHORIZATION_TOKEN];
  const msalToken = await getToken();
  const token = localStorageToken ? localStorageToken : msalToken;

  // PortfolioService Client
  portfolioServiceQueryClient = new GraphQLClient(
    process.env.REACT_APP_PORTFOLIO_SERVICE_URL,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    },
  );
};
