import { ErrorMessage } from '@ftrprf/tailwind-components';

import StatusElement from './partials/StatusElement';

const URLS = [
  {
    url: process.env.REACT_APP_EDU_HEALTH,
    name: 'EDU',
  },
  {
    url: process.env.REACT_APP_STUDIO_HEALTH,
    name: 'STUDIO',
  },
  {
    url: process.env.REACT_APP_PORTFOLIO_HEALTH,
    name: 'PORTFOLIO',
  },
  {
    url: process.env.REACT_APP_CODECOSMOS_HEALTH,
    name: 'CODECOSMOS',
  },
];

const Status = () => {
  return (
    <div className="flex justify-center">
      <div className="p-5 max-w-xl">
        <h1 className="text-2xl font-bold">Service status</h1>
        {URLS.map((endpoint) =>
          endpoint.url ? (
            <StatusElement
              className=""
              key={endpoint.name}
              url={endpoint.url}
              name={endpoint.name}
            />
          ) : (
            <ErrorMessage
              key={endpoint.name}
              title="No health endpoint configured"
              error="Health Url not configured"
              description={`Service: ${endpoint.name}`}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default Status;
