import { useEffect, useState } from 'react';
import { FcCancel, FcOk, FcSynchronize } from 'react-icons/fc';

const StatusElement = ({ url, name }) => {
  const [status, setStatus] = useState(null);
  useEffect(() => {
    const abortController = new AbortController();

    // Default fetch timeout is 5mins, => 5sec
    setTimeout(() => {
      abortController.abort();
      setStatus((prev) => (prev === null ? false : prev));
    }, 5000);

    const fetchHealth = async () => {
      try {
        const res = await fetch(url, { signal: abortController.signal });
        setStatus(res.ok);
      } catch {
        setStatus(false);
      }
    };

    fetchHealth();
  }, [url]);

  const getIcon = () => {
    const sz = 28;
    switch (status) {
      case true:
        return <FcOk size={sz} />;
      case false:
        return <FcCancel size={sz} />;
      default:
        return <FcSynchronize size={sz} />;
    }
  };

  const getBorderColor = () => {
    switch (status) {
      case true:
        return 'border-green-500';
      case false:
        return 'border-red-500';
      default:
        return 'border-orange-500';
    }
  };

  return (
    <div className={`flex p-3 my-5 rounded-md border-2 ${getBorderColor()}`}>
      <div className="container">
        <p className="font-semibold">{name}</p>
        <a href={url} className="text-sm hover:underline ">
          {url}
        </a>
      </div>
      <p className="">{getIcon()}</p>
    </div>
  );
};

export default StatusElement;
