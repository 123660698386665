export const removeLeadingSlash = (path) => {
  return path.startsWith('/') ? path.substring(1) : path;
};

export const removeTrailingSlash = (path) => {
  return path.endsWith('/') ? path.substring(0, path.length - 1) : path;
};

export const removeLeadingAndTrailingSlash = (path = '') => {
  return removeLeadingSlash(removeTrailingSlash(path));
};
