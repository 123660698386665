import { useQuery } from 'react-query';

import contentTypes from 'utils/constants/contentTypes';

export const classGroupProgramURL = (classGroupProgramId) =>
  `${process.env.REACT_APP_API_URL}/classGroupPrograms/${classGroupProgramId}`;

const mapContentContainer = (classGroupContent) => ({
  ...classGroupContent,
  content: classGroupContent.contentContainer,
});

const sortContent = (a, z) => {
  const x = (a.content.sequence || 0) - (z.content.sequence || 0);

  // sort alfabetical when sequences are undefined
  // or when the sequences are equal
  return x === 0 ? a.content.title.localeCompare(z.content.title) : x;
};

const useClassGroupContentList = (classGroupProgramId, contentType) => {
  const response = useQuery(classGroupProgramURL(classGroupProgramId));

  return {
    classGroupContentList:
      contentType === contentTypes.EXAM
        ? response.data?.classGroupExams
            ?.map(mapContentContainer)
            .sort(sortContent)
        : response.data?.classGroupLessons
            ?.map(mapContentContainer)
            .sort(sortContent),
    isLoading: response.isLoading,
  };
};

export default useClassGroupContentList;
