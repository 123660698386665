import { useContext, useState } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import confetti from 'canvas-confetti';

import {
  ErrorMessage,
  FilledButton,
  NotificationContext,
  SlideViewerContext,
  SuccessMessage,
  VIEW_MODES,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

const HARD_SUBMIT_SLIDE_ID = 'hard-submit-slide';

const HardSubmitSlide = ({ onSubmit }) => {
  const t = useFormatMessage();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { showConfetti, canEdit } = useContext(SlideViewerContext);
  const { addNotification } = useContext(NotificationContext);

  const onSubmitHandler = () => {
    setLoading(true);
    setError(false);

    onSubmit()
      .then(() => {
        setLoading(false);
        setSuccess(true);

        addNotification({
          type: 'success',
          content: t('content-slide-viewer.hard-submit-slide.success'),
        });

        if (showConfetti) {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
            startVelocity: 60,
            ticks: 70,
            gravity: 2,
          });
        }
      })
      .catch((err) => {
        addNotification({
          type: 'error',
          content: t('content-slide-viewer.hard-submit-slide.error'),
        });

        setError(err);
        setLoading(false);
      });
  };

  const isSubmitted = success || !canEdit;

  return (
    <div>
      <ErrorMessage
        error={error}
        description={t('content-slide-viewer.hard-submit-slide.error')}
      />
      {isSubmitted && (
        <SuccessMessage
          description={t('content-slide-viewer.hard-submit-slide.success')}
        />
      )}
      {!isSubmitted && (
        <>
          <p className="max-w-screen-sm">
            {t('content-slide-viewer.hard-submit-slide.description')}
          </p>
          <FilledButton
            iconBefore={FaPaperPlane}
            onClick={onSubmitHandler}
            disabled={loading || !canEdit}
          >
            {canEdit
              ? t('content-slide-viewer.hard-submit-slide.submit')
              : t('content-slide-viewer.hard-submit-slide.submitted')}
          </FilledButton>
        </>
      )}
    </div>
  );
};

export const createHardSubmitSlide = (t, onSubmit, canEdit) => {
  const HardSubmitSlideWrapper = () => {
    return <HardSubmitSlide onSubmit={onSubmit} canEdit={canEdit} />;
  };

  return {
    id: HARD_SUBMIT_SLIDE_ID,
    title: t('content-slide-viewer.hard-submit-slide.title'),
    component: HardSubmitSlideWrapper,
    sequence: Infinity,
    viewModes: Object.values(VIEW_MODES),
  };
};
