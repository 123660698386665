import '@ftrprf/tailwind-components/slideviewer-styles.css';

import { useCallback, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import {
  SlideViewerCloseButton,
  SlideViewerContextProvider,
  StyledSlideViewer,
  VIEW_MODES,
  ViewModeButton,
} from '@ftrprf/tailwind-components';

import getExerciseInfo from 'hooks/api/exercise/getExerciseInfo';
import useStudioContentSlides from 'hooks/api/UseStudioContentSlides';
import useCurrentContent from 'hooks/useCurrentContent';
import useDebounce from 'hooks/useDebounce';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import contentTypes from 'utils/constants/contentTypes';
import urls from 'utils/constants/urls';

import { ReactComponent as Loader } from '../../assets/vectors/logo-animated-lines.svg';

import { createHardSubmitSlide } from './partials/HardSubmitSlide';

const EmptyContent = ({ currentViewMode, slideId, onClose, canClose }) => {
  const t = useFormatMessage();

  const history = useHistory();
  const { viewUrl } = useCurrentContent();

  const otherViewModes = Object.values(VIEW_MODES).filter(
    (viewMode) => viewMode !== currentViewMode,
  );

  return (
    <div className="w-full h-full flex flex-col items-center gap-y-2 justify-center relative">
      {canClose && (
        <div className="absolute top-5 right-5">
          <SlideViewerCloseButton onClose={onClose} />
        </div>
      )}

      <span className="font-bold text-2xl">
        {`${t('content-slide-viewer.no-slides')}!`}
      </span>
      <span className="text-gray-700">
        {t('content-slide-viewer.no-slides.description')}
      </span>

      <div className="flex gap-x-4 mt-4">
        {otherViewModes.map((viewMode) => (
          <div className="w-10 h-10" key={viewMode}>
            <ViewModeButton
              onClick={() => history.push(viewUrl(viewMode, slideId))}
              viewMode={viewMode}
              title={t(`view-modes.${viewMode.toLowerCase()}`)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const CodeCosmosSlideViewer = ({
  canEdit,
  contentType,
  submittedQuestionAnswers,
  temporaryQuestionAnswers,
  showViewModes,
  showTeacherInfoTab,
  showSettingsTab,
  showSlidesOverviewTab,
  showSteamsTab,
  areAnswersSaved,
  generateFeedbackURL,
  onSubmitQuestionAnswers,
  onTemporaryQuestionAnswers,
  onWarning,
  onHardSubmit,
  EmptyContent,
}) => {
  const t = useFormatMessage();
  const history = useHistory();

  const { contentId, viewMode, slideId } = useParams();
  const { lessonId, viewmode } = useParams();
  const { viewUrl, classGroupId, language } = useCurrentContent();

  const generateScratchExercisePath = async ({ versionId }) => {
    const res = await getExerciseInfo(lessonId);

    return generatePath(urls.EXERCISE_URLS_START, {
      startId: versionId,
      language: res.language,
    });
  };

  const { contentDetails, isLoading: isContentLoading } =
    useStudioContentSlides({
      studioID: lessonId,
      viewMode: viewmode,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSlideChange = useCallback(
    useDebounce((newSlide) => {
      if (slideId !== undefined) {
        if (`${newSlide.id}` !== `${slideId}` && classGroupId !== undefined) {
          history.push(viewUrl(viewMode, newSlide.id));
        }
      }
    }, 70),
    [viewUrl, history, slideId, viewMode],
  );

  const onViewModeChange = useCallback(
    (newViewMode, { currentSlide }) => {
      if (newViewMode !== viewMode && !contentDetails) {
        history.push(viewUrl(newViewMode, currentSlide.id));
      }
    },
    [viewMode, contentDetails, history, viewUrl],
  );

  // eslint-disable-next-line consistent-return
  const slides = useMemo(() => {
    if (!contentDetails?.slides) {
      return [];
    } else if (contentDetails?.slides) {
      return contentType === contentTypes.EXAM
        ? [...contentDetails.slides, createHardSubmitSlide(t, onHardSubmit)]
        : contentDetails?.slides;
    }
  }, [contentDetails?.slides, contentType, t, onHardSubmit]);

  if (isContentLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader className="w-32" />
      </div>
    );
  }

  if (!isContentLoading && contentDetails?.slides?.length === 0) {
    return (
      <EmptyContent
        contentType={contentType}
        currentViewMode={viewMode}
        contentId={contentId}
        slideId={slideId}
      />
    );
  }

  const defaultSlide = slides.find((slide) => `${slide.id}` === `${slideId}`);
  const defaultSlideId = defaultSlide?.id || slides[0].id;

  return (
    <SlideViewerContextProvider
      showConfetti={true}
      canEdit={canEdit}
      canOverrideAnswers={contentType === contentTypes.EXAM}
      slides={slides}
      defaultSlideId={defaultSlideId}
      onSlideChange={onSlideChange}
      onViewModeChange={onViewModeChange}
      temporaryQuestionAnswers={temporaryQuestionAnswers}
      submittedQuestionAnswers={submittedQuestionAnswers}
      onSubmitQuestionAnswers={onSubmitQuestionAnswers}
      onTemporaryQuestionAnswers={onTemporaryQuestionAnswers}
      onWarning={onWarning}
      generateScratchExercisePath={(rest) =>
        generateScratchExercisePath({ ...rest, language })
      }
      canClose={false}
    >
      <StyledSlideViewer
        canClose={false}
        showViewModes={showViewModes}
        showTeacherInfoTab={showTeacherInfoTab}
        showSettingsTab={showSettingsTab}
        showSteamsTab={showSteamsTab}
        showSlidesOverviewTab={showSlidesOverviewTab}
        areAnswersSaved={areAnswersSaved}
        generateFeedbackURL={generateFeedbackURL}
      />
    </SlideViewerContextProvider>
  );
};

CodeCosmosSlideViewer.defaultProps = {
  submittedQuestionAnswers: [],
  temporaryAnswers: {},
  showViewModes: true,
  showSettingsTab: true,
  showSlidesOverviewTab: true,
  areAnswersSaved: false,
  canClose: false,
  showTeacherInfoTab: true,
  EmptyContent: EmptyContent,
  generateFeedbackURL: () => {},
  onTemporaryQuestionAnswers: () => {},
  onSubmitQuestionAnswers: () => {},
  generateScratchExercisePath: () => {},
};

export default CodeCosmosSlideViewer;
