import { useMutation } from 'react-query';

import { post } from 'api/index';

const URL = ({ classGroupId, contentId, contentType }) =>
  `${process.env.REACT_APP_API_URL}/classGroups/${classGroupId}/content/${contentType}/${contentId}/submit`;

const useContentSubmitMutation = ({ classGroupId, contentId, contentType }) => {
  const url = URL({ classGroupId, contentId, contentType });

  return useMutation(() => post(url));
};

export default useContentSubmitMutation;
