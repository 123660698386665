import { FaCheck, FaTimes } from 'react-icons/fa';

import { Button, Input, InputGroup, Label } from '@ftrprf/tailwind-components';

import useScore from 'hooks/api/useScore';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import c from '../../../utils/c';
import getComponentStyle from 'utils/getComponentStyle';

const feedbackButtonStyles = {
  GRAY: 'bg-gray-300 text-gray-500',
  GREEN: 'bg-green-100 text-green-500',
  RED: 'bg-red-100 text-red-500',
};

const FeedbackButton = ({ children, gray, green, red, ...props }) => {
  const key = getComponentStyle(
    feedbackButtonStyles,
    { gray, green, red },
    'GRAY',
  );

  const className = feedbackButtonStyles[key];

  return (
    <Button
      disabledClassName="cursor-default"
      className={c(
        'w-8 h-8 flex justify-center items-center text-sm rounded-full',
        className,
      )}
      {...props}
    >
      {children}
    </Button>
  );
};

const StudentAnswersOpenResult = ({
  contentType,
  answer,
  question,
  canEdit,
}) => {
  const t = useFormatMessage();

  const { classGroupId, contentId, studentId } = useParams();

  const { publishScore } = useScore({
    contentId,
    contentType,
    classGroupId,
    studentId,
    questionId: question.id,
  });

  if (!answer) {
    return <div>{t('question-result.no-result')}</div>;
  }

  const isGraded = answer.score !== null;

  return (
    <div className="flex flex-col">
      <InputGroup>
        <Label htmlFor="answer">{t('student-answers.given_answer')}</Label>
        <Input
          type="multiline"
          value={answer.answers?.[0]?.value}
          disabled
          id="answer"
        />
      </InputGroup>
      {question.solution && (
        <InputGroup>
          <Label htmlFor="solution">
            {t('student-answers.example_solution')}
          </Label>
          <Input
            type="multiline"
            value={question.solution}
            disabled
            id="solution"
          />
        </InputGroup>
      )}
      <div className="flex">
        <div>
          <FeedbackButton
            disabled={!canEdit}
            green={isGraded && answer.score > 0}
            onClick={() => {
              if (!isGraded || answer.score <= 0) {
                publishScore(question.scorePerCorrectAnswer);
              }
            }}
          >
            <FaCheck />
          </FeedbackButton>
        </div>
        <div className="ml-2">
          <FeedbackButton
            disabled={!canEdit}
            red={isGraded && answer.score === 0}
            onClick={() => {
              if (!isGraded || answer.score !== 0) {
                publishScore(0);
              }
            }}
          >
            <FaTimes />
          </FeedbackButton>
        </div>
      </div>
    </div>
  );
};

export default StudentAnswersOpenResult;
