import { useQuery } from 'react-query';

export const URL = ({ contentId, contentType, viewMode }) =>
  `${process.env.REACT_APP_API_URL}/content/${contentType}/${contentId}${
    viewMode ? `?viewMode=${viewMode}` : ''
  }`;

const useContent = ({ contentId, contentType, viewMode }) => {
  const response = useQuery(URL({ contentId, contentType, viewMode }));

  return { contentDetails: response.data, ...response };
};

export default useContent;
