import {
  ChromeInfoBarTranslations,
  DemoInfoBarTranslations,
  DialogTranslations,
  MessageTranslations,
  NotificationTranslations,
  PasswordStrengthBarTranslations,
  StyledSlideViewerTranslations
} from '@ftrprf/tailwind-components';

// Add external translations in the array below
const externalTranslations = [
  ChromeInfoBarTranslations,
  DemoInfoBarTranslations,
  DialogTranslations,
  NotificationTranslations,
  PasswordStrengthBarTranslations,
  StyledSlideViewerTranslations,
  MessageTranslations
];

export const externalEn = Object.assign(
  {},
  ...externalTranslations.flatMap((tr) => tr.en),
);

export const externalNl = Object.assign(
  {},
  ...externalTranslations.flatMap((tr) => tr.nl),
);
