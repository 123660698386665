import useClassGroupContentStudents from 'hooks/api/useClassGroupContentStudents';
import { TEACHERURL as CONTENT_ANSWERS_URL } from 'hooks/api/useContentAnswers';
import { TEACHERURL as FEEDBACK_URL } from 'hooks/api/useFeedbacks';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';
import useParams from 'hooks/useParams';

import StudentAnswers from './StudentAnswers';

const TeacherContainer = ({ contentType, user }) => {
  const { id: classGroupId } = useCurrentClassGroup();
  const { contentId, studentId, viewMode, version } = useParams();

  const classGroupContentStudents = useClassGroupContentStudents({
    classGroupId,
    contentId,
    contentType,
    viewMode,
    version,
  });

  const data = {
    classGroupContentStudents,
    feedbackEndpoint: FEEDBACK_URL({
      classGroupId,
      contentId,
      contentType,
      studentId,
    }),
    contentAnswersEndpoint: CONTENT_ANSWERS_URL,
  };

  return (
    <StudentAnswers
      data={data}
      user={user}
      contentType={contentType}
      classGroupId={classGroupId}
      contentId={contentId}
      studentId={studentId}
      viewMode={viewMode}
      version={version}
      canEdit={true}
    />
  );
};

export default TeacherContainer;
