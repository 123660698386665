import { useMemo, useState } from 'react';
import {
  FaArrowLeft,
  FaFilter,
  FaPlus,
  FaSearch,
  FaTimes,
} from 'react-icons/fa';
import { generatePath, Link, useHistory } from 'react-router-dom';

import {
  Badge,
  Button,
  Content,
  ContentCard,
  FilledButton,
  Input,
  OutlineButton,
  PageHeader,
} from '@ftrprf/tailwind-components';

import PageTitle from 'components/PageTitle';

import useFormatMessage from 'hooks/useFormatMessage';
import useTitle from 'hooks/useTitle';

import urls from 'utils/constants/urls';

import { ReactComponent as EmptyImage } from '../../assets/vectors/empty-image.svg';

import ContentCardContextMenu from './partials/ContentCardContextMenu';
import { ContentCardListSkeleton } from './partials/ContentCardList';

const ExercisesOverview = ({
  exercises,
  isLoading,
  isError,
  title,
  hasCreateExercise,
  canRemoveExercise,
  removeExercise,
}) => {
  const t = useFormatMessage();
  const history = useHistory();

  const pageTitle = title || t('exercises-overview.my-exercises.title');

  const [isShowingFilter, setIsShowingFilter] = useState(false);
  const [filterTitle, setFilterTitle] = useState('');

  useTitle(pageTitle);

  const isSearching = filterTitle !== '';
  const filteredExercises = useMemo(
    () =>
      exercises.filter((exercise) =>
        exercise.title.toLowerCase().includes(filterTitle.toLowerCase()),
      ),
    [filterTitle, exercises],
  );

  if (isError) {
    return <span className="mt-8">{t('exercises-overview.error')}</span>;
  }

  if (!isLoading && !isError && exercises.length === 0) {
    return (
      <div className="flex flex-col justify-center space-y-2">
        <span className="mt-8 text-center">
          {t('exercises-overview.no_exercises')}
        </span>
        {hasCreateExercise && (
          <Link to={urls.EXERCISE_URLS_NEW} target="_blank">
            <FilledButton iconBefore={FaPlus} className="mr-2">
              {t('exercises-overview.create')}
            </FilledButton>
          </Link>
        )}
      </div>
    );
  }

  return (
    <>
      <PageHeader>
        <div className="flex flex-col md:flex-row justify-between space-y-2">
          <PageTitle>{pageTitle}</PageTitle>
          <div className="flex flex-row">
            {hasCreateExercise && (
              <Link to={urls.EXERCISE_URLS_NEW} target="_blank">
                <FilledButton iconBefore={FaPlus} className="mr-2">
                  {t('exercises-overview.create')}
                </FilledButton>
              </Link>
            )}
            {!isShowingFilter ? (
              <FilledButton
                iconBefore={FaFilter}
                onClick={() => setIsShowingFilter((prev) => !prev)}
              >
                {t('exercises-overview.toggle_filter')}
              </FilledButton>
            ) : (
              <OutlineButton
                iconBefore={FaFilter}
                onClick={() => setIsShowingFilter((prev) => !prev)}
              >
                {t('exercises-overview.toggle_filter')}
              </OutlineButton>
            )}
          </div>
        </div>
        {isShowingFilter && (
          <div className="flex flex-row-reverse mt-2">
            <div className="relative flex-grow min-w-56 max-w-md float-right">
              <Input
                value={filterTitle}
                placeholder={t('exercises-overview.filter_placeholder')}
                onChange={(e) => setFilterTitle(e.target.value)}
              />
              <div className="absolute h-full top-0 flex items-center right-3 text-gray-500 text-sm">
                {isSearching ? (
                  <Button onClick={() => isSearching && setFilterTitle('')}>
                    <FaTimes />
                  </Button>
                ) : (
                  <FaSearch />
                )}
              </div>
            </div>
          </div>
        )}
      </PageHeader>
      <Content>
        <div className="flex flex-col w-full">
          <div className="mb-2">
            <FilledButton
              onClick={() => history.goBack()}
              secondary
              iconBefore={FaArrowLeft}
            >
              {t('global.go_back')}
            </FilledButton>
          </div>

          {filteredExercises.length === 0 ? (
            <div className="flex justify-center w-full h-full items-center">
              <p>{t('global.no_results')}</p>
            </div>
          ) : (
            <ContentCardListSkeleton isLoading={isLoading}>
              {filteredExercises.map((e) => {
                const editURL = generatePath(urls.EXERCISE_URLS_ID, {
                  exerciseId: e.id,
                });

                return (
                  <ContentCardContextMenu
                    key={e.id}
                    editURL={editURL}
                    hasRemoveExercise={canRemoveExercise}
                    onDelete={() => removeExercise(e.id)}
                  >
                    {({ toggle }) => (
                      <Link to={editURL}>
                        <ContentCard
                          toggleContextMenu={toggle}
                          disabled={e.archived}
                          version={
                            e.exerciseVersionType && (
                              <Badge
                                className="px-1 text-xs w-auto inline-block"
                                info
                              >
                                {t(`scratch.version.${e.exerciseVersionType}`)}
                              </Badge>
                            )
                          }
                          title={e.title}
                          image={e.imageUri}
                          defaultImage={EmptyImage}
                        />
                      </Link>
                    )}
                  </ContentCardContextMenu>
                );
              })}
            </ContentCardListSkeleton>
          )}
        </div>
      </Content>
    </>
  );
};

ExercisesOverview.defaultProps = {
  exercises: [],
  hasCreateExercise: true,
  title: undefined,
  canRemoveExercise: true,
  removeExercise: () => {},
};

export default ExercisesOverview;
