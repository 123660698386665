import { Content } from '@ftrprf/tailwind-components';

const ContentDetailsSkeleton = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <Content>
        <div className="w-full max-w-lg">
          <div className="bg-gray-300 h-5 w-20 animate-pulse rounded" />
          <div className="bg-gray-300 h-12 animate-pulse rounded mt-3" />
          <div className="bg-gray-300 h-12 animate-pulse rounded mt-3" />
          <div className="bg-gray-300 h-12 animate-pulse rounded mt-3" />
        </div>
      </Content>
    );
  }

  return children;
};

export default ContentDetailsSkeleton;
