import { useQuery } from 'react-query';

import { get } from 'api/index';

export const URL = ({ userId }) =>
  `${process.env.REACT_APP_API_URL}/users/${userId}`;

const useUser = (userId) => {
  const response = useQuery(
    URL({ userId }),
    ({ queryKey }) => get(queryKey[0]),
    { enabled: !!userId },
  );

  return {
    user: response?.data,
    ...response,
  };
};

export default useUser;
