import { useQuery } from 'react-query';

export const URL = ({ studioID, viewMode }) =>
  `${process.env.REACT_APP_API_URL}/content/${studioID}/slides${
    viewMode ? `?viewMode=${viewMode}` : ''
  }`;
const useStudioContentSlides = ({ studioID, viewMode }) => {
  const response = useQuery(URL({ studioID, viewMode }));

  return { contentDetails: response.data, ...response };
};

export default useStudioContentSlides;
