import { Link } from 'react-router-dom';

import { Badge, ContentCard } from '@ftrprf/tailwind-components';

import { classGroupContentHelpers } from 'providers/ContentProvider';

import useDocument from 'hooks/api/useDocument';

import c from 'utils/c';
import contentTypes from 'utils/constants/contentTypes';
import viewModes from 'utils/constants/viewModes';

import { ReactComponent as EmptyImage } from 'assets/vectors/empty-image.svg';

const HackRoomLessonCard = ({
  className,
  hackRoomLesson,
  classGroupId,
  isFeatured,
}) => {
  const download = useDocument();

  const { viewUrl } = classGroupContentHelpers({
    classGroupId,
    contentType: contentTypes.LESSON,
    contentId: hackRoomLesson.id,
  });

  return (
    <div className={c(className)} key={hackRoomLesson.id}>
      <Link to={viewUrl(viewModes.CLASSICAL)}>
        <ContentCard
          defaultImage={EmptyImage}
          title={hackRoomLesson?.title}
          image={hackRoomLesson.imageUrl}
          onDocument={
            hackRoomLesson.document
              ? () => download(hackRoomLesson.document)
              : undefined
          }
          isFeatured={isFeatured}
        >
          {hackRoomLesson.categories ? (
            hackRoomLesson.categories?.map((category) => (
              <Badge
                className="py-2 px-3 rounded-full mb-2 mr-2"
                info
                key={category.id}
              >
                {category.name}
              </Badge>
            ))
          ) : (
            <div className="h-3" />
          )}
        </ContentCard>
      </Link>
    </div>
  );
};

export default HackRoomLessonCard;
