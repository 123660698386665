import { useQuery } from 'react-query';

import viewModes from 'utils/constants/viewModes';

export const URL = ({ contentId, contentType, viewMode }) =>
  `${process.env.REACT_APP_API_URL}/content/${contentType}/${contentId}/versions?viewMode=${viewMode}`;

const useContentVersions = ({ contentId, contentType, viewMode }) => {
  const response = useQuery(URL({ contentId, contentType, viewMode }));

  const versionsArray = response.data
    ?.sort((a, b) => b.version - a.version)
    .map((versionObject) => [versionObject.version, versionObject]);

  const latestVersion = versionsArray?.length > 0 && versionsArray[0][1];

  const versionMap = response.data ? Object.fromEntries(versionsArray) : {};

  return { versions: response.data, versionMap, latestVersion, ...response };
};

useContentVersions.defaultProps = {
  viewMode: viewModes.CLASSICAL,
};

export default useContentVersions;
