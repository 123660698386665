import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import {
  BorderedList,
  Content,
  FilledButton,
  InputGroup,
  Label,
  RadioButton,
} from '@ftrprf/tailwind-components';

import useClassGroupContent from 'hooks/api/useClassGroupContent';
import useCurrentClassGroup from 'hooks/useCurrentClassGroup';
import useCurrentContent from 'hooks/useCurrentContent';
import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import contentTypes from 'utils/constants/contentTypes';
import publishStatuses from 'utils/constants/publishStatuses';

import ContentDetailsSkeleton from './partials/ContentDetailsSkeleton';

const StatusRadioButton = ({ selectedValue, setPublishStatus, value }) => {
  const t = useFormatMessage();

  return (
    <div className="py-2 px-4">
      <RadioButton
        name="status"
        value={value}
        checked={value === selectedValue}
        onChange={(e) => {
          setPublishStatus(e.target.value);
        }}
      >
        <div>
          <p className="leading-snug">{t(`publish-statuses.${value}`)}</p>
          <p className="text-xs text-gray-600">
            {t(`publish-statuses.explanation.${value}`)}
          </p>
        </div>
      </RadioButton>
    </div>
  );
};

const ContentDetails = ({ contentType }) => {
  const t = useFormatMessage();
  const { programUrl } = useCurrentContent();
  const { contentId } = useParams();
  const { id: classGroupId } = useCurrentClassGroup();

  const {
    classGroupContent,
    isLoading: classGroupContentLoading,
    setPublishStatus,
  } = useClassGroupContent({ classGroupId, contentId, contentType });

  return (
    <ContentDetailsSkeleton isLoading={classGroupContentLoading}>
      <Content>
        <div>
          <div className="mb-4">
            <Link to={programUrl}>
              <FilledButton secondary iconBefore={FaArrowLeft}>
                {t('global.go_back')}
              </FilledButton>
            </Link>
          </div>
          <InputGroup className="w-full max-w-lg">
            <Label>{t('content-details.status')}</Label>
            <BorderedList>
              <StatusRadioButton
                selectedValue={classGroupContent?.publishStatus}
                setPublishStatus={setPublishStatus}
                value={publishStatuses.OPEN_FOR_CLASSGROUP}
              />
              {contentType === contentTypes.LESSON && (
                <StatusRadioButton
                  selectedValue={classGroupContent?.publishStatus}
                  setPublishStatus={setPublishStatus}
                  value={publishStatuses.OPEN_FOR_HOME}
                />
              )}
              <StatusRadioButton
                selectedValue={classGroupContent?.publishStatus}
                setPublishStatus={setPublishStatus}
                value={publishStatuses.CLOSED}
              />
            </BorderedList>
          </InputGroup>
        </div>
      </Content>
    </ContentDetailsSkeleton>
  );
};

export default ContentDetails;
