import { useQuery } from 'react-query';

import versionTypes from 'utils/constants/versionTypes';
import { sortByLastNameAndFirstName } from 'utils/sort';

export const URL = ({ classGroupId, contentId, contentType, version }) =>
  `${process.env.REACT_APP_API_URL}/classGroups/${classGroupId}/content/${contentType}/${contentId}/versions/${version}/students`;

const useClassGroupContentStudents = ({
  classGroupId,
  contentId,
  contentType,
  version = versionTypes.LATEST,
}) => {
  const response = useQuery(
    URL({ classGroupId, contentId, contentType, version }),
  );

  return {
    classGroupContentStudents:
      response.data && sortByLastNameAndFirstName(response.data),
    ...response,
  };
};

export default useClassGroupContentStudents;
