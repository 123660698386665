import { useMutation } from 'react-query';

import { CREATE_SCRATCH_EXERCISE } from '../../../api/portfolioService';

import { portfolioServiceQueryClient } from 'utils/graphqlQueryClients';

const useCreateScratchExercise = () => {
  const { mutate, isLoading, error } = useMutation(
    ({ exerciseVersionId, exerciseId, file }) => {
      const client = portfolioServiceQueryClient;
      return client.request(CREATE_SCRATCH_EXERCISE, {
        exerciseVersionId,
        exerciseId,
        file,
      });
    },
    {
      mutationKey: 'createScratchExercise',
    },
  );

  return { mutate, isLoading, error };
};

export default useCreateScratchExercise;
