/* eslint-disable react/display-name */
import { BsThreeDotsVertical } from 'react-icons/bs';

import { Badge, Button } from '@ftrprf/tailwind-components';

import Avatar from 'components/Avatar';

import RowContextMenu from './partials/RowContextMenu';

const columns = ({
  canDelete,
  t,
  formatDate,
  openRemoveModal,
  openResetModal,
}) => [
  {
    Header: t('student-overview.column.name'),
    Cell: ({ row }) => {
      const { firstName, lastName, username } = row.original;

      return (
        <div className="flex gap-x-4 items-center" title={username}>
          <Avatar
            className="w-10 h-10 flex-shrink-0 text-white"
            firstName={firstName}
            lastName={lastName}
          />
          {`${firstName} ${lastName}`}
        </div>
      );
    },
  },
  {
    Header: t('student-overview.column.username'),
    Cell: ({ row }) => {
      const { username } = row.original;

      return <div className="flex gap-x-4 items-center">{username}</div>;
    },
  },
  {
    Header: t('student-overview.column.last-login'),
    Cell: ({ row }) => {
      const { lastLogin } = row.original;

      return (
        <div className="flex gap-x-4 items-center">{formatDate(lastLogin)}</div>
      );
    },
  },
  {
    Header: t('student-overview.column.class'),
    Cell: ({ row }) => {
      const classBadges = row.original.classGroups.map((classGroup, index) => {
        return (
          <Badge info key={index} className="w-auto inline-block py-1 px-2 m-1">
            {classGroup.name}
          </Badge>
        );
      });
      return classBadges;
    },
  },
  {
    Header: ' ',
    Cell: ({ row }) => {
      return (
        <RowContextMenu
          canDelete={canDelete}
          userId={row.original.id}
          openRemoveModal={openRemoveModal}
          openResetModal={openResetModal}
        >
          {({ toggle }) => (
            <Button
              className="hover:bg-gray-400 rounded-lg p-2"
              onClick={(e) => {
                toggle(e);
              }}
            >
              <BsThreeDotsVertical />
            </Button>
          )}
        </RowContextMenu>
      );
    },
  },
];

export default columns;
