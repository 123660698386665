import { memo } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import Content from 'pages/Content/Content';
import ExamContentContainer from 'pages/Content/ExamContainer';
import ContentSlideViewer from 'pages/ContentSlideViewer/ContentSlideViewer';
import StudentContentSlideViewer from 'pages/ContentSlideViewer/StudentContainer';
import TeacherContentSlideViewer from 'pages/ContentSlideViewer/TeacherContainer';
import CurriculaContainer from 'pages/Curricula/CurriculaContainer';
import ExamCurriculaContainer from 'pages/Curricula/ExamContainer';
import TeacherExerciseOverviewContainer from 'pages/ExercisesOverview/TeacherContanier';
import HackRoomContainer from 'pages/HackRoom/HackRoomContainer';
import StudentStudentAnswers from 'pages/StudentAnswers/StudentContainer';
import TeacherStudentAnswers from 'pages/StudentAnswers/TeacherContainer';
import SchoolAdminStudentOverviewContainer from 'pages/StudentOverview/SchoolAdminContainer';
import StudentOverviewContainer from 'pages/StudentOverview/StudentOverviewContainer';
import SchoolAdminContainer from 'pages/StudentProfile/SchoolAdminContainer';
import StudentProfileContainer from 'pages/StudentProfile/StudentProfileContainer';

import ContentProvider from 'providers/ContentProvider';

import contentTypes from 'utils/constants/contentTypes';

import Route from './Route';

export const curriculaUrls = {
  [contentTypes.LESSON]: 'curricula-lessons',
  [contentTypes.EXAM]: 'curricula-exams',
};

export const classGroupUrls = {
  [contentTypes.LESSON]: 'lessons',
  [contentTypes.EXAM]: 'exams',
  STUDENT_OVERVIEW: 'students',
  STUDENT_PROFILE: 'students/:studentId',
  STUDENT_EXERCISE_OVERVIEW: 'students/:studentId/exercises',
  HACKROOM: 'hackroom*',
};

const classGroupContentUrls = {
  CONTENT_OVERVIEW: '',
  STUDENT_RESULTS_OVERVIEW: 'results',
  STUDENT_ANSWERS: 'results/:studentId',
  CONTENT_SLIDEVIEWER: 'view/:viewMode?/:slideId?',
};

const ClassGroupContentApp = ({ user, contentType }) => {
  const { path } = useRouteMatch();
  return (
    <ContentProvider contentType={contentType}>
      <Switch>
        {user.isCodeCosmosStudent && (
          <Route
            exact
            path={`${path}/${classGroupContentUrls.STUDENT_RESULTS_OVERVIEW}`}
            component={StudentStudentAnswers}
            user={user}
            contentType={contentType}
          />
        )}

        {user.isCodeCosmosTeacherOrAdmin && (
          <Route
            exact
            path={`${path}/${classGroupContentUrls.STUDENT_ANSWERS}`}
            component={TeacherStudentAnswers}
            user={user}
            contentType={contentType}
          />
        )}

        {user.isCodeCosmosStudent && (
          <Route
            exact
            path={`${path}/${classGroupContentUrls.CONTENT_SLIDEVIEWER}`}
            component={StudentContentSlideViewer}
            user={user}
            contentType={contentType}
          />
        )}

        {user.isCodeCosmosTeacherOrAdmin && (
          <Route
            exact
            path={`${path}/${classGroupContentUrls.CONTENT_SLIDEVIEWER}`}
            component={TeacherContentSlideViewer}
            user={user}
            contentType={contentType}
          />
        )}

        <Route
          exact
          path={`${path}/${classGroupContentUrls.CONTENT_SLIDEVIEWER}`}
          component={ContentSlideViewer}
          user={user}
          contentType={contentType}
        />

        {user.isCodeCosmosTeacherOrAdmin &&
          contentType === contentTypes.LESSON && (
            <Route
              exact
              path={`${path}/:active`}
              component={Content}
              user={user}
              contentType={contentType}
            />
          )}

        {user.isCodeCosmosTeacherOrAdmin &&
          contentType === contentTypes.EXAM && (
            <Route
              exact
              path={`${path}/:active`}
              component={ExamContentContainer}
              user={user}
              contentType={contentType}
            />
          )}
      </Switch>
    </ContentProvider>
  );
};

const ClassGroupApp = ({ user }) => {
  const { url, path } = useRouteMatch();

  return (
    <Switch>
      {user.isSchoolAdmin && (
        <Route
          exact
          path={`${path}/${classGroupUrls.STUDENT_OVERVIEW}`}
          component={SchoolAdminStudentOverviewContainer}
        />
      )}

      {user.isCodeCosmosTeacherOrAdmin && (
        <Route
          exact
          path={`${path}/${classGroupUrls.STUDENT_OVERVIEW}`}
          component={StudentOverviewContainer}
        />
      )}

      {user.isSchoolAdmin && (
        <Route
          exact
          path={`${path}/${classGroupUrls.STUDENT_PROFILE}`}
          component={SchoolAdminContainer}
        />
      )}

      {user.isCodeCosmosTeacherOrAdmin && (
        <Route
          exact
          path={`${path}/${classGroupUrls.STUDENT_PROFILE}`}
          component={StudentProfileContainer}
        />
      )}

      {(user.isCodeCosmosStudent || user.isCodeCosmosTeacherOrAdmin) && (
        <Route
          path={`${path}/${classGroupUrls.LESSON}/:contentId`}
          component={ClassGroupContentApp}
          user={user}
          contentType={contentTypes.LESSON}
        />
      )}

      {(user.isCodeCosmosStudent || user.isCodeCosmosTeacherOrAdmin) && (
        <Route
          path={`${path}/${classGroupUrls.EXAM}/:contentId`}
          component={ClassGroupContentApp}
          user={user}
          contentType={contentTypes.EXAM}
        />
      )}

      {(user.isCodeCosmosStudent || user.isCodeCosmosTeacherOrAdmin) && (
        <Route
          path={`${path}/${curriculaUrls.LESSON}*`}
          component={CurriculaContainer}
          user={user}
          contentType={contentTypes.LESSON}
        />
      )}

      {(user.isCodeCosmosStudent || user.isCodeCosmosTeacherOrAdmin) && (
        <Route
          path={`${path}/${curriculaUrls.EXAM}*`}
          component={ExamCurriculaContainer}
          user={user}
          contentType={contentTypes.EXAM}
        />
      )}

      {(user.isCodeCosmosStudent || user.isCodeCosmosTeacherOrAdmin) && (
        <Route
          path={`${path}/${classGroupUrls.HACKROOM}`}
          component={HackRoomContainer}
          user={user}
        />
      )}

      {user.isCodeCosmosTeacherOrAdmin && (
        <Route
          path={`${path}/${classGroupUrls.STUDENT_EXERCISE_OVERVIEW}`}
          component={TeacherExerciseOverviewContainer}
          user={user}
        />
      )}

      <Redirect exact from={url} to={`${url}/${curriculaUrls.LESSON}`} />
    </Switch>
  );
};

export default memo(ClassGroupApp);
