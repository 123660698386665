import { Fragment } from 'react';
import { Route as RRDRoute } from 'react-router-dom';

const Route = ({
  path,
  exact,
  layout: Layout,
  component: Component,
  ...rest
}) => {
  return (
    <RRDRoute exact={exact} path={path} key={path}>
      <Layout>
        <Component {...rest} />
      </Layout>
    </RRDRoute>
  );
};

Route.defaultProps = {
  exact: false,
  layout: Fragment,
};

export default Route;
