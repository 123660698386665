import { useMutation, useQuery, useQueryClient } from 'react-query';
import produce from 'immer';

import {
  generateSubmittedQuestionAnswersState,
  QUESTION_TYPES,
} from '@ftrprf/tailwind-components';

import { get, post } from 'api/index';

import { URL } from './useContentSlides';

// Backend wants different fields for different question types for some reason
const valueKeys = {
  [QUESTION_TYPES.MULTIPLE_CHOICE]: 'multipleChoiceAnswerIds',
  [QUESTION_TYPES.OPEN]: 'value',
};

export const STUDENTURL = ({ contentType, contentId }) =>
  `${process.env.REACT_APP_API_URL}/content/${contentType}/${contentId}/answers`;

export const TEACHERURL = ({
  contentType,
  contentId,
  classGroupId,
  studentId,
}) =>
  `${process.env.REACT_APP_API_URL}/classgroups/${classGroupId}/content/${contentType}/${contentId}/students/${studentId}/answers`;

const SINGLE_QUESTION_URL = ({ contentType, contentId, questionId }) =>
  `${process.env.REACT_APP_API_URL}/content/${contentType}/${contentId}/questions/${questionId}`;

const useContentAnswers = ({
  contentId,
  contentType,
  endpoint: endpointFn,
  classGroupId,
  studentId,
  viewMode,
  slideId,
}) => {
  const endpoint = endpointFn({
    contentType,
    contentId,
    classGroupId,
    studentId,
  });
  const queryCache = useQueryClient();

  const contentAnswers = useQuery(endpoint);

  const { mutateAsync: submitAnswers } = useMutation(
    ({ question, temporaryQuestionAnswers }) =>
      post(endpoint, {
        questionId: question.id,
        type: question.type,
        [valueKeys[question.type]]: temporaryQuestionAnswers[question.id],
      }),
    {
      onMutate: ({
        question,
        temporaryQuestionAnswers,
        setTemporaryQuestionAnswers,
      }) => {
        const value = temporaryQuestionAnswers[question.id];

        const previousSubmittedQuestionAnswers =
          queryCache.getQueryData(endpoint);

        // optimistic update
        queryCache.setQueryData(endpoint, (previous) => {
          return [
            ...previous.filter((p) => p.questionId !== question.id),
            generateSubmittedQuestionAnswersState(question, value),
          ];
        });

        // remove the temporary answer
        setTemporaryQuestionAnswers(
          ({ [question.id]: _, ...otherQuestions }) => ({
            ...otherQuestions,
          }),
        );

        return () => {
          queryCache.setQueryData(endpoint, previousSubmittedQuestionAnswers);

          setTemporaryQuestionAnswers((prev) => ({
            ...prev,
            [question.id]: value,
          }));
        };
      },

      onSuccess: ([submittedQuestion], { question }) => {
        queryCache.setQueryData(endpoint, (previous) => {
          return [
            ...previous.filter((p) => p.questionId !== question.id),
            submittedQuestion,
          ];
        });

        return get(
          SINGLE_QUESTION_URL({
            contentType,
            contentId,
            questionId: question.id,
          }),
        ).then(({ questionAnswers }) => {
          queryCache.setQueryData(
            URL({ contentType, contentId: contentId, viewMode }),
            produce((draft) => {
              const currentSlide = draft.slides.find(
                ({ id }) => `${slideId}` === `${id}`,
              );

              const currentQuestion = currentSlide.questions.find(
                ({ id }) => question.id === id,
              );

              // Adds the explanation and correctness of the multiple choice question
              if (currentQuestion.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
                currentQuestion.questionAnswersMultipleChoice = questionAnswers;
              }
            }),
          );
        });
      },

      onError: (_, _newData, rollback) => rollback(),
    },
  );

  return {
    ...contentAnswers,
    endpoint,
    submitAnswers,
  };
};

export default useContentAnswers;
