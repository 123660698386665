import {
  DropdownButton,
  DropdownMenu,
  Label,
  MenuButton,
} from '@ftrprf/tailwind-components';

import { supportedLanguages } from 'providers/LanguageProvider';

import useFormatMessage from 'hooks/useFormatMessage';

const Language = ({ currentLanguageKey, updateLanguage }) => {
  const t = useFormatMessage();
  const currentLanguage = supportedLanguages.find(
    (s) => s.key === currentLanguageKey,
  )?.label;

  return (
    <div>
      <Label>{t('profile.language')}</Label>
      <DropdownMenu
        trigger={({ isOpen }) => (
          <DropdownButton isOpen={isOpen}>{currentLanguage}</DropdownButton>
        )}
      >
        {({ hide }) => (
          <div>
            {supportedLanguages.map((language) => (
              <MenuButton
                key={language.key}
                onClick={() => {
                  hide();
                  updateLanguage(language.key);
                }}
              >
                {language.label}
              </MenuButton>
            ))}
          </div>
        )}
      </DropdownMenu>
    </div>
  );
};

export default Language;
