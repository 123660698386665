export const teacherVideos = [
  {
    title: 'Aan de slag met FuTuRePRooF',
    src: 'https://vimeo.com/505210883',
  },
  {
    title: 'Wie zijn we',
    src: 'https://vimeo.com/422434557',
  },
  {
    title: 'De lespakketten',
    src: 'https://vimeo.com/422808664',
  },
  {
    title: 'IT&ME',
    src: 'https://vimeo.com/423076062',
  },
  {
    title: 'Computationeel denken',
    src: 'https://vimeo.com/422757006',
  },
  {
    title: 'Wijs',
    src: 'https://vimeo.com/423105785',
  },
  {
    title: 'De Digibib',
    src: 'https://vimeo.com/422784799',
  },
  {
    title: 'De Hackroom',
    src: 'https://vimeo.com/422434436',
  },
  {
    title: 'Eigen lessen met interactieve vragen',
    src: 'https://vimeo.com/478428808',
  },
  {
    title: 'Test de kennis met FTRPRF',
    src: 'https://vimeo.com/503403583',
  },
];
