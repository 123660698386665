import { useQuery } from 'react-query';

export const URL = ({ classGroupId }) =>
  `${process.env.REACT_APP_API_URL}/classgroups/${classGroupId}/hackroom/lessons`;

const useHackRoom = ({ classGroupId }) => {
  const response = useQuery(URL({ classGroupId }));

  return { hackRoomLessons: response.data, ...response };
};

export default useHackRoom;
