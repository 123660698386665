import useFormatMessage from 'hooks/useFormatMessage';

const ScratchError = () => {
  const t = useFormatMessage();

  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      <div className="flex divide-x-2 divide-gray-300 items-center justify-center leading-tight">
        <span className="text-2xl mr-2">{t('global.oops')}</span>
        <span className="pl-2">{t('scratch.scratch_error')}</span>
      </div>
    </div>
  );
};

export default ScratchError;
