const roles = {
  SCHOOLTEACHER: 'SCHOOLTEACHER',
  ADMIN: 'ADMIN',
  WORKSHOP: 'WORKSHOP',
  SCHOOLSTUDENT: 'SCHOOLSTUDENT',
  EDITOR: 'EDITOR',
  SCHOOLADMIN: 'SCHOOLADMIN',
  FTRPRF_SCHOOLTEACHER: 'FTRPRF_SCHOOLTEACHER',
  FTRPRF_SCHOOLSTUDENT: 'FTRPRF_SCHOOLSTUDENT',
  FTRPRF_SCHOOLADMIN: 'FTRPRF_SCHOOLADMIN',
  FTRPRF_ADMIN: 'FTRPRF_ADMIN',

  CODECOSMOS_ADMIN: 'CODECOSMOS_SCHOOLADMIN',
  CODECOSMOS_TEACHER: 'CODECOSMOS_SCHOOLTEACHER',
  CODECOSMOS_STUDENT: 'CODECOSMOS_SCHOOLSTUDENT',

  CODECOSMOS_PARENT: 'CODECOSMOS_PARENT',
  CODECOSMOS_CHILD: 'CODECOSMOS_SCHOOLSTUDENT',

  LPF_SCHOOLTEACHER: 'LPF_SCHOOLTEACHER',
  LPF_SCHOOLSTUDENT: 'LPF_SCHOOLSTUDENT',
  LPF_SCHOOLADMIN: 'LPF_SCHOOLADMIN',
  LPF_ADMIN: 'LPF_ADMIN',
};

export default roles;
