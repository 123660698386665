import { useRouteMatch } from 'react-router-dom';

import useClassGroupPrograms from 'hooks/api/useClassGroupPrograms';
import useParams from 'hooks/useParams';

import {
  removeLeadingAndTrailingSlash,
  removeTrailingSlash,
} from 'utils/removeLeadingAndTrailingSlash';

import Curricula from './Curricula';

const CurriculaContainer = ({ contentType, defaultViewMode }) => {
  const params = useParams();
  const { url } = useRouteMatch();
  const { curriculaIndex, isLoading } = useClassGroupPrograms();

  const path = removeLeadingAndTrailingSlash(params[0] || '');

  if (isLoading) {
    return null;
  }

  const curricula = curriculaIndex[path];

  if (!curricula) {
    return <p>Not found</p>;
  }

  return (
    <Curricula
      path={path}
      curricula={curricula}
      contentType={contentType}
      url={removeTrailingSlash(url)}
      defaultViewMode={defaultViewMode}
    />
  );
};

export default CurriculaContainer;
