import { useCallback } from 'react';
import { useIntl } from 'react-intl';

const defaultOptions = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};

const useFormatNumber = (options) => {
  const { formatNumber } = useIntl();

  return useCallback(
    (number) => {
      return formatNumber(number, options || defaultOptions);
    },
    [formatNumber, options],
  );
};

export default useFormatNumber;
