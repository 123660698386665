import { useContext } from 'react';

import { TailwindContext } from '../TailwindProvider';

const useFormatMessage = () => {
  const { t } = useContext(TailwindContext);

  return t;
};

export default useFormatMessage;
