import * as Sentry from '@sentry/react';

export const getClassGroupFromLocation = (location) => {
  if (!location.pathname.startsWith('/classgroups/')) {
    return undefined;
  }

  const [, id] = location.pathname.match(
    /^\/classgroups\/([^/]*)((\/([^/])*)+)?$/,
  );

  return id;
};

const redirectToExistingClassgroup = (history, location, classGroups = []) => {
  const classGroupIds = classGroups.map((c) => c.id);
  // There is a slash at the end because what if we ever want to show a list of all
  // classgroups at the URL {{domain}}/classgroups
  if (!location.pathname.startsWith('/classgroups/')) {
    return false;
  }

  const [, id, rest] = location.pathname.match(
    /^\/classgroups\/([^/]*)((\/([^/])*)+)?$/,
  );

  if (classGroupIds.includes(id) || classGroups.length === 0) {
    // We don't have to do anything.
    return false;
  }

  Sentry.captureMessage(
    `Classgroup with ID ${id} not found. Redirecting to first available classGroup...`,
  );

  history.replace(
    `/classgroups/${classGroupIds[0]}${rest || ''}${location.search || ''}`,
  );

  return true;
};

export default redirectToExistingClassgroup;
