const viewModes = {
  PREP: 'PROJECTION',
  PROJECTION: 'PROJECTION',
  CLASS: 'CLASSICAL',
  HOME: 'SELFSTUDY',
  CLASSICAL: 'CLASSICAL',
  SELFSTUDY: 'SELFSTUDY',
};

export default viewModes;
