import { useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';

import {
  Button,
  Content,
  SlideViewerTextSlide,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import c from 'utils/c';

import FeedbackPopover from './FeedbackPopover';
import StudentAnswersQuestionResult from './StudentAnswersQuestionResult';

const StudentAnswersQuestion = ({
  question,
  slide,
  questionNumber,
  answer,
  canEdit,
  user,
  contentType,
  feedbackEndpoint,
}) => {
  const t = useFormatMessage();

  const [questionIsShown, setQuestionIsShown] = useState(false);

  return (
    <div className="flex w-full justify-center even:bg-gray-50 py-4">
      <Content>
        <div className="w-full flex flex-col sm:flex-row">
          <div
            className="flex-shrink-0 mr-8 font-semibold text-gray-700 cursor-pointer"
            onClick={() => setQuestionIsShown((prev) => !prev)}
          >
            {t('student-answers.question_label')} {questionNumber}
          </div>
          <div className="w-full flex flex-col items-center">
            <div className="max-w-6xl w-full">
              <div
                className="cursor-pointer flex items-center"
                onClick={() => setQuestionIsShown((prev) => !prev)}
              >
                {slide.title ? (
                  <span className="font-semibold">{slide.title}</span>
                ) : (
                  <Button
                    iconBefore={() => (
                      <FaAngleRight
                        className={c(
                          'transform',
                          questionIsShown ? 'rotate-90' : 'rotate-0',
                        )}
                      />
                    )}
                    className="font-light text-gray-700"
                  >
                    {t(
                      questionIsShown
                        ? 'student-answers.click_to_hide_question'
                        : 'student-answers.click_to_show_question',
                    )}
                  </Button>
                )}
              </div>

              {questionIsShown && (
                <SlideViewerTextSlide
                  className="my-2"
                  value={slide.content || t('student-answers.no_content')}
                />
              )}

              <div className="mt-3">
                <StudentAnswersQuestionResult
                  contentType={contentType}
                  question={question}
                  answer={answer}
                  canEdit={canEdit}
                />
              </div>
            </div>
          </div>
          {answer && (
            <div className="ml-4">
              <FeedbackPopover
                feedbackEndpoint={feedbackEndpoint}
                canEdit={canEdit}
                questionId={answer.id}
                user={user}
              />
            </div>
          )}
        </div>
      </Content>
    </div>
  );
};

export default StudentAnswersQuestion;
