import { useContext, useState } from 'react';

import {
  FilledButton,
  Input,
  InputGroup,
  Label,
  NotificationContext,
  PasswordStrengthBar,
} from '@ftrprf/tailwind-components';

import useFormatMessage from 'hooks/useFormatMessage';

import { post } from 'api/index';

const UpdatePassword = ({ user }) => {
  const t = useFormatMessage();
  const { addNotification } = useContext(NotificationContext);
  const [wrongCredentials, setWrongCredentials] = useState(false);

  const [passwords, setPasswords] = useState({
    current: '',
    new: '',
    confirm: '',
  });

  const [passwordScore, setPasswordScore] = useState(0);

  const isConfirmed = passwords.new === passwords.confirm;
  const canSubmit =
    passwords.current && passwords.new && isConfirmed && passwordScore > 2;

  const submit = () => {
    post(`${process.env.REACT_APP_API_URL}/users/${user.id}/resetPassword`, {
      authPassword: passwords.current,
      newPassword: passwords.new,
    }).then((data) => {
      if (
        data?.status === 'BAD_REQUEST' &&
        data.message === 'WRONG_CREDENTIALS'
      ) {
        return setWrongCredentials(true);
      }

      if (data?.status === 'BAD_REQUEST') {
        addNotification({
          type: 'error',
          content: t('profile.errors.change_password'),
        });
      }

      return addNotification({
        type: 'success',
        content: t('profile.success.change_password'),
      });
    });
  };

  return (
    <form>
      {/* Password forms should have (optionally hidden) username fields for accessibility: (More info: https://goo.gl/9p2vKq)*/}
      <Input
        type="text"
        autoComplete="username"
        value={user.username}
        readOnly
        className="hidden"
      />

      <InputGroup>
        <Label>{t('profile.current_password')}</Label>
        <Input
          type="password"
          autoComplete="current-password"
          value={passwords.current}
          onChange={(e) =>
            setPasswords((p) => ({
              ...p,
              current: e.target.value,
            }))
          }
        />
      </InputGroup>

      <InputGroup>
        <Label>{t('profile.new_password')}</Label>
        <Input
          type="password"
          autoComplete="new-password"
          value={passwords.new}
          onChange={(e) =>
            setPasswords((p) => ({
              ...p,
              new: e.target.value,
            }))
          }
        />
        {passwords.new && (
          <div className="mt-2">
            <PasswordStrengthBar
              password={passwords.new}
              onScoreChange={setPasswordScore}
            />
          </div>
        )}
      </InputGroup>

      <InputGroup>
        <Label>{t('profile.confirm_new_password')} </Label>
        <Input
          type="password"
          value={passwords.confirm}
          autoComplete="new-password"
          onChange={(e) =>
            setPasswords((p) => ({
              ...p,
              confirm: e.target.value,
            }))
          }
        />
      </InputGroup>

      <FilledButton disabled={!canSubmit} onClick={submit}>
        {t('profile.change_password')}
      </FilledButton>

      {!isConfirmed && (
        <p className="text-red-500 mt-3">
          {t('profile.errors.passwords_not_matching')}
        </p>
      )}

      {wrongCredentials && (
        <p className="text-red-500 mt-3">
          {t('profile.errors.wrong_current_password')}
        </p>
      )}
    </form>
  );
};

export default UpdatePassword;
