import { useCallback, useMemo } from 'react';
import { FaPlus } from 'react-icons/fa';
import { generatePath, useHistory } from 'react-router-dom';
import { adminUrls } from 'app/AdminApp';

import { FilledButton, SearchBar, Table } from '@ftrprf/tailwind-components';

import useFormatDate from 'hooks/useFormatDate';
import useFormatMessage from 'hooks/useFormatMessage';

import StudentOverviewTableSkeleton from './partials/StudentOverviewTableSkeleton';
import createColumns from './columns';

const ManageStudents = ({
  schoolStudents,
  isLoadingStudents,
  canDelete,
  canCreate,
  openRemoveModal,
  openAddModal,
  openResetModal,
  setFilter,
}) => {
  const t = useFormatMessage();
  const history = useHistory();
  const formatDate = useFormatDate();

  const routeToDetail = useCallback(
    ({ id: studentId }) => {
      history.push(
        generatePath(`${adminUrls.STUDENT_PROFILE}`, {
          studentId,
        }),
      );
    },
    [history],
  );

  const columns = useMemo(
    () =>
      createColumns({
        t,
        formatDate,
        canDelete,
        routeToDetail,
        openRemoveModal,
        openResetModal,
      }),
    [t, formatDate, canDelete, routeToDetail, openRemoveModal, openResetModal],
  );

  return (
    <div className="w-full max-w-5/6 my-4 mx-auto">
      <div className="w-full flex justify-between">
        <div className="justify-between w-1/2">
          <SearchBar
            onChange={(e) => setFilter(e.target.value)}
            placeholder={t('hack-room.what_are_you_looking_for')}
            className="mb-4"
          />
        </div>
        {canCreate && (
          <FilledButton
            secondary={true}
            iconBefore={FaPlus}
            onClick={openAddModal}
            className="mb-4"
          >
            {t('student-overview.new-student')}
          </FilledButton>
        )}
      </div>
      <StudentOverviewTableSkeleton loading={isLoadingStudents}>
        <Table
          columns={columns}
          data={schoolStudents}
          rowClassName="cursor-pointer"
          onRowClick={routeToDetail}
        />
        {schoolStudents?.length === 0 && (
          <div className="flex flex-grow w-full items-center justify-center">
            <span>{t('global.empty')}</span>
          </div>
        )}
      </StudentOverviewTableSkeleton>
    </div>
  );
};

ManageStudents.defaultProps = {
  canDelete: false,
  canCreate: false,
  openAddModal: () => {},
  openRemoveModal: () => {},
};

export default ManageStudents;
