import { memo } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import Manage from 'pages/Manage/Manage';
import SchoolAdminStudentProfile from 'pages/StudentProfile/SchoolAdminContainer';

import Route from './Route';

export const adminUrls = {
  STUDENT_OVERVIEW: 'managestudents',
  STUDENT_PROFILE: 'student/:studentId',
};

const AdminApp = ({ user }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}/${adminUrls.STUDENT_OVERVIEW}`}
        component={Manage}
        user={user}
      />
      <Route
        exact
        path={`${path}/${adminUrls.STUDENT_PROFILE}`}
        component={SchoolAdminStudentProfile}
      />
    </Switch>
  );
};

export default memo(AdminApp);
