export default {
  'styled-slide-viewer.open_the_sidebar': 'Open de zijbalk',

  'styled-slide-viewer.sidebar.questions_not_saved':
    'De antwoorden op de vragen zullen niet worden opgeslagen',

  'styled-slide-viewer.previous': 'Vorige',
  'styled-slide-viewer.next': 'Volgende',

  'styled-slide-viewer.teacher_manual': 'Handleiding leerkracht',
  'styled-slide-viewer.teacher_manual_empty':
    'Er is geen extra uitleg nodig bij deze slide',

  'styled-slide-viewer.slides_overview': 'Slides overzicht',

  'styled-slide-viewer.settings': 'Instellingen',
  'styled-slide-viewer.settings.feedback': 'Feedback',
  'styled-slide-viewer.settings.feedback_explanation':
    'Geef feedback op deze slide',

  'styled-slide-viewer.settings.zoom': 'Zoom',
  'styled-slide-viewer.settings.zoom_explanation':
    'Gebruik + en - om de slide in en uit te zoomen',

  'styled-slide-viewer.view-modes.projection': 'Projectie',
  'styled-slide-viewer.view-modes.selfstudy': 'Zelfstudie',
  'styled-slide-viewer.view-modes.classical': 'Klassikaal',

  'styled-slide-viewer.submit': 'Dien in',
  'styled-slide-viewer.submitted': 'Ingediend',
  'styled-slide-viewer.save': 'Sla op',
  'styled-slide-viewer.saved': 'Opgeslagen',
  'styled-slide-viewer.close': 'Sluit',
  'styled-slide-viewer.no_slides': 'Er zijn geen slides in deze les',
  'styled-slide-viewer.empty_title': 'Tussenslide',
  'styled-slide-viewer.submit_answers': 'Antwoorden indienen',
  'styled-slide-viewer.answer_placeholder': 'Typ hier jouw antwoord',

  'styled-slide-viewer.hints': 'Hints',
  'styled-slide-viewer.hint': 'Hint',
  'styled-slide-viewer.hint-dialog.title': 'Hint',

  'styled-slide-viewer.questions.not_supported_type': 'De vraag kon niet getoond worden: het type van de vraag wordt niet ondersteunt.'
};
