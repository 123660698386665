import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaArrowLeft, FaSave, FaTrash } from 'react-icons/fa';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { classGroupUrls } from 'app/ClassGroupApp';

import {
  Content,
  FilledButton,
  FormGroup,
  Input,
  InputGroup,
  Label,
  PageHeader,
  PageTitle,
} from '@ftrprf/tailwind-components';
import { Dropdown } from '@ftrprf/tailwind-components';

import Avatar from 'components/Avatar';

import useFormatMessage from 'hooks/useFormatMessage';
import useParams from 'hooks/useParams';

import c from 'utils/c';
import urls from 'utils/constants/urls';

import { ReactComponent as ScratchIcon } from 'assets/vectors/scratch.svg';

import StudentProfileSkeleton from './partials/StudentProfileSkeleton';

const StudentProfile = ({
  user,
  availableClassGroups,
  classGroupId,
  isLoading,
  isError,
  isSaving,
  canDelete,
  canEdit,
  onSubmit,
  openRemoveModal,
}) => {
  const t = useFormatMessage();
  const history = useHistory();
  const { studentId } = useParams();
  const {
    handleSubmit,
    formState: { isDirty, errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      ...user,
    },
  });

  const onSubmitAndReset = useCallback(
    (data) => {
      onSubmit(data);
      reset({ ...data });
    },
    [onSubmit, reset],
  );

  if (isError) {
    return <Content>{t('profile.errors.something_went_wrong')}</Content>;
  }

  return (
    <StudentProfileSkeleton isLoading={isLoading} canDelete={canDelete}>
      <form onSubmit={handleSubmit(onSubmitAndReset)} className="w-full">
        <PageHeader marginTop={false} c>
          <div className="flex flex-col space-y-4 lg:flex-row w-full justify-between pt-8">
            <div className="flex items-end space-x-4 flex-grow">
              <Avatar
                className="w-16 h-16 text-xl"
                firstName={user?.firstName}
                lastName={user?.lastName}
              />
              <PageTitle>
                {user?.firstName} {user?.lastName}
              </PageTitle>
            </div>
            <div className="flex flex-wrap gap-y-4 sm:space-x-2 flex-col sm:flex-row lg:self-end lg:justify-end w-auto">
              {classGroupId && (
                <Link
                  className="flex-grow flex sm:flex-grow-0"
                  to={generatePath(
                    `${urls.CLASSGROUP}/${classGroupUrls.STUDENT_EXERCISE_OVERVIEW}`,
                    {
                      classGroupId,
                      studentId,
                    },
                  )}
                >
                  <FilledButton
                    className="whitespace-nowrap text-xs lg:text-sm flex-grow lg:flex-grow-0"
                    iconBefore={() => (
                      <ScratchIcon className="w-4 h-4 fill-current" />
                    )}
                  >
                    {t('exercises-overview.view_exercises')}
                  </FilledButton>
                </Link>
              )}
              {canEdit && (
                <FilledButton
                  className="whitespace-nowrap text-sm flex-grow sm:flex-grow-0"
                  type="submit"
                  disabled={!isDirty || isSaving}
                  iconBefore={FaSave}
                >
                  {t('global.save-changes')}
                </FilledButton>
              )}
              {canDelete && (
                <FilledButton
                  className="whitespace-nowrap text-sm flex-grow sm:flex-grow-0"
                  onClick={openRemoveModal}
                  iconBefore={FaTrash}
                  error
                >
                  {t('global.delete')}
                </FilledButton>
              )}
            </div>
          </div>
        </PageHeader>

        <Content>
          <div className="flex flex-col w-full md:w-2/3 xl:w-1/2 space-y-4">
            <div>
              <FilledButton
                iconBefore={FaArrowLeft}
                onClick={() => history.goBack()}
                secondary
              >
                {t('global.go_back')}
              </FilledButton>
            </div>
            <FormGroup>
              <InputGroup>
                <Label>{t('profile.first_name')}</Label>
                <Controller
                  control={control}
                  name="firstName"
                  rules={{
                    required: {
                      value: true,
                      message: t('global.required-field'),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      readOnly={!canEdit}
                      defaultValue={user?.firstName}
                      className={c(!canEdit && 'cursor-not-allowed')}
                      {...field}
                    />
                  )}
                />
                {errors.firstName && (
                  <span className="text-red-500 text-xs">
                    {errors.firstName.message}
                  </span>
                )}
              </InputGroup>

              <InputGroup>
                <Label>{t('profile.last_name')}</Label>
                <Controller
                  control={control}
                  name="lastName"
                  rules={{
                    required: {
                      value: true,
                      message: t('global.required-field'),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      readOnly={!canEdit}
                      defaultValue={user?.lastName}
                      className={c(!canEdit && 'cursor-not-allowed')}
                      {...field}
                    />
                  )}
                />
                {errors.lastName && (
                  <span className="text-red-500 text-xs">
                    {errors.lastName.message}
                  </span>
                )}
              </InputGroup>

              <InputGroup>
                <Label>{t('profile.username')}</Label>
                <Controller
                  control={control}
                  name="username"
                  rules={{
                    required: {
                      value: true,
                      message: t('global.required-field'),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      readOnly={!canEdit}
                      defaultValue={user?.username}
                      className={c(!canEdit && 'cursor-not-allowed')}
                      {...field}
                    />
                  )}
                />

                {errors.username && (
                  <span className="text-red-500 text-xs">
                    {errors.username.message}
                  </span>
                )}
              </InputGroup>

              <InputGroup>
                <Label>{t('profile.mail')}</Label>
                <Controller
                  control={control}
                  name="mail"
                  rules={{
                    required: {
                      value: false,
                      message: t('global.required-field'),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      readOnly={!canEdit}
                      defaultValue={user?.mail}
                      className={c(!canEdit && 'cursor-not-allowed')}
                      {...field}
                    />
                  )}
                />
              </InputGroup>
              <InputGroup>
                <Label>{t('add-student-dialog.linked_classgroups')}</Label>
                <Controller
                  control={control}
                  name="classGroups"
                  type="select"
                  rules={{
                    required: {
                      value: true,
                      message: t('global.required-field'),
                    },
                  }}
                  defaultValue={user.classGroups}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Dropdown
                      disabled={!canEdit}
                      onBlur={onBlur}
                      onChange={onChange}
                      isMulti={true}
                      options={Object.keys(availableClassGroups)}
                      value={value}
                    />
                  )}
                />
                {errors.classGroups && (
                  <span className="text-red-500 text-xs">
                    {errors.classGroups.message}
                  </span>
                )}
              </InputGroup>
            </FormGroup>
          </div>
        </Content>
      </form>
    </StudentProfileSkeleton>
  );
};

export default StudentProfile;
